import { Check, ErrorOutline } from "@mui/icons-material";
import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HttpGet } from "../../apis";
import { route } from "../../constants/routes";

const DownloadingPage = () => {

  const navigate = useNavigate();

  const [messages, setMessages] = useState<{ title: string, description: string }>({
    title: "Downloading Reports",
    description: "This might take a few minutes, please wait."
  })

  const [downloadState, setDownloadState] = useState<"loading" | "success" | "failed">("loading");

  const downloadTransactions = useCallback(async () => {
    const newWindow: any = window;

    const params: any = newWindow.state;

    if (params === null || params === undefined) {
      return navigate(route.HOME);
    }

    setDownloadState("loading");
    setMessages({
      title: "Downloading Reports",
      description: "This might take a few minutes, please wait."
    });

    await HttpGet('/crew/download/transactions', params, 300000)
      .then(result => {
        let data = result.data;

        if (data) {
          setMessages({
            title: "Download Successful",
            description: "Reports successfully downloaded."
          });
          setDownloadState("success");

          const link = document.createElement("a");

          link.href = data.file_path;
          link.download = data.file_name;
          link.click();

        } else {
          setMessages({
            title: "Download Failed",
            description: "Failed to download reports."
          });
          setDownloadState("failed");
        }
      })
      .catch(error => {
        setMessages({
          title: "Download Failed",
          description: `${error}`
        });
        setDownloadState("success");
      });
  }, [navigate])

  useEffect(() => {
    downloadTransactions();
  }, [downloadTransactions])

  return (
    <Box sx={{ height: "100vh", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {downloadState === "loading" && <CircularProgress size={32} />}
      {downloadState === "success" && <Check color="success" fontSize="large" />}
      {downloadState === "failed" && <ErrorOutline color="error" fontSize="large" />}
      <Typography variant="h2" sx={{ fontWeight: 600, mb: 2, mt: 3, fontSize: 18, textAlign: 'center' }}>
        {messages.title}
      </Typography>
      <Typography variant="h3" sx={{ fontWeight: 500, fontSize: 15, textAlign: 'center' }}>
        {messages.description}
      </Typography>
    </Box>
  );
}

export default DownloadingPage;