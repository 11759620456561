import { CrewListState } from 'redux/departmentRepresentative/crewSlice'
import Card from '.'
import { RootState } from 'redux/configureStore'
import { useSelector } from 'react-redux'

const TierDetailCrewCard = () => {
  const { statistics } = useSelector<RootState, CrewListState>((state) => state.crewList)

  return (
    <>
      <Card className="ml-4 w-[288px] font-dmSans">
        <div className="text-sm font-medium">Crew numbers</div>

        <div className="border-t-2 mb-3 pt-2">
          <div className="text-xs">Tier 1</div>
          <div className="flex">
            <div className="text-base font-dmSans font-medium">{statistics?.['1']?.crew_count ?? '-'}</div>
            <div className="bg-ccp-purple100 text-ccp-purple80 flex items-center ml-2 px-1 rounded text-xs">
              {statistics?.['1']?.headcountPercent?.toFixed(2) ?? '-'}%
            </div>
          </div>
        </div>

        <div className="border-t-2 mb-3 pt-2">
          <div className="text-xs">Tier 2</div>
          <div className="flex">
            <div className="text-base font-dmSans font-medium">{statistics?.['2']?.crew_count ?? '-'}</div>
            <div className="bg-ccp-purple100 text-ccp-purple80 flex items-center ml-2 px-1 rounded text-xs">
              {statistics?.['2']?.headcountPercent?.toFixed(2) ?? '-'}%
            </div>
          </div>
        </div>

        <div className="border-t-2 mb-3 pt-2">
          <div className="text-xs">Tier 3</div>
          <div className="flex">
            <div className="text-base font-dmSans font-medium">{statistics?.['3']?.crew_count ?? '-'}</div>
            <div className="bg-ccp-purple100 text-ccp-purple80 flex items-center ml-2 px-1 rounded text-xs">
              {statistics?.['3']?.headcountPercent?.toFixed(2) ?? '-'}%
            </div>
          </div>
        </div>

        <div className="border-t-2 mb-3 pt-2">
          <div className="text-xs">Tier 4</div>
          <div className="flex">
            <div className="text-base font-dmSans font-medium">{statistics?.['4']?.crew_count ?? '-'}</div>
            <div className="bg-ccp-purple100 text-ccp-purple80 flex items-center ml-2 px-1 rounded text-xs">
              {statistics?.['4']?.headcountPercent?.toFixed(2) ?? '-'}%
            </div>
          </div>
        </div>
      </Card>
    </>
  )
}

export default TierDetailCrewCard
