import axios from 'axios'
import * as firebase from 'firebase/auth'
import encryptPayload from '../common/helpers/encryptors'
import getApiUrl from '../common/helpers/getApiUrl'
import { getLocalStorage, setLocalStorage, storageName } from '../utils'

const _baseApiUrl = getApiUrl()

const fileApi = axios.create({
  baseURL: _baseApiUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

export const PostFile = async (url: string, body: FormData) => {
  const currentUser = firebase.getAuth().currentUser

  if (currentUser) {
    try {
      const auth = firebase.getAuth()
      const getUserAuthCred: any = await firebase.getRedirectResult(auth)

      let googleAccessToken = null

      if (getUserAuthCred) {
        const getGoogleAccessToken = firebase.GoogleAuthProvider.credentialFromResult(getUserAuthCred)
        googleAccessToken = getGoogleAccessToken?.accessToken

        setLocalStorage(storageName.accessToken, googleAccessToken)
      } else {
        googleAccessToken = getLocalStorage(storageName.accessToken)
      }

      const getJWTtoken = await currentUser.getIdToken()

      fileApi.defaults.headers.common['Authorization'] = `Bearer ${getJWTtoken} ${googleAccessToken}`

      return fileApi
        .post(url, body, {
          timeout: 60000,
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          throw err
        })
    } catch (error) {
      console.error('[error] postFile failed: ', error)
    }
  }
}

const instance = axios.create({
  baseURL: _baseApiUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

const mainHttp = async (
  HTTPMethod: 'GET' | 'POST' | 'PATCH' | 'DELETE',
  url: string,
  body: any = null,
  queryParams = {},
  timeout = 60000,
) => {
  const currentUser = firebase.getAuth().currentUser

  if (currentUser) {
    try {
      // TODO: need to have Google Access Token
      const auth = firebase.getAuth()
      const getUserAuthCred: any = await firebase.getRedirectResult(auth)

      let googleAccessToken = null

      if (getUserAuthCred) {
        const getGoogleAccessToken = firebase.GoogleAuthProvider.credentialFromResult(getUserAuthCred)
        googleAccessToken = getGoogleAccessToken?.accessToken

        setLocalStorage(storageName.accessToken, googleAccessToken)
      } else {
        googleAccessToken = getLocalStorage(storageName.accessToken)
      }

      const getJWTtoken = await currentUser.getIdToken()

      instance.defaults.headers.common['Authorization'] = `Bearer ${getJWTtoken} ${googleAccessToken}`
    } catch (error) {
      console.log('error', error)
    }
  }

  let payload: any = {}

  if (HTTPMethod === 'GET') {
    payload = {
      ...queryParams,
    }
  } else {
    if (body) {
      payload = body
    }
  }

  payload.request_url = url
  payload.request_base_url = _baseApiUrl
  payload.request_method = HTTPMethod

  let token: string = encryptPayload(payload)
  if (!token) {
    throw Error('Invalid token')
  }

  return instance({
    method: 'GET',
    url: '/encryptor/decrypt',
    data: null,
    timeout: timeout,
    params: {
      token: token,
      ...(process.env.REACT_APP_DEBUG === 'true' ? { debug_request_url: url, debug_payload: queryParams } : {}),
    },
  })
    .then((res) => {
      return res
    })
    .catch((err) => {
      throw err
    })
}

export const HttpGet = async (url: string, queryParams = {}, timeout = 60000) => {
  return await mainHttp('GET', url, null, queryParams, timeout)
}

export const HttpPost = async (url: string, body: any = null, queryParams = {}, timeout = 60000) => {
  return await mainHttp('POST', url, body, queryParams, timeout)
}

export const HttpPatch = async (url: string, body: any = null, queryParams = {}, timeout = 60000) => {
  return await mainHttp('PATCH', url, body, queryParams, timeout)
}
