import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFuzzySearchList } from '@nozbe/microfuzz/react'
import Card from 'components/Card'
import FlightPairingCard from 'components/Card/FlightPairingCard'
import Header from 'components/Header'
import Select from 'components/Select/Select'
import Tabs from 'components/Tabs'
import SwipeableContent from 'components/Tabs/SwipeableContent'
import Input from 'components/TextField/Input'
import { route } from 'constants/routes'
import { filter, groupBy, keys } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { RootState } from 'redux/configureStore'
import { FlightPairingTransaction, FlightPairingTransactionsItem } from 'redux/thunk/getFlightPairingList'
import { TransactionState } from 'redux/transactions'
import { UserState } from 'redux/user'
import { mapToFlightPairingCardData, useDownloadReportHelper } from '../utils'
import FlightPairingSalesList from './FlightPairingSalesList'
import { useResponsiveCheck } from 'utils/hooks'
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table'
import { AocState } from 'redux/departmentRepresentative/aocSlice'
import { format } from 'date-fns'
import SharedTable from 'components/Table/SharedTable'
import AircraftImg from 'assets/images/card-bg/undraw-aircraft.svg'
import MoneyBagImg from 'assets/images/card-bg/money-bag.svg'
import MonetizationImg from 'assets/images/card-bg/monetization.svg'
import clsx from 'clsx'

interface FlightPairingDetailsData {
  transactionsData: FlightPairingTransaction
  listOfFlight: string[]
}

interface FlightPairingDetailsProps {}

const FlightPairingDetails = (props: FlightPairingDetailsProps) => {
  const navigate = useNavigate()
  const { crewId, pairingId } = useParams()
  const { notFromUserView } = useOutletContext<any>()
  const transactionState = useSelector<RootState, TransactionState>((state) => state.transactions)
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const aocState = useSelector<RootState, AocState>((state) => state.aoc)
  const { isDesktop } = useResponsiveCheck()
  const pageRef = useRef<HTMLDivElement>(null)
  const currency = aocState.aocCurrency ?? userState.aocDetails.currency

  const isLoading = transactionState.flightPairingsListIsLoading
  const [loadedData, setLoadedData] = useState<FlightPairingDetailsData | null>(null)
  const [tabKeys, setTabKeys] = useState<string[]>([])
  const [filteredData, setFilteredData] = useState<FlightPairingTransactionsItem[]>([])
  const [searchText, setSearchText] = useState<string>('')
  const [selectedFlight, setSelectedFlight] = useState<string>('')

  const { reportFilters, setReportFilter } = useDownloadReportHelper()

  const searchedList = useFuzzySearchList({
    list: filteredData ?? [],
    queryText: searchText,
    getText: (item) => [item.product_name, item.flight_number],
    mapResultItem: ({ item }) => item,
    strategy: 'off',
  })

  useEffect(() => {
    setReportFilter({
      ...reportFilters,
      flight_pairing_id: pairingId,
    })
  }, [])

  // Get correct data from state
  useEffect(() => {
    if (pairingId && transactionState.flightPairings.length) {
      let data = transactionState.flightPairings.find((d) => d.id === pairingId)

      if (!data) return

      const flightList = keys(groupBy(data.transactionsItems, 'flight_number'))
      const tabs = keys(groupBy(data.transactionsItems, 'category'))

      setTabKeys(['ALL', ...tabs])
      setLoadedData({
        transactionsData: data,
        listOfFlight: flightList,
      })
    }
  }, [pairingId, transactionState.flightPairings.length, selectedFlight])

  // Filters
  useEffect(() => {
    if (loadedData?.transactionsData.transactionsItems.length) {
      const transactionItems = loadedData.transactionsData.transactionsItems

      if (selectedFlight !== '') {
        setFilteredData(filter(transactionItems, { flight_number: selectedFlight }))
      } else {
        setFilteredData(transactionItems)
      }
    }
  }, [selectedFlight, loadedData])

  // Scrolling behaviour helper
  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [pageRef.current])

  const onClickBackBtn = () => {
    if (notFromUserView) {
      navigate(generatePath(route.CREWS_TRANSACTIONS, { crewId }))
    } else {
      navigate(route.HOME)
    }
  }

  const tabLabelMapper: { [key: string]: string } = {
    ALL: 'All',
    DOMLUX: 'Domestic Luxury',
    'DUTY FREE': 'Duty Free',
    'NON-PERISHABLE': 'Non-Perishable',
    PERISHABLE: 'Perishable',
    MERCHANDISE: 'Merchandise',
  }
  const [activeTab, setActiveTab] = useState<number>(0)

  return (
    <div ref={pageRef} className="scroll-my-4">
      <button className="mb-2 px-2 py-1 -ml-2 rounded hover:bg-ccp-ripple cursor-pointer" onClick={onClickBackBtn}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <span className="ml-2 text-medium">Back to full list</span>
      </button>

      {/* Top section */}
      {isDesktop ? (
        <DesktopTopSection data={loadedData?.transactionsData} isLoading={isLoading} currency={currency} />
      ) : (
        <MobileTopSection data={loadedData?.transactionsData} isLoading={isLoading} currency={currency} />
      )}

      {loadedData && isDesktop ? (
        <DesktopTransactionList
          currency={currency}
          loadedData={loadedData}
          searchedList={searchedList}
          setSelectedFlight={setSelectedFlight}
          selectedFlight={selectedFlight}
          setSearchText={setSearchText}
          searchText={searchText}
          tabKeys={tabKeys}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabLabelMapper={tabLabelMapper}
          isDesktop={isDesktop}
        />
      ) : (
        <MobileTransactionList
          currency={currency}
          loadedData={loadedData}
          searchedList={searchedList}
          setSelectedFlight={setSelectedFlight}
          selectedFlight={selectedFlight}
          setSearchText={setSearchText}
          searchText={searchText}
          tabKeys={tabKeys}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabLabelMapper={tabLabelMapper}
          isDesktop={isDesktop}
        />
      )}
    </div>
  )
}

type TopSectionProps = {
  data?: FlightPairingTransaction
  isLoading: boolean
  currency: string
}

const MobileTopSection = ({ data, isLoading, currency }: TopSectionProps) => {
  return (
    <>
      {isLoading && <FlightPairingCard isLoading isMain />}
      {!isLoading && data && <FlightPairingCard data={mapToFlightPairingCardData(data, currency)} isMain />}
    </>
  )
}

const DesktopTopSection = ({ data, isLoading }: TopSectionProps) => {
  const formatCurrency = (currency: string, value: number) => `${currency} ${value.toFixed(2)}`
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const aocState = useSelector<RootState, AocState>((state) => state.aoc)
  const { notFromUserView } = useOutletContext<any>()
  const fallbackCurrency = notFromUserView ? aocState.aocCurrency : userState.extraDetails?.currency
  const columns = useMemo<MRT_ColumnDef<FlightPairingTransaction, any>[]>(
    () => [
      {
        accessorKey: 'flight_number_pairing',
        header: 'Flight pairing',
      },
      {
        accessorKey: 'date_string',
        header: 'Date',
        Cell: ({ cell }) => format(new Date(cell.getValue()), 'd MMM'),
        size: 80,
      },
      {
        accessorKey: 'total_sales',
        header: 'Santan',
        Cell: ({ cell, row }) =>
          formatCurrency(
            row.original.currency ?? fallbackCurrency,
            row.original.total_sales.perishable + row.original.total_sales.non_perishable,
          ),
        size: 100,
      },
      {
        accessorKey: 'total_sales.domlux',
        header: 'Domlux',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
      },
      {
        accessorKey: 'total_sales.mcds',
        header: 'MCDS',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
      },
      {
        accessorKey: 'total_sales.dtf',
        header: 'DTF',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
      },
      {
        accessorKey: 'total_commission',
        header: 'Santan',
        Cell: ({ cell, row }) =>
          formatCurrency(
            row.original.currency ?? fallbackCurrency,
            row.original.total_commission.perishable + row.original.total_commission.non_perishable,
          ),
        size: 100,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: '#4B4FA6',
            color: 'white',
            borderTopLeftRadius: '16px',
          },
        }),
      },
      {
        accessorKey: 'total_commission.domlux',
        header: 'Domlux',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: '#4B4FA6',
            color: 'white',
          },
        }),
      },
      {
        accessorKey: 'total_commission.mcds',
        header: 'MCDS',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: '#4B4FA6',
            color: 'white',
          },
        }),
      },
      {
        accessorKey: 'total_commission.dtf',
        header: 'DTF',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: '#4B4FA6',
            color: 'white',
            borderTopRightRadius: '16px',
          },
        }),
      },
    ],
    [],
  )

  return <SharedTable columns={columns} data={data ? [data] : []} disablePagination />
}

type SaleTransactionListProps = {
  currency: any
  loadedData: FlightPairingDetailsData | null
  searchedList: FlightPairingTransactionsItem[]
  setSelectedFlight: any
  selectedFlight: any
  setSearchText: any
  searchText: any
  tabKeys: any
  activeTab: any
  setActiveTab: any
  tabLabelMapper: any
  isDesktop: any
}

const MobileTransactionList = ({
  currency,
  loadedData,
  searchedList,
  setSelectedFlight,
  selectedFlight,
  setSearchText,
  searchText,
  tabKeys,
  activeTab,
  setActiveTab,
  tabLabelMapper,
  isDesktop,
}: SaleTransactionListProps) => {
  return (
    <div className="bg-white mt-4 rounded p-4">
      <Card>
        <Header.Subtitle1>Total Sales</Header.Subtitle1>
        <Header.H3>
          {currency} {loadedData?.transactionsData.total_sales.total.toFixed(2)}
        </Header.H3>
      </Card>

      <Card className="mt-4">
        <Header.Subtitle1>Total Commission</Header.Subtitle1>
        <Header.H3>
          {currency} {loadedData?.transactionsData.total_commission.total.toFixed(2)}
        </Header.H3>
      </Card>

      <Header.Subtitle1 className="mt-4">Search by</Header.Subtitle1>

      <div className="flex flex-col mt-2">
        <Select
          placeholder="Flight sold"
          onChange={setSelectedFlight}
          value={selectedFlight}
          options={
            loadedData?.listOfFlight.map((d) => ({
              label: d,
              value: d,
            })) ?? []
          }
        />
        <Input
          placeholder="Product"
          sizing={isDesktop ? 'md' : 'lg'}
          onChange={setSearchText}
          value={searchText}
          className="mt-2"
        />
      </div>

      {/* tab wrapper */}
      {tabKeys.length ? (
        <div className="rounded shadow mt-4">
          <Tabs
            data={tabKeys}
            activeTabIndex={activeTab}
            onIndexChange={setActiveTab}
            labelFormatter={(s: string) => tabLabelMapper[s] ?? s}
          />
          {searchText || selectedFlight ? (
            <div className={`flex flex-col xl:flex-row p-4`}>
              <Header.Subtitle1>Search result</Header.Subtitle1>
              <span>
                {filter(searchedList, (item) => (activeTab === 0 ? true : item.category === tabKeys[activeTab])).length}
              </span>
            </div>
          ) : null}
        </div>
      ) : null}

      {/* Swipeable content */}
      {tabKeys.length ? (
        <SwipeableContent
          pages={tabKeys.map(() => (
            <FlightPairingSalesList
              currency={currency}
              listData={filter(searchedList, (item) => (activeTab === 0 ? true : item.category === tabKeys[activeTab]))}
            />
          ))}
          activeTabIndex={activeTab}
          onChange={(idx) => {
            setActiveTab(idx)
          }}
          className={`-mx-4`}
          pageWrapperClassName="px-4"
        />
      ) : null}
    </div>
  )
}

const DesktopTransactionList = ({
  currency,
  loadedData,
  searchedList,
  setSelectedFlight,
  selectedFlight,
  setSearchText,
  searchText,
  tabKeys,
  activeTab,
  setActiveTab,
  tabLabelMapper,
  isDesktop,
}: SaleTransactionListProps) => {
  const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 10 })

  const columns = useMemo<MRT_ColumnDef<FlightPairingTransactionsItem, any>[]>(
    () => [
      {
        accessorKey: 'product_name',
        header: 'Products',
      },
      {
        accessorKey: 'flight_number',
        header: 'Flight',
      },
      {
        accessorKey: 'category',
        header: 'Category',
      },
      {
        accessorKey: 'product_quantity',
        header: 'Qty',
      },
      {
        accessorKey: 'sales',
        header: 'Sales',
        Cell: ({ cell, row }) => `${currency} ${cell.getValue<number>().toFixed(2)}`,
      },
      {
        accessorKey: 'commission',
        header: 'Commission',
        Cell: ({ cell, row }) => `${currency} ${cell.getValue<number>().toFixed(2)}`,
      },
    ],
    [],
  )

  return (
    <div className="bg-white mt-4 rounded p-4 flex flex-row">
      {/* Left Section */}
      <div className="flex flex-col gap-4">
        <Card bgImageUrl={AircraftImg} className={clsx('h-20 w-64 pl-28 bg-no-repeat bg-[center_left_-40px]')}>
          <div className="text-xs font-bold font-dmSans">
            {loadedData && loadedData.transactionsData?.date_string
              ? format(new Date(loadedData.transactionsData.date_string), 'd MMM')
              : '-'}
          </div>
          <div className="text-base font-bold font-dmSans">{loadedData?.transactionsData.aoc}</div>
        </Card>
        <Card bgImageUrl={MoneyBagImg} className={clsx('h-20 w-64 pl-28 bg-no-repeat bg-[center_left_30px]')}>
          <div className="text-xs font-bold font-dmSans">Daily Sales</div>
          <div className="text-base font-bold font-dmSans">
            {currency} {loadedData?.transactionsData.total_sales.total.toFixed(2)}
          </div>
        </Card>
        <Card bgImageUrl={MonetizationImg} className={clsx('h-20 w-64 pl-28 bg-no-repeat bg-[center_left_35px]')}>
          <div className="text-xs font-bold font-dmSans">Daily Commission</div>
          <div className="text-base font-bold font-dmSans">
            {currency} {loadedData?.transactionsData.total_commission.total.toFixed(2)}
          </div>
        </Card>
      </div>

      {/* Right Section */}
      <div className="flex flex-col ml-4 min-w-1">
        <Tabs
          data={tabKeys}
          activeTabIndex={activeTab}
          onIndexChange={setActiveTab}
          labelFormatter={(s: string) => tabLabelMapper[s] ?? s}
          wrapperClassName="shadow"
        />
        <Header.Subtitle1 className="mt-4">Search by</Header.Subtitle1>

        <div className={`flex mt-2 gap-2 flex-row items-center`}>
          <Select
            className="min-w-32"
            placeholder="Flight sold"
            onChange={setSelectedFlight}
            value={selectedFlight}
            options={
              loadedData?.listOfFlight.map((d) => ({
                label: d,
                value: d,
              })) ?? []
            }
          />
          <Input
            placeholder="Product"
            onChange={setSearchText}
            value={searchText}
            className="min-w-32"
            showClearButton
          />
        </div>

        {searchText || selectedFlight ? (
          <div className={`flex px-4 py-1 items-center gap-4 shadow my-4`}>
            <Header.Subtitle1>Search result</Header.Subtitle1>
            <span className="text-ccp-primary font-bold">
              {filter(searchedList, (item) => (activeTab === 0 ? true : item.category === tabKeys[activeTab])).length}
            </span>
          </div>
        ) : null}

        <SharedTable
          columns={columns}
          data={filter(searchedList, (item) => (activeTab === 0 ? true : item.category === tabKeys[activeTab]))}
          pagination={pagination}
          setPagination={setPagination}
          paginationMode="client"
        />
      </div>
    </div>
  )
}

export default FlightPairingDetails
