import SummaryCard from 'components/Card/SummaryCard'
import { route } from 'constants/routes'
import HeaderToolbar from 'pages/home/components/HeaderToolbar'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, generatePath, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom'
import { useResponsiveCheck } from 'utils/hooks'
import { MainContainer } from '../../components/Container'
import { CrewListState } from 'redux/departmentRepresentative/crewSlice'
import { RootState } from 'redux/configureStore'
import Breadcrumb from 'components/Breadcrumb'
import { is } from 'date-fns/locale'
import { isNull } from 'lodash'

const Transactions = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isDesktop } = useResponsiveCheck()
  const { crewId } = useParams()
  const isTransactionPage = useMatch(route.CREWS_TRANSACTIONS)
  const isSubTransactionPage = useMatch(route.CREWS_SUB_TRANSACTIONS)
  const isTiersPage = useMatch(route.CREWS_TIERS)

  const crewListState = useSelector<RootState, CrewListState>((state) => state.crewList)

  const currentCrewInfo = crewListState.list.find((crew) => crew.crew_id === crewId)

  const viewTierDetails = () => {
    navigate(generatePath(route.CREWS_TIERS, { crewId }))
  }

  return (
    <MainContainer
      contentHeaderBar={
        isDesktop ? (
          <div className="bg-white">
            <div className="bg-white shadow sticky top-0 left-0 w-full z-10 mb-2">
              <div className="h-14 w-full max-w-[1280px] px-4 mx-auto flex flex-row">
                <div className="grow flex flex-row">
                  <Breadcrumb
                    items={[
                      {
                        label: 'Crew list',
                        url: route.CREWS,
                      },
                      {
                        label: currentCrewInfo?.full_name ?? '',
                        ...(isNull(isTransactionPage)
                          ? { url: generatePath(route.CREWS_TRANSACTIONS, { crewId }) }
                          : {}),
                      },
                      ...(isSubTransactionPage
                        ? [
                            {
                              label: 'Route details',
                            },
                          ]
                        : []),
                      ...(isTiersPage
                        ? [
                            {
                              label: 'Tier ranking',
                            },
                          ]
                        : []),
                    ]}
                  />
                </div>
                <HeaderToolbar className="shrink-0" isDesktop={isDesktop} />
              </div>
            </div>

            <div className="max-w-[1280px] mx-auto px-4 pb-4">
              <SummaryCard onCardClick={viewTierDetails} crewID={crewId} />
            </div>
          </div>
        ) : null
      }>
      <div className="mb-4" />
      <Outlet context={{ notFromUserView: true }} />
    </MainContainer>
  )
}

export default Transactions
