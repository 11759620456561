import { faCircleCheck, faCircleXmark, faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PostFile } from 'apis'
import clsx from 'clsx'
import Button from 'components/Button'
import PlainModal from 'components/Modal/PlainModal'
import { SvgIcon } from 'components/SvgIcon'
import Toast from 'components/Toast'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { formatBytes } from 'utils/functions'
import { read, utils } from 'xlsx'

interface UploadCsvModalProps {}

const UploadCsvModal = (props: UploadCsvModalProps) => {
  const {} = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0) // Index!
  const [error, setError] = useState<string | null>(null)
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const { acceptedFiles, getRootProps, getInputProps, inputRef, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/*': ['.csv'],
      // 'application/vnd.ms-excel': ['.xsl'],
      // 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      // 'application/json': ['.json'],
    },
    multiple: false,
    onDrop: (files) => {
      setSelectedFiles(files)
    },
  })

  const PROGRESS_STEPS = [
    'File ready to be uploaded',
    'Preparing to upload',
    'Uploading file to server',
    'Upload successful!',
  ]

  const progressToNextStep = () => {
    const maxIndex = PROGRESS_STEPS.length - 1

    setUploadProgress((currentProgress) => (currentProgress < maxIndex ? currentProgress + 1 : currentProgress))
  }

  const resetProgressAndError = () => {
    setError(null)
    setUploadProgress(0)
  }

  const processFile = async () => {
    // Don't do anything if no file is selected
    if (!acceptedFiles?.length) return

    resetProgressAndError()
    progressToNextStep()
    const file = acceptedFiles[0]
    let parsed
    if (file.type === 'application/json') {
      try {
        const filereader = new FileReader()
        filereader.onload = function (event) {
          parsed = JSON.parse(event.target?.result?.toString() ?? '')

          // handleParsedData(parsed)
        }

        await filereader.readAsText(file)
      } catch (error) {
        console.error('[error] Failed to read json: ', error)
      }
    } else if (file.type === 'text/csv') {
      const formData = new FormData()
      formData.append('file', file, 'file.csv')

      submitData(formData)
    } else {
      try {
        const data = await acceptedFiles[0].arrayBuffer()
        const workbook = read(data)
        parsed = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])

        // handleParsedData(parsed)
      } catch (error) {
        console.error('[error] Failed to read file: ', error)
      }
    }
  }

  const submitData = async (data: FormData) => {
    try {
      progressToNextStep()
      const response = await PostFile('/tier-targets/upload', data)
      progressToNextStep()
    } catch (error) {
      progressToNextStep()
      Toast.error('Failed to upload the file')
      setError('Upload failed')
      console.error('[error] Failed to upload csv:', error)
    }
  }

  const clearFile = useCallback(() => {
    if (inputRef?.current) {
      setSelectedFiles([])
      inputRef.current.value = ''
    }
  }, [inputRef.current])

  const getIconForFileType = (fileType: string) => {
    if (fileType === 'text/csv') return 'FileCsv'
    if (fileType === 'application/json') return 'FileJson'

    return 'FileXls'
  }

  useEffect(() => {
    resetProgressAndError()
  }, [acceptedFiles, isOpen])

  return (
    <div>
      <Button onClick={() => setIsOpen((curVal) => !curVal)}>
        <FontAwesomeIcon icon={faPlus} className="mr-1" />
        Add new target
      </Button>
      <PlainModal onOpenChange={setIsOpen} isOpen={isOpen} className="min-w-[700px]">
        <div className="text-ccp-primary font-bold">Add new target</div>
        <div>Upload the CSV file below to add new target into the dashboard</div>

        {/* Drop zoness */}
        <div className="mt-4">
          <div
            className={clsx('border rounded-lg border-dashed py-8 min-h-[200px] flex items-center justify-center', {
              'border-green-600': isDragAccept,
              'border-red-400': isDragReject,
              'border-ccp-primary': !isDragAccept && !isDragReject,
            })}
            {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="flex flex-col items-center">
              {isDragReject && (
                <>
                  <FontAwesomeIcon icon={faCircleXmark} size="3x" className="text-ccp-red80" />
                  <div className="mt-4">File type not supported</div>
                </>
              )}

              {isDragAccept && (
                <>
                  <FontAwesomeIcon icon={faCircleCheck} size="3x" className="text-ccp-green80" />
                  <div className="mt-4">File type supported</div>
                </>
              )}

              {!isDragReject && !isDragAccept && (
                <>
                  <SvgIcon icon="CloudUpload" size={48} className="text-[#9B9DD7]" />
                  <div>Drag and drop files or Browse</div>
                  <div>Supported formats:CSV</div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* File Status section */}
        {selectedFiles?.length ? (
          <div className="p-4 mt-4 bg-slate-200 rounded-lg relative">
            {/* Clear file button */}
            <button
              className="hover:bg-ccp-hover h-6 w-6 rounded-full absolute top-[10px] right-[10px]"
              onClick={clearFile}>
              <FontAwesomeIcon icon={faClose} />
            </button>

            {/* Icon and file info */}
            <div className="flex flex-row">
              <SvgIcon icon={getIconForFileType(selectedFiles[0].type)} size={48} className="text-ccp-green40" />
              <div className="ml-3">
                <div className="text-sm font-medium">{selectedFiles[0].name}</div>
                <div className="text-xs text-ccp-grey90">
                  {formatBytes(selectedFiles[0].size)} •{' '}
                  {error ? <span className="text-red-400 italic">{error}</span> : PROGRESS_STEPS[uploadProgress]}
                </div>
              </div>
            </div>

            {/* Progress bar */}
            {uploadProgress > 0 && (
              <div className="h-1 w-full bg-white rounded-full overflow-hidden mt-2">
                <div
                  className={clsx('h-full transition-all', {
                    'bg-red-500': error,
                    'bg-ccp-green40': !error && uploadProgress === PROGRESS_STEPS.length - 1,
                    'bg-ccp-purple90': !error && uploadProgress < PROGRESS_STEPS.length - 1,
                  })}
                  style={{ width: `${(uploadProgress / (PROGRESS_STEPS.length - 1)) * 100}%` }}
                />
              </div>
            )}
          </div>
        ) : null}

        {/* Footer */}
        <div className="flex flex-row justify-center">
          <Button
            className="mx-auto mt-8"
            disabled={!selectedFiles?.length || (uploadProgress > 0 && uploadProgress < PROGRESS_STEPS.length - 1)}
            onClick={processFile}>
            Upload
          </Button>
        </div>
      </PlainModal>
    </div>
  )
}

export default UploadCsvModal
