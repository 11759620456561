import { FC, SVGProps } from 'react'
import { useLazySvgImport } from 'utils/hooks'

interface SvgIconProps extends SVGProps<SVGSVGElement> {
  /**
   * Name of svg file in src/assets/icons
   */
  icon: string
  /**
   * Dimension in pixel. by default this will be 16px i.e. 16px height X 16px width
   */
  size?: number
}

/**
 * Component to load svg as icon
 * HOW: Add svg file to 'src/assets/icons', then supply the svg name in through icon prop
 * @param icon svg name to be loaded by this component
 * @param svgProps generic SVG props that you usually use on actual svg element
 */
export const SvgIcon: FC<SvgIconProps> = (props) => {
  const { icon, size = 16, className, ...svgProps } = props
  const { Svg, error, loading } = useLazySvgImport(icon)
  const classNames = ['inline-block', className].filter(Boolean).join(' ')

  if (error) {
    console.error(`Error loading ${icon}.svg`)
    return null
  }

  if (loading) {
    // TODO: Add placeholder for icon while it's loading
    return <span style={{ height: size, width: size }} />
  }

  if (!Svg) {
    // Icon not found
    return null
  }

  return <Svg height={size} width={size} className={classNames} {...svgProps} />
}
