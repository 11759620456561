import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'components/Card'
import DropdownMenu from 'components/DropdownMenu'
import { SvgIcon } from 'components/SvgIcon'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/configureStore'
import { AocState } from 'redux/departmentRepresentative/aocSlice'
import { MetaFilterState, setHub } from 'redux/departmentRepresentative/metafilters'
import { getAocDetails } from 'redux/departmentRepresentative/thunk/getAocDetails'
import { getMonthlySummary } from 'redux/departmentRepresentative/thunk/getMontlySummary'
import { TransactionState } from 'redux/transactions'
import { formatCurrency } from 'utils/functions'
import TotalSalesImg from 'assets/images/card-bg/total-sales.svg'
import { format } from 'date-fns'
import clsx from 'clsx'

interface AdminSummaryCardProps {}

const AdminSummaryCard = (props: AdminSummaryCardProps) => {
  const {} = props
  const dispatch = useDispatch()
  const aocState = useSelector<RootState, AocState>((state) => state.aoc)
  const transactionState = useSelector<RootState, TransactionState>((state) => state.transactions)
  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const currency = aocState.aocCurrency
  const currentDate = new Date(Number(metaFilterState.currentYear), Number(metaFilterState.currentMonth) - 1, 1)

  // Get summary once the aoc list is available
  // Everytime the filter is changed, request new data
  useEffect(() => {
    if (metaFilterState.aoc) {
      dispatch(
        getMonthlySummary({
          month: Number(metaFilterState.currentMonth),
          year: Number(metaFilterState.currentYear),
          aoc: metaFilterState.aoc,
          hub: metaFilterState.hub,
        }),
      )
    }
  }, [metaFilterState.currentMonth, metaFilterState.currentYear, metaFilterState.aoc, metaFilterState.hub])

  // Get hubs list and aoc specific currency
  useEffect(() => {
    if (metaFilterState.aoc) {
      dispatch(
        getAocDetails({
          aoc: metaFilterState.aoc,
        }),
      )
      dispatch(setHub(''))
    }
  }, [metaFilterState.aoc])

  const ddItems = useMemo(() => {
    let hubs: any = []
    const generateItems = (hubs: any) =>
      hubs.map((hub: string) => ({
        label: hub,
        onClick: () => {
          dispatch(setHub(hub))
        },
      }))
    if (metaFilterState.aoc === 'AK') {
      // MAA
      hubs = generateItems(['KUL', 'PEN', 'JHB', 'BKI', 'KCH'])
    } else if (metaFilterState.aoc === 'D7') {
      // AAX
      hubs = generateItems(['KUL'])
    } else if (metaFilterState.aoc === 'FD') {
      // TAA
      hubs = generateItems(['BKK', 'CNX', 'DMK', 'HKT'])
    } else if (metaFilterState.aoc === 'QZ') {
      // IAA
      hubs = generateItems(['CGK', 'DPS', 'KNO', 'SUB'])
    } else if (metaFilterState.aoc === 'Z2') {
      // PAA
      hubs = generateItems(['MNL', 'CEB', 'CRK', 'KLO'])
    }

    return [
      {
        label: 'All',
        onClick: () => {
          dispatch(setHub(''))
        },
      },
      ...hubs,
    ]
  }, [metaFilterState.aoc])

  return (
    <>
      <div className="mt-4 flex flex-row justify-between">
        <div>
          <div className="text-ccp-primary font-bold">{format(currentDate, 'MMMM yyyy')}</div>
          <div>Monthly sales overviews</div>
        </div>
        <div>
          <span className="block text-xs">Hub</span>
          <DropdownMenu items={ddItems} disabled={aocState.aocDetailsIsLoading}>
            <button className="min-w-16 flex flex-row justify-between hover:bg-ccp-hover p-2 rounded -ml-2">
              <span
                className={clsx(
                  'font-bold text-sm',
                  aocState.aocDetailsIsLoading ? 'text-gray-300' : 'text-ccp-primary',
                )}>
                {metaFilterState.hub || 'All'}
              </span>
              <FontAwesomeIcon
                icon={faChevronDown}
                size={'sm'}
                className={clsx(aocState.aocDetailsIsLoading ? 'text-gray-300' : 'text-current')}
              />
            </button>
          </DropdownMenu>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-3 gap-4">
        <Card color="primary" bgImageUrl={TotalSalesImg} bgImagePosition="bg-no-repeat bg-right">
          <div className="text-xs">Total sales</div>
          <div className="text-xl">
            {currency} {formatCurrency(transactionState.totalSales)}
          </div>
        </Card>

        <Card>
          <div className="text-xs flex flex-row items-center">
            <SvgIcon icon="Monetization" size={20} className="text-ccp-green80 mr-1" />
            Total commission
          </div>
          <div className="text-xl">
            {currency} {formatCurrency(transactionState.totalCommission)}
          </div>
        </Card>

        <Card>
          <div className="text-xs flex flex-row items-center">
            <SvgIcon icon="SupervisedUser" size={20} className="text-ccp-red80 mr-1" />
            Total crew member
          </div>
          <div className="text-xl">{transactionState.totalCrewMember}</div>
        </Card>
      </div>
    </>
  )
}

export default AdminSummaryCard
