import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../apis'

export const getCrewListDetails = createAsyncThunk(
  'executive/getCrewListDetails',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await HttpGet('executive/crews', {
        aoc: params.aoc,
      })
      return response.data
    } catch (error: any) {
      console.log('get  all crew details error: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
