import { SvgIcon } from 'components/SvgIcon'
import { adminRoles, roles } from 'constants/roles'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { RootState } from 'redux/configureStore'
import { UserState } from 'redux/user'
import { adminNavItems, execNavItems, navItems } from './navItems'

export const Sidebar = () => {
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const location = useLocation()
  const navigate = useNavigate()

  const userRole = userState.extraDetails.role
  const isPathMatches = (url: string | undefined) => !isNil(url && matchPath(url, location.pathname))

  const handleClick = (url: string | undefined) => () => {
    if (url) navigate(url)
  }

  const navMenuItems = useMemo(() => {
    if (userRole !== undefined) {
      if (adminRoles.includes(userRole)) {
        return adminNavItems
      }

      if (userRole === roles.executive) {
        return execNavItems
      }

      return navItems
    }

    return []
  }, [userRole])

  return (
    <div className="flex flex-col w-16 shadow-[2px_-1px_4px_0_rgba(0,0,0,0.2)] z-[1]">
      {navMenuItems.map((item, idx) => (
        <div
          key={`${idx}`}
          className={`flex flex-col items-center mt-4 relative px-2 cursor-pointer ${
            isPathMatches(item.url) ? 'text-ccp-primary' : 'text-ccp-grey90'
          }`}
          onClick={handleClick(item.url)}>
          <SvgIcon icon={item.icon ?? ''} size={24} />
          <span className={`mt-2 text-center text-xs`}>{item.label}</span>

          <div
            className={`h-full absolute top-0 right-0 origin-right border-l-4 border-ccp-primary rounded-l transition-transform ${
              isPathMatches(item.url) ? 'scale-x-100' : 'scale-x-0'
            }`}
          />
        </div>
      ))}
    </div>
  )
}
