import React from 'react'
import { adminRoles, roles } from '../../constants/roles'
import { store } from '../../redux/configureStore'
import CrewHome from './crew/Home'
import DeptRepHome from './dept-representative/Home'

class Home extends React.Component {
  constructor(props: any) {
    super(props)
    this.reRender = this.reRender.bind(this)
  }

  reRender() {
    this.setState({})
    this.render()
  }

  render() {
    const userState = store.getState().user

    const role = userState.extraDetails.role

    let HomeDisplay: React.FC<any> = CrewHome

    if (role !== undefined) {
      if (adminRoles.includes(role) || role === roles.executive) {
        HomeDisplay = DeptRepHome
      } else {
        HomeDisplay = CrewHome
      }
    }

    return <HomeDisplay reRender={this.reRender}></HomeDisplay>
  }
}

export default Home
