import { ButtonHTMLAttributes, ReactNode } from 'react'
import cx from 'clsx'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'primary' | 'white' | 'custom'
  borderless?: boolean
}

const Button = (props: ButtonProps) => {
  const { color = 'white', borderless, children, className, ...otherProps } = props
  return (
    <button
      {...otherProps}
      className={cx(
        'rounded text-sm py-1 px-2',
        borderless ? 'border-0' : 'border',
        {
          'bg-gray-100 border-gray-200 text-gray-300': props.disabled,
          'bg-ccp-primary border-ccp-primary text-white': color === 'primary',
          'bg-white border-ccp-primary text-ccp-primary hover:bg-ccp-hover': color === 'white',
        },
        className,
      )}>
      {children}
    </button>
  )
}

export default Button
