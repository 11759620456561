import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { min } from 'lodash'
import { useState } from 'react'
import DayPicker from '.'

export type DateRange = { from: Date; to: Date }

interface DateRangePickerProps {
  isMobile?: boolean
  selectedRange?: DateRange
  defaultSelectedRange?: DateRange
  className?: string
  onSelectedRangeChange?: (newValue?: DateRange) => void
}

const DateRangePicker = (props: DateRangePickerProps) => {
  const todaysDate = new Date()
  const defaultDateRange = {
    from: new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1),
    to: todaysDate,
  }
  const {
    isMobile = false,
    selectedRange,
    defaultSelectedRange = defaultDateRange,
    className = '',
    onSelectedRangeChange,
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [internalSelected, setInternalSelected] = useState<DateRange>(defaultSelectedRange)
  const selected = selectedRange ?? internalSelected
  // Get either from date or to date as reference
  const selectedDateRef = internalSelected?.from ?? internalSelected?.to
  const firstDayOfMonth = selectedDateRef
    ? new Date(selectedDateRef.getFullYear(), selectedDateRef.getMonth(), 1)
    : undefined
  const lastDayOfMonth = selectedDateRef
    ? new Date(selectedDateRef.getFullYear(), selectedDateRef.getMonth() + 1, 0)
    : undefined

  const formatDate = (date: Date | undefined) => (date ? format(date, 'dd MMMM, yyyy') : null)

  const handleDateSelected = (newVal: any) => {
    setInternalSelected(newVal)
  }

  const handleOpenChange = (isOpening: boolean) => {
    if (isOpening) {
      // Ensure internal value is same as outside value
      if (selectedRange) setInternalSelected(selectedRange)
    }

    setIsOpen(isOpening)
  }

  const onConfirmClick = () => {
    if (internalSelected?.from && internalSelected?.to) {
      onSelectedRangeChange?.(internalSelected)
    }
    setIsOpen(false)
  }

  return (
    <DayPicker
      mode="range"
      defaultMonth={internalSelected?.from}
      selected={internalSelected}
      onSelect={handleDateSelected}
      disabled={{
        before: firstDayOfMonth,
        after: min([lastDayOfMonth, new Date()]) ?? new Date(),
      }}
      isMobile={isMobile}
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      onConfirmClick={onConfirmClick}
      confirmBtnDisabled={!internalSelected?.from || !internalSelected?.to}
      triggerClassName={className}>
      <div className={`p-2 min-w-[200px] flex flex-row items-center rounded border hover:bg-ccp-hover`}>
        <span className="mr-2 grow">
          {format(selected?.from, 'dd') ?? 'from'} - {formatDate(selected?.to) ?? 'to'}
        </span>
        <FontAwesomeIcon icon={faCalendarDay} />
      </div>
    </DayPicker>
  )
}

export default DateRangePicker
