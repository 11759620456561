import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import PictureAsPdf from '@mui/icons-material/PictureAsPdf'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { format } from 'date-fns'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HttpGet } from '../../apis'
import productCategory from '../../common/helpers/productCategory'
import { MainContainer } from '../../components/Container'
import ConfirmDownloadModal from '../../components/Modal/ConfirmDownloadModal'
import TotalRecords from '../../components/TotalRecords/TotalRecords'
import { productCategories } from '../../config/productCategories'
import { route } from '../../constants/routes'
import { RootState } from '../../redux/configureStore'
import { getFlightFilterList } from '../../redux/thunk/getFlightFilterList'
import { getFlightSummary } from '../../redux/thunk/getFlightSummary'
import { getFlightSummaryDetails } from '../../redux/thunk/getFlightSummaryDetails'
import { FlightSummary, TransactionDetails, TransactionState } from '../../redux/transactions'
import { UserState } from '../../redux/user'
import { currencyFormatToCountry } from '../../utils/currencyHelper/formatToCountry'
import { convertToDate } from '../../utils/dateHelper/dateHelper'

const ExpandMore = styled((props: any) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

const Transactions = () => {
  const dispatch = useDispatch()
  const theme = useTheme()

  const [openCalendar, setOpenCalendar] = useState<boolean>(false)

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const userState = useSelector<RootState, UserState>((state) => state.user)
  const transactionState = useSelector<RootState, TransactionState>((state) => state.transactions)

  const [fromDate, setFromDate] = useState<any>(new Date(transactionState.dateFilterFromTransactionPage))
  const [toDate, setToDate] = useState<any>(new Date(transactionState.dateFilterToTransactionPage))

  const { flightSummaryTransactions } = transactionState

  const [viewTabularMode, setViewTabularMode] = useState<boolean>(true)

  const [selectedFlightNumber, setSelectedFlightNumber] = useState<string>('')
  const [selectedProductCategory, setSelectedProductCategory] = useState<string>('')

  const selectedDateFunc = (value: any) => {
    setOpenCalendar(false)
    console.log(value)
    setFromDate(value[0])
    setToDate(value[1])

    dispatch(
      getFlightSummary({
        crew_id: userState.extraDetails.crew_id || '100000',
        from_date: value[0],
        to_date: value[1],
        updateCommissionNsales: false,
      }),
    )
  }

  const applyFilterFunc = () => {
    const params: any = {
      crew_id: userState.extraDetails.crew_id || '100000',
      from_date: fromDate,
      to_date: toDate,
      updateCommissionNsales: false,
    }

    if (selectedFlightNumber !== '') {
      params.flight_pair = selectedFlightNumber
    }

    if (selectedProductCategory !== '') {
      params.product_category = selectedProductCategory
    }

    dispatch(getFlightSummary(params))
  }

  const resetFilterFunc = () => {
    setSelectedFlightNumber('')
    setSelectedProductCategory('')
  }

  const [transactionsArr, setTransactionsArr] = useState<Array<any>>([])
  const [pageCount, setPageCount] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const COUNT_PER_PAGE = 20

  const nextPage = () => {
    const callNextPageApi = currentPage > pageCount ? true : false

    if (pageCount !== 1 && callNextPageApi === false) {
      const nextPage = currentPage + 1
      const startFrom = currentPage * COUNT_PER_PAGE
      const nextPageRecord = flightSummaryTransactions.slice(startFrom, startFrom + COUNT_PER_PAGE + 1)

      setCurrentPage(nextPage)
      setTransactionList(nextPageRecord)

      if (nextPage === pageCount) {
        setIsNextPage(false)
      }
    }
  }

  const prevPage = () => {
    const previousPage = currentPage - 1

    const startFrom = previousPage === 1 ? 0 : (previousPage - 1) * COUNT_PER_PAGE
    const prevPageRecord = flightSummaryTransactions.slice(startFrom, startFrom + COUNT_PER_PAGE)

    setCurrentPage(previousPage)
    setTransactionList(prevPageRecord, false)
    setIsNextPage(true)
  }

  const setTransactionList = (arr: any[], splice1: boolean = true) => {
    const haveNextPage = arr.length > 20

    if (haveNextPage) {
      setIsNextPage(true)
      if (splice1) {
        arr.splice(-1)
      }
    } else {
      setIsNextPage(false)
    }

    setTransactionsArr(arr)
  }

  const initTransactionList = useCallback(() => {
    const tArr: any[] = flightSummaryTransactions.slice(0, COUNT_PER_PAGE + 1)
    setPageCount(Math.ceil(flightSummaryTransactions.length / COUNT_PER_PAGE))

    setTransactionList(tArr)
  }, [flightSummaryTransactions])

  const [flightList, setFlightList] = useState<Array<string>>([])

  const onFlightSearch = async (evt: React.SyntheticEvent, value: string, reason: string) => {
    // TODO: call an api that will do a name search in partial "LIKE" way
    if (value !== '') {
      let params: any = {}

      params.search_pair = value
      params.crew_id = userState.extraDetails.crew_id
      params.limit = 100

      const response = await HttpGet('/flightnumbers', params)
      const results: any[] = response.data.data

      let searched: string[] = []

      for (let flightData of results) {
        for (let number of flightData.flight_numbers) {
          if (!searched.includes(number)) {
            searched.push(number)
          }
        }
      }

      if (searched.length > 0) {
        searched.sort()
      }

      setFlightList(searched)
    } else {
      setFlightList([])
    }
  }

  useEffect(() => {
    dispatch(getFlightFilterList({ crew_id: userState.extraDetails.crew_id || '100000' }))
  }, [dispatch, userState.extraDetails.crew_id])

  useEffect(() => {
    if (isMobile) {
      setViewTabularMode(false)
    } else {
      setViewTabularMode(true)
    }
  }, [isMobile])

  const [isAdvanceFilterOpen, setIsAdvanceFilterOpen] = useState<boolean>(false)

  const [confirmDownloadModal, setConfirmDownloadModal] = useState<boolean>(false)

  const downloadTransactions = useCallback(async () => {
    setConfirmDownloadModal(false)

    let params: any = {
      crew_id: userState.extraDetails.crew_id ?? '100000',
      from_date: format(fromDate, 'yyyy-MM-dd'),
      to_date: format(toDate, 'yyyy-MM-dd'),
      type: 'PDF',
    }

    if (selectedFlightNumber !== '') {
      params.flight_pairing = selectedFlightNumber
    }

    if (selectedProductCategory !== '') {
      params.product_category = selectedProductCategory
    }

    const newWindow: any = window.open(route.DOWNLOADING, '_blank', 'popup')
    newWindow.state = params
  }, [fromDate, selectedFlightNumber, selectedProductCategory, toDate, userState])

  useEffect(() => {
    initTransactionList()
    dispatch(getFlightFilterList({ crew_id: userState.extraDetails.crew_id || '100000' }))
  }, [dispatch, userState.extraDetails.crew_id, initTransactionList])

  return (
    <MainContainer title="Transactions" sideMenu={false}>
      <div style={{ paddingBottom: 30 }}></div>
      {isAdvanceFilterOpen ? (
        <>
          <div onClick={() => setOpenCalendar(!openCalendar)} style={{ cursor: 'pointer' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              {/* <DateRangePicker
                showOneCalendar
                format="dd-MM-yyyy"
                character=" to "
                onOk={selectedDateFunc}
                disabledDate={afterToday()}
                ranges={[]}
                defaultValue={[fromDate, toDate]}
                cleanable={false}
                size="lg"
              /> */}
            </Box>
          </div>

          <Grid container spacing={2} sx={{ mb: 5 }} style={{ marginTop: 5 }}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl variant="standard" fullWidth>
                <Autocomplete
                  disablePortal
                  id="flight_pairing"
                  noOptionsText={'e.g: AK0071'}
                  options={flightList.map((item) => item)}
                  renderInput={(params: any) => <TextField {...params} variant="standard" label="Flight Pair" />}
                  onInputChange={onFlightSearch}
                  value={selectedFlightNumber}
                  onChange={(evt, value: any) => setSelectedFlightNumber(value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="demo-simple-select-label">Product Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedProductCategory}
                  onChange={(evt) => setSelectedProductCategory(evt.target.value)}
                  label="Product Category">
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>

                  {productCategories.map((each: any, index: any) => (
                    <MenuItem key={index} value={each}>
                      {each}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ mb: 5 }} justifyContent="flex-end">
            <Grid item xs={12} sm={4} md={2} sx={{ mb: 1 }} justifyContent="flex-end">
              <Button size="small" variant="outlined" sx={{ width: '100%' }} onClick={resetFilterFunc}>
                Reset
              </Button>
            </Grid>
            <Grid item xs={0} sm={0.1} md={0.1} />
            <Grid item xs={12} sm={4} md={2} sx={{ mb: 1 }} justifyContent="flex-end">
              <Button size="small" variant="contained" sx={{ width: '100%' }} onClick={applyFilterFunc}>
                Apply
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box style={{ marginBottom: 10 }}></Box>
      )}

      <Box sx={{ float: 'right', mt: -2, mr: 1, display: 'flex', flexDirection: 'row' }}>
        <IconButton
          aria-label="filter"
          sx={{ backgroundColor: '#e4e4e4', marginRight: 1 }}
          onClick={() => setConfirmDownloadModal(true)}>
          {<PictureAsPdf sx={{ color: '#757575' }} />}
        </IconButton>
        <IconButton
          aria-label="filter"
          sx={{ backgroundColor: '#e4e4e4' }}
          onClick={() => setIsAdvanceFilterOpen(!isAdvanceFilterOpen)}>
          {isAdvanceFilterOpen ? (
            <FilterAltOffIcon sx={{ color: '#757575' }} />
          ) : (
            <FilterAltIcon sx={{ color: '#757575' }} />
          )}
        </IconButton>
      </Box>

      <Box sx={{ mt: 2, backgroundColor: '#f8f8f8', p: 3, borderRadius: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Box sx={{ mt: -4, mr: 0 }}>
            <TotalRecords total={flightSummaryTransactions.length} />
          </Box>
        </Box>

        {transactionState.flightSummaryIsLoading ? (
          <Box
            sx={{
              height: '15vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CircularProgress size={32} />
          </Box>
        ) : transactionsArr.length === 0 ? (
          <Typography variant="h2" sx={{ fontWeight: 500, mt: 5, fontSize: 18, textAlign: 'center' }}>
            ~ You don't have any transactions ~
          </Typography>
        ) : viewTabularMode ? (
          <>
            <Box sx={{ display: 'flex', flex: 1, mt: 3, p: 2, pb: 0 }}>
              <Typography sx={{ display: 'flex', fontFamily: 'Roboto', flex: 1, textDecoration: 'underline' }}>
                Flight Pair
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}>
                Date
              </Typography>
              {/* Note: it is not RM, it will be dynamic base on the AOC */}
              <Typography
                sx={{
                  display: 'flex',
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}>
                Sales
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  flex: 1,
                  justifyContent: 'flex-end',
                }}>
                Commission
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  fontFamily: 'Roboto',
                  textDecoration: 'underline',
                  flex: 0.4,
                  justifyContent: 'flex-end',
                }}
              />
            </Box>

            <Box>
              {transactionsArr.map((summary, index) => {
                return (
                  <TabularCard
                    key={index}
                    flight_number={summary.flight_number}
                    data={summary}
                    selectedProductCategory={selectedProductCategory}
                  />
                )
              })}
            </Box>
          </>
        ) : (
          // Mobile View
          <Box>
            {transactionsArr.map((summary, index) => {
              return (
                <CardStyle
                  flight_number={summary.flight_number}
                  data={summary}
                  key={index}
                  selectedProductCategory={selectedProductCategory}
                />
              )
            })}
          </Box>
        )}
      </Box>
      <Stack spacing={2} direction="row" justifyContent={'flex-end'} sx={{ mt: 5 }}>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          style={{ width: 100 }}
          onClick={prevPage}
          disabled={currentPage <= 1 ? true : false}>
          Prev
        </Button>
        <Button variant="outlined" size="small" style={{ width: 100 }} onClick={nextPage} disabled={!isNextPage}>
          Next
        </Button>
      </Stack>
      <div style={{ height: 15 }} />
      <ConfirmDownloadModal
        open={confirmDownloadModal}
        onClose={() => setConfirmDownloadModal(false)}
        title={'Download Reports'}
        onClick={async () => await downloadTransactions()}
        description={`Are you sure you want to download records from ${format(fromDate, 'dd-MM-yyyy')} to ${format(
          toDate,
          'dd-MM-yyyy',
        )}?`}
      />
    </MainContainer>
  )
}

export default Transactions

const CollapseCard = ({
  expanded,
  transactions,
  currency,
  sales,
  commissions,
}: {
  expanded: boolean
  transactions: Array<TransactionDetails>
  currency: string
  sales: number
  commissions: number
}) => {
  const haveData = transactions.length > 0 ? true : false

  return !haveData ? (
    <Typography variant="h2" sx={{ p: 2 }}>
      Loading details...
    </Typography>
  ) : (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Box sx={{ display: 'flex', flex: 1, mt: 3 }}>
          <Typography sx={{ display: 'flex', fontFamily: 'Poppins', textDecoration: 'underline', flex: 1.5 }}>
            Product
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
              flex: 0.5,
              justifyContent: 'center',
            }}>
            Quantity
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
              flex: 1,
              justifyContent: 'flex-end',
            }}>
            Category
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
              flex: 1,
              justifyContent: 'flex-end',
            }}>
            Sales
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
              flex: 1,
              justifyContent: 'flex-end',
            }}>
            Commission
          </Typography>
        </Box>

        {transactions.map((transaction: TransactionDetails, index) => {
          return (
            <Box sx={{ display: 'flex', flex: 1, mt: 3 }} key={index}>
              <Typography sx={{ display: 'flex', flex: 1.5, fontSize: 15, fontFamily: 'Montserrat' }}>
                {transaction.product}
              </Typography>
              <Typography sx={{ display: 'flex', flex: 0.5, justifyContent: 'center' }}>
                x {transaction.quantity}
              </Typography>
              <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', textAlign: 'end', fontSize: 13 }}>
                {transaction.product_category}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                  wordBreak: 'break-word',
                  textAlign: 'end',
                }}>
                {currencyFormatToCountry(transaction.net_amount, currency)}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                  wordBreak: 'break-word',
                  textAlign: 'end',
                }}>
                {currencyFormatToCountry(transaction.commission, currency)}
              </Typography>
            </Box>
          )
        })}

        <Box sx={{ display: 'flex', flex: 1, mt: 3 }}>
          <Typography sx={{ display: 'flex', flex: 1.5, fontSize: 15, fontFamily: 'Montserrat' }}></Typography>
          <Typography sx={{ display: 'flex', flex: 0.5, justifyContent: 'center' }}></Typography>
          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', textAlign: 'end', pr: 2 }}>
            Total
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              wordBreak: 'break-word',
              textAlign: 'end',
              borderTopWidth: 2,
              borderTopStyle: 'double',
              borderTopColor: '#4d4d4d',
            }}>
            {currencyFormatToCountry(sales, currency)}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              wordBreak: 'break-word',
              textAlign: 'end',
              borderTopWidth: 2,
              borderTopStyle: 'double',
              borderTopColor: '#4d4d4d',
            }}>
            {currencyFormatToCountry(commissions, currency)}
          </Typography>
        </Box>
      </CardContent>
    </Collapse>
  )
}

const TabularCard = ({
  flight_number,
  data,
  selectedProductCategory = '',
}: {
  flight_number: string | number
  data: FlightSummary
  selectedProductCategory: string
}) => {
  const dispatch = useDispatch()

  const userState = useSelector<RootState, UserState>((state) => state.user)
  const transactionState = useSelector<RootState, TransactionState>((state) => state.transactions)

  const [expanded, setExpanded] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)

  // TODO: pass the product category to the function if user select the filter

  const flightSummaryIndex = transactionState.flightSummaryTransactions.findIndex((flightSummary: FlightSummary) => {
    return flightSummary.flight_number === flight_number && flightSummary.date === data.date
  })

  const handleExpandClick = () => {
    const params: any = {
      flight_pair: flight_number,
      from_date: new Date(data.date),
      to_date: new Date(data.date),
      crew_id: userState.extraDetails.crew_id,
    }

    if (selectedProductCategory !== '') {
      params.product_category = selectedProductCategory
    }

    if (
      flightSummaryIndex !== -1 &&
      transactionState.flightSummaryTransactions[flightSummaryIndex]?.transactions === undefined
    ) {
      setIsLoading(true)
      dispatch(getFlightSummaryDetails(params))
    }

    setExpanded(!expanded)
  }

  useEffect(() => {
    if (flightSummaryIndex !== -1) {
      if (transactionState.flightSummaryTransactions[flightSummaryIndex]?.transactions) {
        setIsLoading(false)
      }
    }
  }, [flightSummaryIndex, flight_number, transactionState.flightSummaryTransactions])

  return (
    <Fragment>
      <Card sx={{ mt: 3, p: 2 }}>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Typography sx={{ display: 'flex', flex: 1 }}>{data.flight_number}</Typography>
          <Typography sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
            {convertToDate(data.date)}
          </Typography>
          <Typography
            sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', wordBreak: 'break-word', textAlign: 'end' }}>
            {currencyFormatToCountry(data.sales, userState.aocDetails.currency)}
          </Typography>
          <Typography
            sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', wordBreak: 'break-word', textAlign: 'end' }}>
            {currencyFormatToCountry(data.commissions, userState.aocDetails.currency)}
          </Typography>

          <Box sx={{ display: 'flex', flex: 0.4, alignItems: 'center' }} style={{ marginTop: -5 }}>
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              sx={{ cursor: 'pointer', color: '#575656' }}
              size="small">
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </Box>
        {expanded && <Box sx={{ backgroundColor: '#b8b8b8' }} style={{ height: 1, marginTop: 10 }} />}

        {isLoading ? (
          <Typography variant="h2" sx={{ p: 2 }}>
            Loading details...
          </Typography>
        ) : (
          data?.transactions && (
            <CollapseCard
              expanded={expanded}
              currency={userState.aocDetails.currency}
              transactions={data.transactions}
              sales={data.sales}
              commissions={data.commissions}
            />
          )
        )}
      </Card>
    </Fragment>
  )
}

const CardStyle = ({
  flight_number,
  data,
  selectedProductCategory = '',
}: {
  flight_number: string | number
  data: FlightSummary
  selectedProductCategory: string
}) => {
  const dispatch = useDispatch()

  const userState = useSelector<RootState, UserState>((state) => state.user)
  const transactionState = useSelector<RootState, TransactionState>((state) => state.transactions)

  const [expanded, setExpanded] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)

  const flightSummaryIndex = transactionState.flightSummaryTransactions.findIndex((flightSummary: FlightSummary) => {
    return flightSummary.flight_number === flight_number && flightSummary.date === data.date
  })

  const handleExpandClick = () => {
    const params: any = {
      flight_pair: flight_number,
      from_date: new Date(data.date),
      to_date: new Date(data.date),
      crew_id: userState.extraDetails.crew_id,
    }

    if (selectedProductCategory !== '') {
      params.product_category = selectedProductCategory
    }

    if (flightSummaryIndex !== -1) {
      setIsLoading(true)
      dispatch(getFlightSummaryDetails(params))
    }

    setExpanded(!expanded)
  }

  useEffect(() => {
    if (flightSummaryIndex !== -1) {
      if (transactionState.flightSummaryTransactions[flightSummaryIndex]?.transactions) {
        setIsLoading(false)
      }
    }
  }, [flightSummaryIndex, flight_number, transactionState.flightSummaryTransactions])

  return (
    <Box>
      <Card sx={{ mt: 3, maxWidth: 600, p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: 16, color: '#2c2c2c' }} style={{ fontFamily: 'Poppins' }}>
            {data.flight_number}
          </Typography>
          <IconButton
            sx={{
              backgroundColor: '#f8f8f8',
              // Add this to fix the background color issue when clicked
              '&:hover': {
                '@media (hover: none)': {
                  backgroundColor: '#f8f8f8',
                },
              },
            }}
            onClick={handleExpandClick}>
            {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
        <Typography sx={{ fontSize: 13, mt: 1, color: '#919191' }}>{convertToDate(data.date)}</Typography>
        <Typography sx={{ mt: 2 }}>
          <Typography component="span" sx={{ fontSize: 14, color: '#4d4d4d' }}>
            Sales:{' '}
          </Typography>
          {currencyFormatToCountry(data.sales, userState.aocDetails.currency)}
        </Typography>
        <Typography>
          <Typography component="span" sx={{ fontSize: 14, color: '#4d4d4d' }}>
            Commission:{' '}
          </Typography>
          {currencyFormatToCountry(data.commissions, userState.aocDetails.currency)}
        </Typography>
        {isLoading ? (
          <Typography variant="h2" sx={{ p: 2 }}>
            Loading details...
          </Typography>
        ) : (
          data?.transactions && (
            <CardStyleCollapse
              expanded={expanded}
              transactions={data.transactions}
              currency={userState.aocDetails.currency}
              sales={data.sales}
              commissions={data.commissions}
            />
          )
        )}
      </Card>
    </Box>
  )
}

const CardStyleCollapse = ({
  expanded,
  transactions,
  currency,
  sales,
  commissions,
}: {
  expanded: boolean
  transactions: Array<TransactionDetails>
  currency: string
  sales: number
  commissions: number
}) => {
  return (
    <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Box sx={{ display: 'flex', flex: 1, mt: 3 }}>
          <Typography sx={{ display: 'flex', fontFamily: 'Poppins', textDecoration: 'underline', flex: 1.5 }}>
            Prd
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
              flex: 1,
              justifyContent: 'start',
            }}>
            Qty
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
              flex: 1,
              justifyContent: 'center',
            }}>
            Ctg
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
              flex: 1,
              justifyContent: 'center',
            }}>
            Sls
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              fontFamily: 'Poppins',
              textDecoration: 'underline',
              flex: 1,
              justifyContent: 'center',
            }}>
            Cms
          </Typography>
        </Box>

        {transactions.map((transaction: TransactionDetails, index) => {
          return (
            <Box sx={{ display: 'flex', flex: 1, mt: 3 }} key={index}>
              <Typography sx={{ display: 'flex', flex: 1.5, fontSize: 12, fontFamily: 'Montserrat' }}>
                {transaction.product}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignSelf: 'center',
                  fontSize: 12,
                }}>
                x {transaction.quantity}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  flex: 1,
                  textAlign: 'center',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  fontSize: 12,
                }}>
                {productCategory(transaction.product_category)}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                  wordBreak: 'break-word',
                  textAlign: 'center',
                  fontSize: 12,
                  alignSelf: 'center',
                }}>
                {currencyFormatToCountry(transaction.net_amount, currency)}
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'flex-end',
                  wordBreak: 'break-word',
                  textAlign: 'center',
                  fontSize: 12,
                  alignSelf: 'center',
                }}>
                {currencyFormatToCountry(transaction.commission, currency)}
              </Typography>
            </Box>
          )
        })}

        <Box sx={{ display: 'flex', flex: 1, mt: 3 }}>
          <Typography sx={{ display: 'flex', flex: 1.5, fontSize: 15, fontFamily: 'Montserrat' }}></Typography>
          <Typography sx={{ display: 'flex', flex: 0.5, justifyContent: 'center' }}></Typography>
          <Typography
            sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', textAlign: 'end', pr: 2, fontSize: 14 }}>
            Total
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              wordBreak: 'break-word',
              textAlign: 'end',
              borderTopWidth: 2,
              borderTopStyle: 'double',
              borderTopColor: '#4d4d4d',
              fontSize: 14,
            }}>
            {currencyFormatToCountry(sales, currency)}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              wordBreak: 'break-word',
              textAlign: 'end',
              borderTopWidth: 2,
              borderTopStyle: 'double',
              borderTopColor: '#4d4d4d',
              fontSize: 14,
            }}>
            {currencyFormatToCountry(commissions, currency)}
          </Typography>
        </Box>
      </CardContent>
    </Collapse>
  )
}
