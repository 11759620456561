import DownloadReportsIcon from 'assets/icons/DownloadReports.svg'
import DateRangePicker from 'components/DayPicker/DateRangePicker'
import { useDownloadReportHelper, useHeaderDateRangeHelper } from '../utils'
import { CircularProgress } from '@mui/material'
import { SvgIcon } from 'components/SvgIcon'
import DropdownMenu from 'components/DropdownMenu'

type HeaderToolbarProps = {
  className: string
  isDesktop: boolean
}

const HeaderToolbar = (props: HeaderToolbarProps) => {
  const { isDesktop, className } = props

  const { selectedDateRange, setSelectedDateRange } = useHeaderDateRangeHelper()
  
  const { downloadCrewReports, downloadingReport, DownloadCrewDropdownItems, hasMenu } = useDownloadReportHelper()

  return (
    <div className={`items-center flex flex-row ${className}`}>
      <DateRangePicker
        isMobile={!isDesktop}
        selectedRange={selectedDateRange}
        onSelectedRangeChange={setSelectedDateRange}
        className={isDesktop ? '' : 'w-full'}
      />
      <span className="mx-2 my-2 border-l self-stretch" />
      { hasMenu ? (
        <DropdownMenu items={DownloadCrewDropdownItems}>
          <button
            disabled={downloadingReport}
            onClick={downloadCrewReports}
            className="relative border-0 rounded-full h-11 w-11 hover:bg-ccp-hover">
            {downloadingReport ? <CircularProgress size={18} /> : <SvgIcon icon={'DownloadReports'} size={24} />}
          </button>
        </DropdownMenu>
      ) : (
        <button
          disabled={downloadingReport}
          onClick={downloadCrewReports}
          className="relative border-0 rounded-full h-11 w-11 hover:bg-ccp-hover">
          {downloadingReport ? <CircularProgress size={18} /> : <SvgIcon icon={'DownloadReports'} size={24} />}
        </button>
      )}
    </div>
  )
}

export default HeaderToolbar
