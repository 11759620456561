import { format, fromUnixTime } from 'date-fns'

export const getDateFromUnix = (timestamp: number) => {
  const getDateObj = fromUnixTime(timestamp)

  return {
    date: format(getDateObj, 'dd-MM-yyyy'),
    time: getDateObj.getTime(),
    dateObj: getDateObj,
  }
}

export const convertToDate = (date: string) => {
  const newDate = new Date(date.replace(/-/g, "/"))

  return format(newDate, 'dd-MM-yyyy')
}
