import { useSelector } from 'react-redux'
import Card from '.'
import { RootState } from 'redux/configureStore'
import { AocState } from 'redux/departmentRepresentative/aocSlice'
import user, { UserState } from 'redux/user'
import { CrewListState } from 'redux/departmentRepresentative/crewSlice'

interface TierRankingCardProps {
  sector?: string
  target?: number
  sales?: number
  isLoading?: boolean
}

const TierRankingCard = (props: TierRankingCardProps) => {
  const { isLoading } = props

  const aocState = useSelector<RootState, AocState>((state) => state.aoc)
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const crewListState = useSelector<RootState, CrewListState>((state) => state.crewList)
  const currency = crewListState.currentCrewInfo?.currency || aocState.aocCurrency || userState.aocDetails.currency

  return (
    <Card>
      <div className={`text-base font-medium font-dmSans text-dark-300 ${isLoading ? 'skeleton w-16 h-6' : ''}`}>
        {isLoading ? null : props.sector}
      </div>

      {/* separator */}
      <hr className="h-[1px] bg-purple-200" />

      <div className="flex">
        <div className="grow shrink-0 flex flex-col gap-2">
          {isLoading ? (
            <>
              <div className="skeleton w-16" />
              <div className="skeleton w-20 h-2" />
            </>
          ) : (
            <>
              <div className="text-sm font-dmSans text-ccp-grey90">
                {currency} {props.target?.toFixed(2)}
              </div>
              <div className="text-xs font-dmSans text-dark-200">Sector target</div>
            </>
          )}
        </div>

        <div className="grow shrink-0 flex flex-col gap-2">
          {isLoading ? (
            <>
              <div className="skeleton w-16" />
              <div className="skeleton w-7 h-3" />
            </>
          ) : (
            <>
              <div className="text-sm font-dmSans text-ccp-grey90">
                {currency} {props.sales?.toFixed(2)}
              </div>
              <div className="text-xs font-dmSans text-dark-200">Sales</div>
            </>
          )}
        </div>
      </div>
    </Card>
  )
}

export default TierRankingCard
