import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../../apis'
import { AxiosResponse } from 'axios'

export interface MonthlySummaryApiParams {
  year: number
  month: number
  aoc: string
  hub: string
}

interface MonthlySummaryApiResponse {
  total_sales: number
  total_commission: number
  total_crews: number
  aoc: string
  hub: string
  month: number
  year: number
  currency: string
}

// First, create the thunk
export const getMonthlySummary = createAsyncThunk(
  '/sales/getMonthlySummary',
  async (params: MonthlySummaryApiParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<MonthlySummaryApiResponse> = await HttpGet('/sales/monthly-summary', params)
      return response.data
    } catch (error: any) {
      console.error('[error] failed getting monthly summary: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
