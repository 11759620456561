import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from 'apis'
import { AxiosResponse } from 'axios'

export interface TotalCommission {
  perishable: number
  total: number
  non_perishable: number
  mcds: number
  dtf: number
  domlux: number
}

interface TotalTarget {
  perishable: number
  total: number
  non_perishable: number
  mcds: number
  dtf: number
  domlux: number
}

interface TotalSales {
  perishable: number
  total: number
  non_perishable: number
  mcds: number
  dtf: number
  domlux: number
}

export interface FlightPairingTransactionsItem {
  flight_number: string
  product_name: string
  sales: number
  product_quantity: number
  status: string
  commission: number
  category: string
}

export interface FlightPairingTransaction {
  total_commission: TotalCommission
  total_target: TotalTarget
  date_string: string
  flight_number_pairing: string
  currency: string
  id: string
  total_sales: TotalSales
  aoc: string
  flight_number: string
  transactionsItems: FlightPairingTransactionsItem[]
}

interface FlightPairingsResponse {
  data: FlightPairingTransaction[]
  total_page: number
  current_page: number
  page_size: number
}

interface FlightPairingsParam {
  crew_id: string
  month?: number
  year?: number
  page?: number
  page_size?: number
  flight_pairing?: string
}

export const getFlightPairingsList = createAsyncThunk(
  'transactions/getFlightPairingsList',
  async (params: FlightPairingsParam, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<FlightPairingsResponse> = await HttpGet('crew/dashboard/transaction-view', params)

      return response.data
    } catch (error: any) {
      console.error('[error] Error getting flight pairings list: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
