import { createSlice } from '@reduxjs/toolkit'
import { CrewStatistics, getCrewStatistics } from './thunk/getCrewStatistics'
import { CrewDetails, getCrewDetails } from './thunk/getCrewDetails'
import { Pagination } from 'components/Pagination'
import { CrewList, getCrewList } from './thunk/getCrewList'
import { CrewSummaryData, getCrewSummary } from 'redux/thunk/getCrewSummary'

export interface CrewListState {
  list: CrewList[]
  listIsLoading?: boolean
  listPagination: Pagination
  listWithDetails: CrewDetails[]
  listWithDetailsPagination: Pagination
  listWithDetailsIsLoading: boolean
  haveNextPage: boolean
  statistics: CrewStatistics | undefined
  statisticsIsLoading?: boolean
  currentCrewInfo: CrewSummaryData | null
  crewSummaryIsLoading?: boolean
}

const initialState: CrewListState = {
  list: [],
  listIsLoading: false,
  listPagination: { current_page: 1, page_size: 20 },
  listWithDetails: [],
  listWithDetailsPagination: { current_page: 1, page_size: 5 },
  listWithDetailsIsLoading: false,
  haveNextPage: false,
  statistics: undefined,
  statisticsIsLoading: false,
  currentCrewInfo: null,
  crewSummaryIsLoading: false,
}

export const crewSlice = createSlice({
  name: 'crew',
  initialState,
  reducers: {
    resetTransctionsState: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(getCrewList.pending, (state) => {
      state.listIsLoading = true
    })
    builder.addCase(getCrewList.fulfilled, (state, action) => {
      state.list = action.payload?.data ?? []
      state.listPagination = {
        current_page: action.payload?.current_page ?? 1,
        page_size: action.payload?.page_size ?? 5,
        total_page: action.payload?.total_page ?? -1,
      }
      state.listIsLoading = false
    })
    builder.addCase(getCrewList.rejected, (state) => {
      state.listIsLoading = false
    })

    builder.addCase(getCrewStatistics.pending, (state) => {
      state.statisticsIsLoading = true
    })
    builder.addCase(getCrewStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload
      state.statisticsIsLoading = false
    })
    builder.addCase(getCrewStatistics.rejected, (state) => {
      state.statisticsIsLoading = false
      state.statistics = undefined
    })

    builder.addCase(getCrewDetails.pending, (state) => {
      state.listWithDetailsIsLoading = true
    })
    builder.addCase(getCrewDetails.fulfilled, (state, action) => {
      state.listWithDetails = action.payload?.data ?? []
      state.listWithDetailsPagination = {
        current_page: action.payload?.current_page ?? 1,
        page_size: action.payload?.page_size ?? 5,
        total_page: action.payload?.total_page ?? -1,
      }
      state.listWithDetailsIsLoading = false
    })
    builder.addCase(getCrewDetails.rejected, (state) => {
      state.listWithDetailsIsLoading = false
    })

    builder.addCase(getCrewSummary.pending, (state) => {
      state.crewSummaryIsLoading = true
    })
    builder.addCase(getCrewSummary.fulfilled, (state, action) => {
      state.currentCrewInfo = action.payload ?? null
      state.crewSummaryIsLoading = false
    })
    builder.addCase(getCrewSummary.rejected, (state) => {
      state.crewSummaryIsLoading = false
    })
  },
})

export const { resetTransctionsState } = crewSlice.actions

export default crewSlice.reducer
