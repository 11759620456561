import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../../apis'
import { AxiosResponse } from 'axios'

interface CrewStatisticsApiParams {
  start_date: string
  end_date: string
  aoc: string
  hub: string
}

export interface CrewStatistics {
  '1': CrewStats
  '2': CrewStats
  '3': CrewStats
  '4': CrewStats
}

interface CrewStats {
  crew_count: number
  headcountPercent: number
  aoc: string
  hub: string
}

export const getCrewStatistics = createAsyncThunk(
  'crewList/getCrewStatistics',
  async (params: CrewStatisticsApiParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<CrewStatistics> = await HttpGet('/tier-details/crew-statistics', params)
      return response.data
    } catch (error: any) {
      console.error('Failed to get crew statistics: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
