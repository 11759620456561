import { TextField, Box, FormControl, Typography, FormGroup, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { months } from '../../../../constants/DATA'
import CommonSelect from '../../../../components/Select/CommonSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenSquare } from '@fortawesome/free-solid-svg-icons'
import { currencyFormatToCountry } from '../../../../utils/currencyHelper/formatToCountry'
import { AocTierState } from '../../../../redux/departmentRepresentative/aocSlice'
import { Close } from '@mui/icons-material'
import { patchAocTier } from '../../../../redux/departmentRepresentative/thunk/updateAOCTier'

const EditTextField = (props: { stateName: string, value?: number, placeholder: number, currency: string, tierLvl: string, onChangeText: (val: any, tierLvl: string) => void }) => {
  const { value = 0, placeholder, currency, tierLvl, onChangeText, stateName } = props

  return (
    <FormControl size="small" sx={{ mb: 3 }}>
      <Typography>
        {tierLvl}
        <Typography component="span" sx={{ color: '#686868', fontSize: 14 }}> ({currencyFormatToCountry(value, currency)})</Typography>
      </Typography>
      <TextField
        variant="filled"
        placeholder={currencyFormatToCountry(placeholder, currency)}
        value={value}
        inputProps={{ style: { backgroundColor: 'white', padding: 0, paddingTop: 5 } }}
        onChange={(evt) => onChangeText(evt.target.value, stateName)}
      />
    </FormControl>
  )
}

const EditModal = ({ details, data = [], isHistory = false }: { details: AocTierState, data?: Array<AocTierState>, isHistory?: boolean }) => {
  const dispatch = useDispatch()
  const { currency, aoc_type, month, year } = details
  const [toggleModal, setToggleModal] = useState<boolean>(false)

  const [newValue, setNewValue] = useState<any>({
    tier1: details?.tier_1 || 0,
    tier2: details?.tier_2 || 0,
    tier3: details?.tier_3 || 0,
    aoc: details?.aoc_type || '',
    year: isHistory ? year : new Date().getFullYear()
  })

  const onChangeText = (value: any, tierLvl: string) => {
    const clonedValues = { ...newValue }
    clonedValues[tierLvl] = value
    setNewValue(clonedValues)
  }

  const getCurrentNFutureMonth = months.filter(item => item.key >= (new Date().getMonth() + 1))
  const getCurrentMonth = months.filter(item => item.key === (new Date().getMonth() + 1))

  const [selectedMonth, setSelectedMonth] = useState<any>((year < new Date().getFullYear()) ? getCurrentMonth[0] : months[month - 1])

  const onChangeMonth = (value: number) => {
    const getMonth = months.filter(item => item.key === value)
    setSelectedMonth(getMonth[0])
  }

  const submitHandler = () => {
    setToggleModal(false)
    dispatch(patchAocTier({
      pathParam: `${aoc_type}-${year}-${month}`,
      isHistory: isHistory,
      queryParams: newValue,
      selectedMonth: selectedMonth.key,
    }))
  }

  return (
    <>
      <FontAwesomeIcon icon={faPenSquare} size="2x" onClick={() => setToggleModal(!toggleModal)} />

      <Dialog open={toggleModal} onClose={() => setToggleModal(false)}>
        <Box display="flex" justifyContent="space-between">
          <DialogTitle>Update Sales Tier</DialogTitle>
          <IconButton size="medium" onClick={() => setToggleModal(false)}>
            <Close style={{ color: 'black', width: 50 }} />
          </IconButton>
        </Box>
        <Divider sx={{ borderBottomWidth: 2, width: '95%', alignSelf: 'center' }} />
        <Box>
          <DialogContent>
            <Typography sx={{ color: '#7c7c7c', fontSize: 18 }}>
              <Typography sx={{ color: '#505050', fontSize: 13 }} component="span">AOC: </Typography>
              {aoc_type}
            </Typography>
            <CommonSelect
              sx={{ mb: 3, mt: 3 }}
              title={`Month (${month}/${year})`}
              data={getCurrentNFutureMonth}
              setSelectedData={(evt) => onChangeMonth(evt)}
              disabled={isHistory ? true : false}
              value={selectedMonth?.key}
            />
            {
              details &&
              <FormGroup>
                <EditTextField onChangeText={onChangeText} value={newValue.tier3} tierLvl="Tier III" stateName="tier3" placeholder={details.tier_3} currency={currency} />
                <EditTextField onChangeText={onChangeText} value={newValue.tier2} tierLvl="Tier II" stateName="tier2" placeholder={details.tier_2} currency={currency} />
                <EditTextField onChangeText={onChangeText} value={newValue.tier1} tierLvl="Tier I" stateName="tier1" placeholder={details.tier_1} currency={currency} />
              </FormGroup>
            }
          </DialogContent>
        </Box>
        <DialogActions sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', pb: 3 }}>
          <Button
            variant="outlined"
            onClick={submitHandler}
            size="small"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditModal
