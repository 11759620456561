import clsx from 'clsx'
import { ReactNode } from 'react'

type CardColors = 'white' | 'primary'

interface CardProps {
  children?: ReactNode
  color?: CardColors
  className?: string
  onClick?: () => void
  bgImageUrl?: string
  /**
   * Actually just tailwindcss classes for background-position
   * @link https://tailwindcss.com/docs/background-position
   */
  bgImagePosition?: string
}

const Card = (props: CardProps) => {
  const { children, color = 'white', className, onClick, bgImageUrl, bgImagePosition } = props

  const getColor = () => {
    switch (color) {
      case 'white':
        return 'text-inherit bg-white'
      case 'primary':
        return 'text-white bg-ccp-primary'
    }
  }

  return (
    <div
      onClick={onClick}
      className={clsx('p-4 rounded shadow', getColor(), className, bgImagePosition)}
      style={{ ...(bgImageUrl ? { backgroundImage: `url(${bgImageUrl})` } : {}) }}>
      {children}
    </div>
  )
}

export default Card
