import { toast as toastify } from 'react-toastify'

interface ToastOptionsProps {}

const Toast = {
  success: (message: string, opts: any = {}) => toastify.success(message),
  error: (message: string, opts: any = {}) => toastify.error(message),
  warn: (message: string, opts: any = {}) => toastify.warn(message),
  info: (message: string, opts: any = {}) => toastify.info(message),
  plain: (message: string, opts: any = {}) => toastify(message),
}

export default Toast
