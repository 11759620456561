import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider as Mui5ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'

import Main from './pages/Main'
import theme from './common/theme/theme'
import { store, persistor } from './redux/configureStore'
import './App.css'
import { initFirebase } from './config/firebaseInstance'

initFirebase()

/*
 * App:
 * the parent route path should have trailing "*"
 * This means if you navigate deeper, the parent is match therefore the child routes will render.
 */

const App: React.FunctionComponent = (): React.ReactElement => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Mui5ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<Main />} />
            </Routes>
          </BrowserRouter>
        </Mui5ThemeProvider>
      </PersistGate>
    </Provider>
  )
}
export default App
