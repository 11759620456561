import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../../apis'
import { AxiosResponse } from 'axios'

interface CrewDetailsResponse {
  data: CrewDetails[]
  total_page: number
  current_page: number
  page_size: number
}

export interface CrewDetails {
  crew_id: string
  aoc: string
  hub: string
  total_sales: number
  perishable: number
  non_perishable: number
  merchandise: number
  domlux: number
  duty_free: number
  position_short: string
  position_long: string
  name: string
  tier: number
  tier_info: Tierinfo
}

interface Tierinfo {
  tier_4: number
  tier_2: number
  tier_1: number
  tier_3: number
}

interface CrewDetailsApiParams {
  start_date: string
  end_date: string
  aoc: string
  hub: string
  tier?: number
  /**
   * If empty, it will return all crew
   */
  page?: number
  page_size?: number
}

export const getCrewDetails = createAsyncThunk(
  'crewList/getCrewDetails',
  async (params: CrewDetailsApiParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<CrewDetailsResponse> = await HttpGet('/tier-details/crew-details', params)
      return response.data
    } catch (error: any) {
      console.log('Failed to get crew details: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
