import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../../apis'

export interface aocTiersApiParams {
  year: number
  month?: number
  aoc?: string
}

// First, create the thunk
export const getAocList = createAsyncThunk('aoc/getAocList', async (params: aocTiersApiParams, { rejectWithValue }) => {
  try {
    const response = await HttpGet('/aoc/tiers', params)
    return response.data.result
  } catch (error: any) {
    console.log('aoc list err: ', error)

    if (error?.response?.data) {
      return rejectWithValue(error.response.data)
    }
  }
})
