import { createAsyncThunk } from '@reduxjs/toolkit';
import { HttpGet } from '../../apis';

interface FlightFilterParams {
    crew_id: string;
}

// First, create the thunk
export const getFlightFilterList = createAsyncThunk(
    'transactions/getFlightFilterList',
    async (params: FlightFilterParams, { rejectWithValue }) => {
        try {
            const response = await HttpGet('flightnumbers', params);
            return response.data;
        } catch (error: any) {
            console.log('flight summary err: ', error);

            if (error?.response?.data) {
                return rejectWithValue(error.response.data);
            }
        }
    },
);
