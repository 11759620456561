import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import styles from './Styles'
import { MainContainer } from '../../components/Container'
import { useLocation, useParams } from 'react-router-dom'
import { getAocListByAoc } from '../../redux/departmentRepresentative/thunk/getAocListByAoc'
import { RootState } from '../../redux/configureStore'
import { AocState } from '../../redux/departmentRepresentative/aocSlice'
import SaleTier from '../home/dept-representative/components/SaleTier'
import { UserState } from '../../redux/user'

const History: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch()
  const location = useLocation()
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const aocState = useSelector<RootState, AocState>(state => state.aoc)

  const { aoc: paramAOC } = useParams();

  const state: any = location.state

  useEffect(() => {

    let aoc: any = "";
    try {
      aoc = state.aocType;
    } catch (_) {
      aoc = paramAOC;
    }

    dispatch(getAocListByAoc({
      aoc: aoc,
      year: new Date().getFullYear(),
    }))
  }, [dispatch, state, paramAOC])

  return (
    <MainContainer sideMenu={false} title="AOC Tier List">
      <Box sx={{ mt: 5, backgroundColor: '#f8f8f8', p: 3, borderRadius: 3 }}>
        <Typography variant="h1" sx={styles.headerLbl}>
          List:
        </Typography>
        <SaleTier canEdit={userState.extraDetails.permissions.includes("edit_aoc_settings")} data={aocState.historyList} />
      </Box>

      {/* Note: to have a space at the bottom of the screen */}
      <div style={{ height: 20 }} />
    </MainContainer>
  )
}

export default History
