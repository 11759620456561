import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../../apis'
import { AxiosResponse } from 'axios'
import Toast from 'components/Toast'

interface CrewListResponse {
  data: CrewList[]
  total_page: number
  current_page: number
  page_size: number
}

export interface CrewList {
  crew_id: string
  full_name: string
  sales: number
  commission: number
  sales_tier: string
}

interface CrewListPayload {
  name?: string
  crew_id?: string
  sales_tier?: number
  page?: number
  page_size?: number
  aoc?: string
}

/**
 *  Map backend error to friendlier message. Add more as needed
 */
const ERROR_MAPPER: { [key: string]: string } = {
  SERVER_ERROR: 'Whoops, something went wrong',
}

export const getCrewList = createAsyncThunk('aoc/getCrewList', async (params: CrewListPayload, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<CrewListResponse> = await HttpGet('/crew/list', params)

    return response.data
  } catch (error: any) {
    console.log('Failed to get crew list:', error)

    if (error?.response?.data) {
      Toast.error(ERROR_MAPPER[error.response.data.message] ?? error.response.data.message)
      return rejectWithValue(error.response.data)
    }
  }
})
