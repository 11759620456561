import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../apis'
import { AxiosResponse } from 'axios'

export interface CrewSummaryData {
  crew_id: string
  name: string
  crew_hub: string
  sales_tier_level: number
  total_earnings: number
  total_sales: number
  total_target_sales: number
  currency: string
  tierInfo: TierInfo
}

interface TierInfo {
  tier_1: number
  tier_2: number
  tier_3: number
}

interface GetCrewSummaryPayload {
  crew_id: string
  /**
   * e.g. 1 is January, 2 is February, etc.
   */
  month?: string | number
  year?: string | number
}

export const getCrewSummary = createAsyncThunk(
  'crewList/getCrewSummary',
  async (params: GetCrewSummaryPayload, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<CrewSummaryData> = await HttpGet('/crew/dashboard/summary-view', {
        crew_id: params.crew_id,
        month: params.month,
        year: params.year,
      })
      return response.data
    } catch (error: any) {
      console.log('Failed to get crew summary: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
