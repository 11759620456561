import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Typography, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as firebase from 'firebase/auth'
import React, { ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { routeAuth } from '../../constants/routes'
import { setLoggedIn } from '../../redux/user'
import { Topbar } from './Topbar'
import { Footer } from './Footer'
import { MobileNavBar } from './MobileNavBar'
import { Sidebar } from './Sidebar'
import { useResponsiveCheck } from '../../utils/hooks'

export const MainContainer = ({
  children,
  backBtn = true,
  appBar = true,
  title = 'Crew Commission',
  sideMenu = true,
  contentHeaderBar = null,
  containerClass = '',
}: {
  children: any
  backBtn?: boolean
  appBar?: boolean
  title?: string
  sideMenu?: boolean
  contentHeaderBar?: ReactNode
  containerClass?: string
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isDesktop } = useResponsiveCheck()

  const logMeOut = async () => {
    dispatch(setLoggedIn(false))
    await firebase.getAuth().signOut()
    localStorage.clear()
    navigate(routeAuth.ROOT)
  }

  return (
    <div className="flex flex-col h-screen">
      {/* NOTE: Desktop view, top bar should not be within the scrolling container and fixed at the top */}
      {isDesktop && <Topbar onLogout={logMeOut} />}
      <div className="grow min-h-0 flex flex-row">
        {isDesktop && <Sidebar />}
        <div className="grow min-w-0 overflow-auto bg-slate-100 relative" id="main-container">
          {/* In mobile view, top bar must be within the scrolling container */}
          {!isDesktop && <Topbar onLogout={logMeOut} />}
          {contentHeaderBar}
          <div className={`max-w-[1280px] mx-auto px-4 pb-4 relative ${containerClass}`}>{children}</div>
        </div>
      </div>
      {isDesktop ? <Footer /> : <MobileNavBar />}
    </div>
  )
}
