import { Box, Modal, Typography } from '@mui/material'
import LoginBg from 'assets/images/login/login-bg.svg'
import * as firebase from 'firebase/auth'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setAuthLoading } from '../../redux/user'
import useStyles from './Styles'
import DesktopLoginBg from 'assets/images/login/bg-image-login-desktop.png'
import { useResponsiveCheck } from 'utils/hooks'

const provider = new firebase.GoogleAuthProvider()
provider.addScope('profile')
provider.setCustomParameters({
  hd: 'airasia.com',
})
provider.addScope('https://www.googleapis.com/auth/directory.readonly')
provider.addScope('https://www.googleapis.com/auth/contacts.readonly')

const Login = (props: any): React.ReactElement => {
  const dispatch = useDispatch()
  const { isDesktop } = useResponsiveCheck()
  const classes = useStyles()

  const [loading, setIsLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openedModal, setOpenedModal] = useState<boolean>(false)

  const GoogleLoginMe = async () => {
    setOpenModal(false)
    dispatch(setAuthLoading(true))
    try {
      const auth = firebase.getAuth()
      await firebase.signInWithPopup(auth, provider)
    } catch (error) {
      console.log('redirect error: ', error)
      setIsLoading(false)
    }
  }

  const initModal = useCallback(() => {
    const message = props.message
    if (message !== '' && message !== undefined) {
      if (!openedModal) {
        setOpenedModal(true)
        setOpenModal(true)
      }
    }
  }, [props, openedModal])

  useEffect(() => {
    initModal()
  }, [initModal])

  return (
    <div
      className={`bg-cover h-screen ${isDesktop ? 'flex items-center justify-center' : ''}`}
      style={{ backgroundImage: `url(${isDesktop ? DesktopLoginBg : LoginBg})` }}>
      {loading ? (
        <div className={classes.root}>
          <p>Loading...</p>
        </div>
      ) : (
        <div>
          {isDesktop ? (
            <>
              {/* Desktop login container */}
              <div className="relative flex flex-col items-center justify-center h-[300px] w-[600px] rounded-2xl bg-white/25 backdrop-blur-lg border-[3px] border-white/25">
                {/* airasia logo */}
                <img
                  src={require('../../assets/images/login/airasia-logo.png')}
                  alt="company-logo"
                  className="h-[120px] w-[120px] absolute left-1/2 translate-x-[-50%] top-0 translate-y-[-50%]"
                />

                <div className="text-xl text-white font-medium">Crew Commission Portal</div>
                <button className="flex mt-4 shadow-dark p-3 rounded bg-white" onClick={GoogleLoginMe}>
                  <img src={require('../../assets/images/login/google-icon.png')} alt="google" />
                  <span className="text-l ml-3">Sign in with Google</span>
                </button>
              </div>
            </>
          ) : (
            <>
              {/* airasia logo */}
              <div className="absolute left-1/2 top-1/3 translate-x-[-50%] translate-y-[-50%]">
                <img
                  src={require('../../assets/images/login/airasia-logo.png')}
                  alt="company-logo"
                  className="h-[120px] w-[120px]"
                />
              </div>

              {/* Mobile login container */}
              <div className="bg-white opacity-20 absolute bottom-0 h-[210px] w-screen rounded-tl-3xl rounded-tr-3xl" />

              <div className="bg-white absolute bottom-0 h-[188px] w-screen rounded-tl-3xl rounded-tr-3xl">
                <div className="flex justify-center items-center flex-col">
                  <img
                    src={require('../../assets/images/login/drawer-icon.png')}
                    alt="drawer"
                    className="h-1 w-8 mt-6"
                  />

                  <div className="text-l mt-4 font-bold">Welcome!</div>

                  <button
                    className="flex mt-4 shadow-dark p-3 h-12 w-[80vw] rounded text-center justify-center"
                    onClick={GoogleLoginMe}>
                    <img src={require('../../assets/images/login/google-icon.png')} alt="google" />
                    <div className="text-l ml-3">Sign in with Google</div>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <Modal
        open={openModal && !loading}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
          }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Login failed
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.message}
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}

export default Login
