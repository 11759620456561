import React, { useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'

interface dataSet {
  key: number
  value: string
  label: string
}

interface RegularSelectProps {
  title: string
  data: dataSet[]
  setSelectedData: (str: any) => void | undefined
  sx?: any;
  disabled?: boolean
  value?: any
}

const CommonSelect: React.FunctionComponent<RegularSelectProps> = (props) => {

  const { title, data, setSelectedData, sx = {}, disabled = false, value = '' } = props

  const [category, setCategory] = useState<string>(value)
  const handleCategory = (event: any) => {
    setCategory(event.target.value)
    setSelectedData(event.target.value)
  }

  return (
    <FormControl
      variant="standard"
      fullWidth
      sx={sx}
      disabled={disabled}
    >
      <InputLabel id="demo-simple-select-label">{title}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={category}
        onChange={handleCategory}
        label={title}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {data.map((each: any, index: any) => (
          <MenuItem key={each.key} value={each.key}>
            {each.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CommonSelect
