import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import user from './user'
import common from './common'
import transactions from './transactions'
import aocSlice from './departmentRepresentative/aocSlice'
import crewSlice from './departmentRepresentative/crewSlice'
import executive from './executive'
import metaFilterSlice from './departmentRepresentative/metafilters'

// NOTE:please update the key date when you update any Redux Slice / State
const persistConfig = {
  key: `root-${process.env.REACT_APP_PERSIST_KEY}`,
  version: 1,
  storage,
  whitelist: ['user'],
}

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user: user,
    transactions: transactions,
    common: common,
    aoc: aocSlice,
    crewList: crewSlice,
    executive: executive,
    metaFilters: metaFilterSlice
  }),
)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export let persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
