import { createSlice } from '@reduxjs/toolkit'
import { getCrewListDetails } from './thunk/getCrewListDetails'
import { getFlightFilterList } from './thunk/getFlightFilterList'
import { getSalesTier } from './thunk/getSalesTier'

export interface ExecutiveState {
  crewListIsLoading: boolean
  flightPairListIsLoading: boolean
  saleTiersIsLoading: boolean
  erorrMsg: string | null
  crewListDetails: Array<string>
  flightPairList: Array<string>
  saleTiersList: Array<string>
}

const initialState: ExecutiveState = {
  crewListIsLoading: false,
  flightPairListIsLoading: false,
  saleTiersIsLoading: false,
  erorrMsg: null,
  crewListDetails: [],
  flightPairList: [],
  saleTiersList: [],
}

export const executive = createSlice({
  name: 'executive',
  initialState,
  reducers: {
    setCrewListLoading: (state, action) => {
      state.crewListIsLoading = action.payload
    },
    resetExecutiveState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getCrewListDetails.pending, (state) => {
      state.crewListIsLoading = true
    })
    builder.addCase(getCrewListDetails.fulfilled, (state, action) => {
      state.crewListDetails = action.payload.data
      state.crewListIsLoading = false
      state.erorrMsg = null
    })
    builder.addCase(getCrewListDetails.rejected, (state) => {
      state.crewListIsLoading = false
      state.erorrMsg = 'There is an error, please try again in a moment'
    })
    builder.addCase(getFlightFilterList.pending, (state) => {
      state.flightPairListIsLoading = true
    })
    builder.addCase(getFlightFilterList.fulfilled, (state, action) => {
      state.flightPairList = action.payload.data
      state.flightPairListIsLoading = false
    })
    builder.addCase(getFlightFilterList.rejected, (state) => {
      state.flightPairListIsLoading = false
    })
    builder.addCase(getSalesTier.pending, (state) => {
      state.crewListIsLoading = true
    })
    builder.addCase(getSalesTier.fulfilled, (state, action) => {
      state.saleTiersList = action.payload.result
      state.crewListIsLoading = false
    })
    builder.addCase(getSalesTier.rejected, (state) => {
      state.crewListIsLoading = false
    })
  },
})

export const { setCrewListLoading, resetExecutiveState } = executive.actions

export default executive.reducer
