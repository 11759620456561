import { faChevronDown, faChevronUp, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Select from '@radix-ui/react-select'
import clsx from 'clsx'
import { useState } from 'react'

interface Option {
  label?: string
  value?: string
  subOptions?: Option[]
  isSeparator?: boolean
  disabled?: boolean
}

interface SelectComponentProps {
  placeholder?: string
  options: Option[]
  onChange?: (value: string) => void
  value?: string
  /**
   * Size of the trigger component. `lg` is usually for mobile and `md` is for desktop.
   * @default 'lg'
   */
  size?: 'md' | 'lg'
  className?: string
}

const SelectComponent = (props: SelectComponentProps) => {
  const { options = [], placeholder, value, onChange, size = 'lg', className = 'min-w-16' } = props
  const [internalValue, setInternalValue] = useState<string>()
  const currentValue = value ?? internalValue

  const handleValueChange = (value: string) => {
    if (onChange) onChange(value)
    else setInternalValue(value)
  }

  const clearValue = (evt: any) => {
    evt.stopPropagation()
    if (onChange) onChange('')
    else setInternalValue('')
  }

  const renderOption = (option: Option, index: number) => {
    if (option.isSeparator) {
      return <Select.Separator key={index} className="h-[1px] bg-violet6 m-[5px]" />
    }

    if (option.subOptions?.length) {
      return (
        <Select.Group key={index}>
          <Select.Label className="px-[25px] text-xs leading-[25px]">{option.label}</Select.Label>
          {option.subOptions.map(renderOption)}
        </Select.Group>
      )
    }

    return (
      <Select.Item
        key={index}
        value={option.value ?? ''}
        disabled={option.disabled}
        className={`leading-none rounded flex items-center h-8 px-4 select-none ${
          currentValue === option.value ? 'bg-ccp-primary text-white' : 'hover:bg-purple-100'
        }`}>
        <Select.ItemText>{option.label}</Select.ItemText>
      </Select.Item>
    )
  }

  return (
    <Select.Root onValueChange={handleValueChange} value={currentValue}>
      <div
        className={clsx(
          'group inline-flex justify-between border rounded leading-none gap-[5px] bg-white relative',
          {
            'h-11': size === 'lg',
            'h-8': size === 'md',
          },
          className,
        )}>
        <Select.Trigger
          className={clsx('grow inline-flex justify-between items-center text-sm data-[placeholder]:text-gray-400', {
            'px-4': size === 'lg',
            'px-2 py-1': size === 'md',
          })}>
          <Select.Value placeholder={placeholder} />
          {currentValue === '' ? (
            <Select.Icon>
              <FontAwesomeIcon icon={faChevronDown} />
            </Select.Icon>
          ) : null}
        </Select.Trigger>
        {currentValue !== '' ? (
          <div
            className={clsx(
              'h-6 w-6 self-center rounded-full hover:bg-ccp-hover cursor-pointer flex items-center justify-center',
              {
                'mr-3': size === 'lg',
                'mr-1': size === 'md',
              },
            )}
            onClick={clearValue}>
            <FontAwesomeIcon icon={faClose} />
          </div>
        ) : null}
      </div>
      <Select.Portal>
        <Select.Content className="overflow-hidden bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
          <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
            <FontAwesomeIcon icon={faChevronUp} />
          </Select.ScrollUpButton>
          <Select.Viewport className="p-[5px]">{options?.map(renderOption)}</Select.Viewport>
          <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
            <FontAwesomeIcon icon={faChevronDown} />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  )
}

export default SelectComponent
