import clsx from 'clsx'
import { isNil } from 'lodash'
import { useMemo } from 'react'

interface TierValue {
  value?: number
  tier_1?: number
  tier_2?: number
  tier_3?: number
  currency?: string
}

const TierProgress = (props: TierValue) => {
  const { value, tier_1, tier_2, tier_3, currency } = props

  const getPercentage = (min: number, max: number, current: number) => {
    if (isNil(min) || isNil(max) || isNil(current)) return 0
    if (current < min) return 0
    if (current > max) return 100

    const numerator = current - min
    const denominator = max - min
    return (numerator / denominator) * 100
  }

  const barData = useMemo(() => {
    if (value && tier_1 && tier_2 && tier_3) {
      return [
        getPercentage(0, tier_3 - 1, value), // tier 4
        getPercentage(tier_3, tier_2 - 1, value), // tier 3
        getPercentage(tier_2, tier_1 - 1, value), // tier 2
        getPercentage(tier_1, tier_1 * 1.333, value), // tier 1, 1.333 is just arbitrary number, as it goes infinitely
      ]
    }

    return [0, 0, 0, 0]
  }, [value])

  return (
    <>
      <div className="w-full grid grid-cols-4">
        <div>
          <div className={clsx('text-[10px]', barData[0] === 100 ? 'text-ccp-green80' : 'text-ccp-primary')}>
            Tier 4
          </div>
          <div className={clsx('text-xs', barData[0] === 100 ? 'text-ccp-green80' : 'text-current')}>-</div>
        </div>
        <div>
          <div className={clsx('text-[10px]', barData[1] === 100 ? 'text-ccp-green80' : 'text-ccp-primary')}>
            Tier 3
          </div>
          <div
            className={clsx(
              'text-xs',
              barData[1] === 100 ? 'text-ccp-green80' : 'text-current',
            )}>{`${currency} ${tier_3?.toFixed(2)}`}</div>
        </div>
        <div>
          <div className={clsx('text-[10px]', barData[2] === 100 ? 'text-ccp-green80' : 'text-ccp-primary')}>
            Tier 2
          </div>
          <div
            className={clsx(
              'text-xs',
              barData[2] === 100 ? 'text-ccp-green80' : 'text-current',
            )}>{`${currency} ${tier_2?.toFixed(2)}`}</div>
        </div>
        <div>
          <div className={clsx('text-[10px]', barData[3] === 100 ? 'text-ccp-green80' : 'text-ccp-primary')}>
            Tier 1
          </div>
          <div
            className={clsx(
              'text-xs',
              barData[3] === 100 ? 'text-ccp-green80' : 'text-current',
            )}>{`${currency} ${tier_1?.toFixed(2)}`}</div>
        </div>
        {barData.map((width, index) => (
          <div
            key={'bar-' + index}
            className={clsx('h-2 mt-4 bg-[#EDEDF6]', index !== 0 && 'border-l-2 border-l-[#9B9DD7]', {
              'rounded-l-full': index === 0,
              'rounded-r-full': index === 3,
            })}>
            <div
              style={{ width: `${width}%` }}
              className={clsx('h-2 relative', width === 100 ? 'bg-ccp-green80' : 'bg-ccp-primary', {
                'rounded-l-full': index === 0,
                'rounded-r-full': index === 3 && width === 100,
              })}>
              {/* arrow */}
              {((0 < width && width < 100) || (index === 3 && width === 100)) && (
                <div className="border-t-4 border-t-black border-x-4 border-x-white absolute -top-2 -right-1" />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default TierProgress
