import Card from ".";

interface TierCardDetails {

}

const TierBreakdownCard = (props: TierCardDetails) => {

    return (
        <>
            <Card>
                <div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </Card>
        </>
    )
}

export default TierBreakdownCard;