import { createAsyncThunk } from "@reduxjs/toolkit";
import { HttpGet } from "apis";
import { AxiosResponse } from "axios";
import { format, startOfMonth } from "date-fns";


export interface DownloadReportParams {
  download_type?: 'PDF' | 'CSV'
  crew_id?: string
  from_date?: string
  to_date?: string
  month?: number
  year?: number
  aoc?: string;
  tier?: number;
  hub?: string;
  flight_pairing_id?: string;
  for_targets?: boolean;
}

export interface DownloadReportResponse {
  file: any
  file_name: string;
}

const setCrewParams = ( params: DownloadReportParams)  => {
  const crewParams: any = {
    crew_id: params.crew_id!,
  }

  if ( params.download_type ) {
    crewParams.download_type = params.download_type
  }

  if ( params.from_date ) {
    crewParams.from_date = params.from_date 
  }

  if ( params.to_date ) {
    crewParams.to_date = params.to_date
  }

  if ( params.month ) {
    crewParams.month = params.month
  }

  if ( params.year ) {
    crewParams.year = params.year
  }

  return crewParams;
}

const setCrewListParams = ( params: DownloadReportParams ) => {
  const firstDayOfMonth = format(startOfMonth(new Date()), 'yyyy-MM-dd')
  const defaultFromDate = format(new Date(firstDayOfMonth.replace(/-/g, '/')), 'yyyy-MM-dd')
  const defaultToDate = format(new Date(), 'yyyy-MM-dd')

  const crewListParams: any = {}

  if ( params.from_date ) {
    crewListParams.start_date = params.from_date
  } else {
    crewListParams.start_date = defaultFromDate
  }

  if ( params.to_date ) {
    crewListParams.end_date = params.to_date
  } else {
    crewListParams.end_date = defaultToDate
  }

  if ( params.aoc ) {
    crewListParams.aoc = params.aoc
  }

  if ( params.tier ) {
    crewListParams.tier = params.tier
  }

  if ( params.hub ) {
    crewListParams.hub = params.hub
  }

  return crewListParams;
}

const setRouteTargetParams = (params: DownloadReportParams) => {
  const today = new Date();

  const routeTargetParams: any = {};

  if ( params.aoc ) {
    routeTargetParams.aoc = params.aoc
  }

  if ( params.year ) {
    routeTargetParams.year = params.year
  } else {
    routeTargetParams.year = today.getFullYear()
  }

  if ( params.month ) {
    routeTargetParams.month = params.month
  } else {
    routeTargetParams.month = today.getMonth() + 1
  }

  return routeTargetParams
}

// First, create the thunk
export const getCrewReports = createAsyncThunk(
  'transactions/getReports',
  async (params: DownloadReportParams, { rejectWithValue }): Promise<{ 
    blob: Blob, 
    fileName: string
  } | any> => {
    try {
      const forTargets = params?.for_targets ?? false;

      // download route targets
      if (forTargets) {
        const routeTargetParams = setRouteTargetParams(params);

        const response: AxiosResponse<DownloadReportResponse> = await HttpGet(`tier-targets/routes/download`, routeTargetParams);
        
        const fileName = response.data.file_name;

        return {
          blob: new Blob([response.data.file]),
          fileName: fileName
        };
      }
      
      // to download for specific crew
      if (params.crew_id && params.crew_id !== '' ) {
        const isPDF = params.download_type === 'PDF';

        // single crew - flight details - transaction - breakdown
        if ( params.flight_pairing_id && params.flight_pairing_id !== '' && !isPDF ) {

          const crewFlightDetailsParams = {
            crew_id: params.crew_id,
            flight_pairing_id: params.flight_pairing_id
          }

          const response: AxiosResponse<DownloadReportResponse> = await HttpGet(`crew/reports/${params.crew_id}/flight-details`, crewFlightDetailsParams);
        
          const fileName = response.data.file_name;

          return {
            blob: new Blob([response.data.file]),
            fileName: fileName
          };
        }

        // single crew - flight pairing csv / transaction breakdown pdf
        const crewParams = setCrewParams(params)

        const response: AxiosResponse<DownloadReportResponse> = await HttpGet(`crew/reports/${crewParams.crew_id}`, crewParams);

        console.log(response.data);

        const fileName = response.data.file_name;

        return {
          blob: isPDF 
            ? new Blob([new Uint8Array(response.data.file.data).buffer], { type: 'application/pdf' }) 
            : new Blob([response.data.file]),
          fileName: fileName
        };
      // do download for all crew list
      } else {
        // download crew list reports
        const crewListParams = setCrewListParams(params);

        const response: AxiosResponse<DownloadReportResponse> = await HttpGet(`crew/reports/crews`, crewListParams);
        
        const fileName = response.data.file_name;

        return {
          blob: new Blob([response.data.file]),
          fileName: fileName
        };
      }
      
    } catch (error: any) {
      console.log('download reports: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)

