import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OnlinePaymentImg from 'assets/images/card-bg/online-payment.svg'
import clsx from 'clsx'
import Header from 'components/Header'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'redux/configureStore'
import { AocState } from 'redux/departmentRepresentative/aocSlice'
import { CrewListState } from 'redux/departmentRepresentative/crewSlice'
import { MetaFilterState } from 'redux/departmentRepresentative/metafilters'
import { getCrewSummary } from 'redux/thunk/getCrewSummary'
import { UserState } from 'redux/user'
import { useResponsiveCheck } from 'utils/hooks'
import Card from '.'
import TierProgress from './TierProgress'

interface TierCardProps {
  onCardClick: () => void
  crewID?: string
}

const SummaryCard = (props: TierCardProps) => {
  const dispatch = useDispatch()
  const { isDesktop } = useResponsiveCheck()
  const { crewId: crewIdParam } = useParams()
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const crewListState = useSelector<RootState, CrewListState>((state) => state.crewList)
  const aocState = useSelector<RootState, AocState>((state) => state.aoc)
  const summary = crewListState.currentCrewInfo
  const isLoading = crewListState.crewSummaryIsLoading

  const crew_id = props.crewID ?? crewIdParam ?? userState.extraDetails.crew_id
  const currency = aocState.aocCurrency || userState.aocDetails.currency
  const colorCfg: { [key: number]: string } = {
    1: 'bg-ccp-green80 text-white',
    2: 'bg-ccp-primary text-white ',
    3: 'bg-ccp-primary text-white',
    4: 'bg-ccp-primary text-white',
  }

  useEffect(() => {
    dispatch(
      getCrewSummary({
        crew_id,
        month: metaFilterState.currentMonth,
        year: metaFilterState.currentYear,
      }),
    )
  }, [metaFilterState.currentMonth, metaFilterState.currentYear, crew_id])

  return (
    <>
      <Card
        color="primary"
        className={clsx('grow mb-4 xl:flex xl:flex-row-reverse')}
        bgImageUrl={OnlinePaymentImg}
        bgImagePosition="bg-no-repeat bg-right">
        <div className="xl:min-w-[30%] flex flex-row">
          {isLoading ? (
            <div className="h-5 w-5 skeleton-lighter rounded-full" />
          ) : (
            <div className="h-5 w-5 rounded-full bg-white flex items-center justify-center mr-2">
              <FontAwesomeIcon icon={faLocationDot} className="text-ccp-primary" />
            </div>
          )}
          {isLoading ? <span className="w-6 skeleton-lighter ml-2" /> : summary?.crew_hub}
        </div>

        <div className="xl:grow flex flex-col">
          {isLoading ? (
            <div className="my-2 h-5 w-[150px] skeleton-lighter" />
          ) : (
            <div className="font-medium text-xl">{summary?.name}</div>
          )}
          {isLoading ? (
            <div className="h-3 w-14 skeleton-lighter" />
          ) : (
            <div className="text-sm">{summary?.crew_id}</div>
          )}
        </div>
      </Card>

      {isDesktop ? (
        <div className="pb-2 gap-2 grid grid-cols-4">
          <Card className="col-span-1">
            <div>Total sales</div>
            <Header.H3>
              {currency} {summary?.total_sales?.toFixed(2)}
            </Header.H3>
          </Card>

          <Card className="col-span-1">
            <div>Total commission</div>
            <Header.H3>
              {currency} {summary?.total_earnings?.toFixed(2)}
            </Header.H3>
          </Card>

          <Card
            className={`col-span-2 flex flex-row items-center cursor-pointer hover:bg-ccp-hover`}
            onClick={() => props.onCardClick()}>
            <div
              className={`${
                colorCfg[summary?.sales_tier_level ?? 0]
              } font-medium rounded-full h-10 w-10 flex shrink-0 items-center justify-center mr-4`}>
              {summary?.sales_tier_level ?? 0}
            </div>

            <TierProgress
              currency={currency}
              value={summary?.total_sales}
              tier_1={summary?.tierInfo?.tier_1}
              tier_2={summary?.tierInfo?.tier_2}
              tier_3={summary?.tierInfo?.tier_3}
            />
          </Card>
        </div>
      ) : (
        <Card className="pb-6 mb-2" onClick={() => props.onCardClick()}>
          <div className="flex font-medium">
            <div className="flex-auto text-dark-300 text-center flex flex-col items-center">
              <div className={`text-xs ${isLoading ? 'skeleton w-5' : ''}`}>{!isLoading && 'Total sales'}</div>
              <div className={`text-base mt-2 ${isLoading ? 'skeleton w-16' : ''}`}>
                {!isLoading && `${currency} ${summary?.total_sales?.toFixed(2)}`}
              </div>
            </div>

            <div className="border-l-2" />

            <div className="flex-auto text-dark-300 text-center flex flex-col items-center">
              <div className={`text-xs ${isLoading ? 'skeleton w-5' : ''}`}>{!isLoading && 'Total commission'}</div>
              <div className={`text-base mt-2 ${isLoading ? 'skeleton w-16' : ''}`}>
                {!isLoading && `${currency} ${summary?.total_earnings?.toFixed(2)}`}
              </div>
            </div>

            <div className="border-l-2" />

            <div className="flex-auto pl-4 text-dark-300 text-center flex flex-col items-center">
              <div className={`text-xs ${isLoading ? 'skeleton w-8' : ''}`}>{!isLoading && 'Tier'}</div>
              <div className={`text-base mt-2 ${isLoading ? 'skeleton w-4' : ''}`}>
                {!isLoading && (summary?.sales_tier_level ?? 0)}
              </div>
            </div>
          </div>

          <div className="border-b my-4" />

          <TierProgress
            currency={currency}
            value={summary?.total_sales}
            tier_1={summary?.tierInfo?.tier_1}
            tier_2={summary?.tierInfo?.tier_2}
            tier_3={summary?.tierInfo?.tier_3}
          />
        </Card>
      )}
    </>
  )
}

export default SummaryCard
