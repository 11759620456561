import Card from 'components/Card'
import Pagination from 'components/Pagination'
import { ReactNode, useState } from 'react'

const ITEMS_PER_PAGE = 5

interface FlightPairingSalesListProps {
  listData?: any[]
  currency?: string
}

const FlightPairingSalesList = (props: FlightPairingSalesListProps) => {
  const { listData, currency } = props

  const [currentPage, setCurrentPage] = useState<number>(1)
  const getItemsForPage =
    (page: number) =>
    (dataArray: any[]): any[] =>
      dataArray.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)

  const ValuePair = ({
    title,
    children,
    isHeader = false,
  }: {
    title: string
    children: ReactNode
    isHeader?: boolean
  }) => (
    <div key={`key-${title}`} className={`mt-4 ${isHeader ? 'border-b' : ''}`}>
      <div className="text-sm mt-2">{title}</div>
      <div>{children}</div>
    </div>
  )

  return (
    <>
      {listData?.length ? (
        getItemsForPage(currentPage)(listData).map((data, idx) => (
          <Card key={idx} className="grid grid-cols-2 grid-flow-row mt-2">
            <ValuePair title={'Flight sold'} isHeader>
              {data.flight_number}
            </ValuePair>
            <ValuePair title={'Quantity'} isHeader>
              {data.product_quantity}
            </ValuePair>
            <ValuePair title={'Product'}>{data.product_name}</ValuePair>
            <ValuePair title={'Status'}>{data.status}</ValuePair>
            <ValuePair title={'Sales'}>
              {currency} {data.sales.toFixed(2)}
            </ValuePair>
            <ValuePair title={'Commission'}>
              {currency} {data.commission.toFixed(2)}
            </ValuePair>
          </Card>
        ))
      ) : (
        <div className="mt-4 text-center italic">No data available</div>
      )}
      <Pagination
        currentPage={currentPage}
        onChange={setCurrentPage}
        dataLength={listData?.length ?? 0}
        itemPerPage={ITEMS_PER_PAGE}
        className="mt-2"
      />
    </>
  )
}

export default FlightPairingSalesList
