import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../apis'

interface FlightSummaryParams {
  crew_id?: string
  flight_pair: string
  from_date?: Date
  to_date?: Date
  product_category?: string
}

// First, create the thunk
export const getFlightSummaryDetails = createAsyncThunk(
  'transactions/getFlightSummaryDetails',
  async (params: FlightSummaryParams, { rejectWithValue }) => {
    try {
      const response = await HttpGet('crew/transactions', params)
      return {
        flightPairNumber: params.flight_pair,
        transactions: response.data.data,
      }
    } catch (error: any) {
      console.log('flight summary details err: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
