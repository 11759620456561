import { createSlice } from '@reduxjs/toolkit'
import format from 'date-fns/format'
import { getUserAndAocDetails } from 'redux/thunk/getUserAndAocDetails'

const DATE_FORMAT = 'yyyy-MM-dd'

export interface MetaFilterState {
  dateFrom: string
  dateTo: string
  currentMonth: string
  currentYear: string
  aoc: string
  hub: string
}

const generateDefaultDate = () => {
  const todaysDate = new Date()
  const dateFrom = format(new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1), DATE_FORMAT)
  const dateTo = format(todaysDate, DATE_FORMAT)
  const currentMonth = `${todaysDate.getMonth() + 1}`
  const currentYear = todaysDate.getFullYear().toString()

  return {
    dateTo,
    dateFrom,
    currentMonth,
    currentYear,
  }
}

const initialState: MetaFilterState = {
  ...generateDefaultDate(),
  aoc: '',
  hub: '',
}

export const metaFilterSlice = createSlice({
  name: 'metaFilters',
  initialState,
  reducers: {
    setDateRange: (state, action) => {
      let data: { dateFrom: string; dateTo: string } = action.payload

      state.dateFrom = data.dateFrom
      state.dateTo = data.dateTo
      state.currentMonth = format(new Date(data.dateFrom), 'MM')
      state.currentYear = format(new Date(data.dateFrom), 'yyyy')
    },
    setHub: (state, action) => {
      state.hub = action.payload
    },
    setAoc: (state, action) => {
      state.aoc = action.payload
    },
    setUserDefault: (state, action) => {
      state.aoc = action.payload.aoc
      state.hub = action.payload.hub
    },
    resetMetaFilters: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAndAocDetails.fulfilled, (state, action) => {
      state.aoc = action.payload?.extraDetails?.aoc ?? ''
      state.hub = action.payload?.extraDetails?.hub ?? ''
    })
    builder.addCase(getUserAndAocDetails.rejected, (state) => {
      console.error('Error setting default aoc and hub')
    })
  },
})

export const { setDateRange, setHub, setAoc, resetMetaFilters } = metaFilterSlice.actions

export default metaFilterSlice.reducer
