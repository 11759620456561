export const routeAuth = {
  ROOT: '/',
  LOGIN: '/login',
}

export const route = {
  HOME: '/',
  FLIGHT_DETAILS: '/details/:pairingId',
  TRANSACTIONS: '/transactions',
  TIERS: '/tiers',
  TIER_DETAILS: '/tier-details',
  TIER_BREAKDOWN: '/tier-breakdown',
  CREWS: '/crews',
  CREWS_AOC: '/:aoc/crews',
  CREWS_TRANSACTIONS: '/crews/:crewId/transactions',
  CREWS_SUB_TRANSACTIONS: '/crews/:crewId/details/:pairingId',
  CREWS_TIERS: '/crews/:crewId/tiers',
  AOC_HISTORY: '/:aoc/history',
  DOWNLOADING: '/downloading',
  ROUTE_SPEC_TARGET: '/route-targets',
}
