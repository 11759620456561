import clsx from 'clsx'
import FlightPairingCard from 'components/Card/FlightPairingCard'
import Header from 'components/Header'
import Pagination from 'components/Pagination'
import SharedTable from 'components/Table/SharedTable'
import Input from 'components/TextField/Input'
import { route } from 'constants/routes'
import { format } from 'date-fns'
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { RootState } from 'redux/configureStore'
import { AocState } from 'redux/departmentRepresentative/aocSlice'
import { MetaFilterState } from 'redux/departmentRepresentative/metafilters'
import { FlightPairingTransaction, getFlightPairingsList } from 'redux/thunk/getFlightPairingList'
import { TransactionState, setDownloadFilter } from 'redux/transactions'
import { UserState } from 'redux/user'
import { useResponsiveCheck } from 'utils/hooks'
import { mapToFlightPairingCardData } from '../utils'

const FlightPairing = () => {
  const { notFromUserView } = useOutletContext<any>()
  const { crewId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userState = useSelector<RootState, UserState>((state) => state.user)
  const transactionState = useSelector<RootState, TransactionState>((state) => state.transactions)
  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const { isDesktop } = useResponsiveCheck()
  const isLoading = transactionState.flightPairingsListIsLoading
  const crew_id = crewId ?? userState.extraDetails.crew_id
  const data = transactionState.flightPairings

  const [searchValue, setSearchValue] = useState<string>('')
  const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 5 })
  const totalPage = transactionState?.flightPairingsPagination?.total_page ?? -1

  useEffect(() => {
    dispatch(
      getFlightPairingsList({
        crew_id,
        year: Number(metaFilterState.currentYear),
        month: Number(metaFilterState.currentMonth),
        flight_pairing: searchValue,
        page: pagination.pageIndex + 1,
        page_size: pagination.pageSize,
      }),
    )

    dispatch(
      setDownloadFilter({
        download_type: 'CSV',
        crew_id,
        year: Number(metaFilterState.currentYear),
        month: Number(metaFilterState.currentMonth),
      }),
    )
  }, [
    metaFilterState.currentMonth,
    metaFilterState.currentYear,
    searchValue,
    pagination.pageIndex,
    pagination.pageSize,
  ])

  const viewSubTransaction = (pairingId: string) => {
    // TODO: implement proper encryption
    // const encryptedId = idSpoofer.encrypt(pairingId)
    if (notFromUserView) {
      navigate(generatePath(route.CREWS_SUB_TRANSACTIONS, { crewId, pairingId: pairingId }))
    } else {
      navigate(generatePath(route.FLIGHT_DETAILS, { pairingId: pairingId }))
    }
  }

  return (
    <div>
      <div className={clsx('flex mb-2', isDesktop ? 'flex-row items-center' : 'flex-col')}>
        <Header.Subtitle1 className={isDesktop ? 'mr-4' : 'mb-2'}>Search by</Header.Subtitle1>
        <div className="grow relative flex flex-row items-center justify-between">
          <Input
            placeholder="Flight pairing"
            sizing={isDesktop ? 'md' : 'lg'}
            onChange={setSearchValue}
            value={searchValue}
            showClearButton
            wrapperClassName={isDesktop ? '' : 'w-full'}
          />

          {isDesktop ? (
            <div>
              <span className="text-xs font-medium font-dmSans">Date selected</span>
              <span className="text-xs font-bold font-dmSans ml-2">
                {format(new Date(metaFilterState.dateFrom), 'dd')} -{' '}
                {format(new Date(metaFilterState.dateTo), 'dd MMM, yyyy')}
              </span>
            </div>
          ) : null}
        </div>
      </div>

      {isDesktop ? (
        <TableView
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          setPagination={setPagination}
          totalPage={totalPage}
          viewSubTransaction={viewSubTransaction}
        />
      ) : (
        <CardView
          data={data}
          isLoading={isLoading}
          pagination={pagination}
          setPagination={setPagination}
          totalPage={totalPage}
          viewSubTransaction={viewSubTransaction}
        />
      )}
    </div>
  )
}

type SubViewProps = {
  data: FlightPairingTransaction[]
  isLoading: boolean
  pagination: MRT_PaginationState
  setPagination: (pagination: MRT_PaginationState) => void
  totalPage: number
  viewSubTransaction: (pairingId: string) => void
}

const CardView = ({ data, isLoading, pagination, setPagination, totalPage, viewSubTransaction }: SubViewProps) => {
  const aocState = useSelector<RootState, AocState>((state) => state.aoc)

  return (
    <>
      {isLoading && <FlightPairingCard isLoading className="mt-2" />}

      {!isLoading &&
        (data.length ? (
          <>
            {data.map((pairing, idx) => (
              <FlightPairingCard
                key={`fpc-${idx}`}
                data={mapToFlightPairingCardData(pairing, aocState.aocCurrency)}
                onClick={() => viewSubTransaction(pairing.id)}
                className="mt-2"
              />
            ))}
            <Pagination
              currentPage={pagination.pageIndex}
              onChange={(number) => {
                setPagination({ pageIndex: number, pageSize: pagination.pageSize })
              }}
              totalPage={totalPage}
              className={'mt-4'}
            />
          </>
        ) : (
          <div className="mt-4 text-center italic">No data available</div>
        ))}
    </>
  )
}

const TableView = ({ data, isLoading, pagination, setPagination, totalPage, viewSubTransaction }: SubViewProps) => {
  const formatCurrency = (currency: string, value: number) => `${currency} ${value.toFixed(2)}`
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const aocState = useSelector<RootState, AocState>((state) => state.aoc)
  const { notFromUserView } = useOutletContext<any>()
  const fallbackCurrency = notFromUserView ? aocState.aocCurrency : userState.extraDetails?.currency

  const columns = useMemo<MRT_ColumnDef<FlightPairingTransaction, any>[]>(
    () => [
      {
        accessorKey: 'flight_number_pairing',
        header: 'Flight pairing',
      },
      {
        accessorKey: 'date_string',
        header: 'Date',
        Cell: ({ cell }) => format(new Date(cell.getValue()), 'd MMM'),
        size: 80,
      },
      {
        accessorKey: 'total_sales',
        header: 'Santan',
        Cell: ({ cell, row }) =>
          formatCurrency(
            row.original.currency ?? fallbackCurrency,
            row.original.total_sales.perishable + row.original.total_sales.non_perishable,
          ),
        size: 100,
      },
      {
        accessorKey: 'total_sales.domlux',
        header: 'Domlux',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
      },
      {
        accessorKey: 'total_sales.mcds',
        header: 'MCDS',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
      },
      {
        accessorKey: 'total_sales.dtf',
        header: 'DTF',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
      },
      {
        accessorKey: 'total_commission',
        header: 'Santan',
        Cell: ({ cell, row }) =>
          formatCurrency(
            row.original.currency ?? fallbackCurrency,
            row.original.total_commission.perishable + row.original.total_commission.non_perishable,
          ),
        size: 100,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: '#4B4FA6',
            color: 'white',
            borderTopLeftRadius: '16px',
          },
        }),
      },
      {
        accessorKey: 'total_commission.domlux',
        header: 'Domlux',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: '#4B4FA6',
            color: 'white',
          },
        }),
      },
      {
        accessorKey: 'total_commission.mcds',
        header: 'MCDS',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: '#4B4FA6',
            color: 'white',
          },
        }),
      },
      {
        accessorKey: 'total_commission.dtf',
        header: 'DTF',
        Cell: ({ cell, row }) => formatCurrency(row.original.currency ?? fallbackCurrency, Number(cell.getValue())),
        size: 100,
        muiTableHeadCellProps: () => ({
          sx: {
            backgroundColor: '#4B4FA6',
            color: 'white',
          },
        }),
      },
    ],
    [],
  )

  return (
    <SharedTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      pagination={pagination}
      setPagination={setPagination}
      pageCount={totalPage}
      showActionColumn
      onActionClick={(rowData) => viewSubTransaction(rowData.id)}
      alternateActionHeaderColumnColor
    />
  )
}

export default FlightPairing
