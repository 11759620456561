import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme?: any) => ({
    root: {
        height: '100vh',
    },
    mainGrid: {
        height: '100%',
        spacing: 0,
        justify: 'space-around',
    },
    firstContainer: {
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    appNameLbl: {
        paddingTop: 20,
        paddingBottom: 48,
        fontFamily: 'Tangerine',
    },
    tncTextStyle: {
        fontSize: 12,
    },
    footer: {
        height: '100%',
        justifyContent: 'flex-end',
        padding: 12,
    },
}));
export default useStyles;
