import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { route } from 'constants/routes'

interface NavItems {
  label: string
  faIcon?: IconDefinition
  icon?: string // Ideally SVG images
  url?: string
  onClick?: () => void
}

export const navItems: NavItems[] = [
  {
    label: 'Overview',
    icon: 'Overview',
    url: route.HOME,
  },
  {
    label: 'Tier Rating',
    icon: 'TierRating',
    url: route.TIERS,
  },
]

export const execNavItems: NavItems[] = [
  {
    label: 'Overview',
    icon: 'Overview',
    url: route.HOME,
  },
  {
    label: 'Crew List',
    icon: 'CabinCrew',
    url: route.CREWS,
  },
]

export const adminNavItems: NavItems[] = [
  {
    label: 'Overview',
    icon: 'Overview',
    url: route.HOME,
  },
  {
    label: 'Tier details',
    icon: 'NumThree',
    url: route.TIER_DETAILS,
  },
  {
    label: 'Route spec. target',
    icon: 'PlaneDepart',
    url: route.ROUTE_SPEC_TARGET,
  },
  {
    label: 'Crew List',
    icon: 'CabinCrew',
    url: route.CREWS,
  },
]
