import { CircularProgress } from '@mui/material'
import Select from 'components/Select/Select'
import { SvgIcon } from 'components/SvgIcon'
import SharedTable from 'components/Table/SharedTable'
import Input from 'components/TextField/Input'
import { debounce } from 'lodash'
import { useDownloadReportHelper } from 'pages/home/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'redux/configureStore'
import { CrewListState } from 'redux/departmentRepresentative/crewSlice'
import { MetaFilterState } from 'redux/departmentRepresentative/metafilters'
import { getCrewList } from 'redux/departmentRepresentative/thunk/getCrewList'
import { setDownloadFilter } from 'redux/transactions'
import { useResponsiveCheck } from 'utils/hooks'
import { MainContainer } from '../../components/Container'
import { route } from '../../constants/routes'
import HeaderToolbar from 'pages/home/components/HeaderToolbar'
import AocTabs from 'pages/components/AocTabs'
import { UserState } from 'redux/user'
import { adminRoles, roles } from 'constants/roles'

const CrewList: React.FC = (): React.ReactElement => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    list: crewListData,
    listIsLoading,
    listPagination,
  } = useSelector<RootState, CrewListState>((state) => state.crewList)
  const metaFiltersState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const { isDesktop } = useResponsiveCheck()

  const [crewName, setCrewName] = useState('')
  const [crewID, setCrewID] = useState<string>('')
  const [salesTier, setSalesTier] = useState<string | undefined>('')
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 20 })

  const userState = useSelector<RootState, UserState>((state) => state.user);
  const isAdmin = adminRoles.includes(userState.extraDetails.role)

  const { downloadingReport, downloadCrewReports } = useDownloadReportHelper()

  const goToTransactions = (row: any) => {
    navigate(route.CREWS_TRANSACTIONS.replace(':crewId', row.crew_id.toString()), {
      state: {
        crew_id: row.crew_id,
        crew_name: row.full_name,
      },
    })
  }

  const fetchCrewListData = (_crewName?: string) => {

    // if admin use aoc tabs, if not use user aoc
    const aoc = isAdmin ? metaFiltersState.aoc : userState.aocDetails.aoc_type;

    dispatch(
      getCrewList({
        name: _crewName ?? crewName,
        crew_id: crewID,
        sales_tier: Number(salesTier) || undefined,
        page: pagination.pageIndex + 1,
        page_size: pagination.pageSize,
        aoc
      }),
    )

    dispatch(
      setDownloadFilter({
        tier: Number(salesTier) || undefined,
      }),
    )
  }

  const debouncedFetchCrewListData = useCallback(debounce(fetchCrewListData, 500), [
    crewID,
    salesTier,
    pagination.pageIndex,
    pagination.pageSize,
    metaFiltersState.aoc
  ])

  useEffect(() => {
    // Avoid spamming the api as the user types
    fetchCrewListData()
  }, [crewID, salesTier, pagination.pageIndex, pagination.pageSize, metaFiltersState.aoc])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'full_name',
        header: 'Crew name and ID',
        Cell: ({ cell, row }: { cell: any; row: any }) => (
          <>
            <div className="text-base font-medium font-dmSans">{cell.getValue()}</div>
            <div className="text-sm font-dmSans text-ccp-grey90">{row.original.crew_id}</div>
          </>
        ),
        muiTableHeadCellProps: {
          width: '100%',
        },
        muiTableBodyCellProps: {
          width: '100%',
        },
      },
      {
        accessorKey: 'sales',
        header: 'Sales',
        Cell: ({ cell }: { cell: any }) => (
          <div className="bg-ccp-purple100 rounded p-2 text-ccp-purple80 font-dmSans font-medium text-sm">
            {cell.getValue().toFixed(2)}
          </div>
        ),
        size: 40,
        minSize: 20,
        maxSize: 100,
      },
      {
        accessorKey: 'commission',
        header: 'Commission',
        Cell: ({ cell }: { cell: any }) => (
          <div className="bg-ccp-green10 rounded p-2 text-ccp-green80 font-dmSans font-medium text-sm">
            {cell.getValue().toFixed(2)}
          </div>
        ),
        size: 40,
        minSize: 20,
        maxSize: 100,
      },
      {
        accessorKey: 'sales_tier',
        header: 'Sales tier',
        Cell: ({ cell }: { cell: any }) => (
          <div className="bg-ccp-purple90 rounded-full mx-auto flex items-center justify-center text-white w-8 h-8 text-sm">
            {cell.getValue()}
          </div>
        ),
        muiTableHeadCellProps: {
          align: 'center' as 'center',
        },
      },
    ],
    [],
  )

  return (
    <MainContainer
      contentHeaderBar={
        isDesktop ? (
          <div className="bg-white shadow sticky top-0 left-0 w-full z-10 mb-2">
            <div className="h-14 w-full max-w-[1280px] px-4 mx-auto flex flex-row">
              <div className="grow flex flex-row">
                {isAdmin && (<AocTabs />)}
              </div>
              <HeaderToolbar className="shrink-0" isDesktop={isDesktop} />
            </div>
          </div>
        ) : null
      }>
      {!isDesktop && (
        <div className='border-b'>
          <AocTabs />
        </div>
      )}
      {!isDesktop && <HeaderToolbar className="mb-4 border-b-2 justify-end h-14" isDesktop={isDesktop} />}
      <div className="p-6">
        <div className="text-base font-medium">Crew list</div>
        <div className="text-sm mt-2 mb-2">List of crew and their sales & commissions</div>

        {/* Filter */}
        <div className="flex gap-2 mb-2">
          <Input
            className="rounded text-sm w-32"
            value={crewName}
            onChange={(newVal) => {
              setCrewName(newVal)
              debouncedFetchCrewListData(newVal)
            }}
            placeholder="Crew name"
            sizing={isDesktop ? 'md' : 'lg'}
            showClearButton
          />

          <Input
            className="rounded text-sm w-32"
            type="number"
            onChange={setCrewID}
            placeholder="ID"
            sizing={isDesktop ? 'md' : 'lg'}
          />

          <Select
            placeholder="Sales Tier"
            options={[
              { label: '1', value: '1' },
              { label: '2', value: '2' },
              { label: '3', value: '3' },
              { label: '4', value: '4' },
            ]}
            value={salesTier}
            onChange={(value) => {
              setSalesTier(value)
            }}
            size={isDesktop ? 'md' : 'lg'}
            className="w-32"
          />
          <div className="w-[1px] h-8 bg-ccp-grey40 ml-2"></div>
          <button
            disabled={downloadingReport}
            onClick={downloadCrewReports}
            className="relative border-0 rounded-full h-8 w-8 hover:bg-ccp-ripple">
            {downloadingReport ? <CircularProgress size={18} /> : <SvgIcon icon="DownloadReports" size={24} />}
          </button>
        </div>

        <SharedTable
          columns={columns}
          data={crewListData}
          isLoading={listIsLoading}
          // isSaving={crewListData?.length > 0 && listIsLoading}
          // isSaving={true}
          pagination={pagination}
          setPagination={setPagination}
          pageCount={listPagination.total_page}
          showActionColumn
          onActionClick={goToTransactions}
        />
      </div>
    </MainContainer>
  )
}

export default CrewList
