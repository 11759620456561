import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpPatch, HttpPost } from '../../../apis'
import { RootState } from '../../configureStore'

export interface aocTiersUpdateApiParams {
  pathParam: string
  queryParams: {
    aoc?: string
    tier1: number
    tier2: number
    tier3: number,
    year: number,
  }
  selectedMonth?: number
  isHistory?: boolean
}

// First, create the thunk
export const patchAocTier = createAsyncThunk(
  'aoc/updateAoc',
  async (params: aocTiersUpdateApiParams, { rejectWithValue, getState }) => {
    try {
      const { aoc } = getState() as RootState
      const { pathParam, queryParams, isHistory = false, selectedMonth } = params

      let aocList = aoc.result

      if (isHistory) {
        aocList = aoc.historyList
      }

      const clonedList: any = [...aocList]

      let response = null

      const splittedData = pathParam.split('-')
      const aocType = splittedData[0]
      const year = parseInt(splittedData[1])
      const month = parseInt(splittedData[2])

      const itemIndex = aocList.findIndex((item: any) => {
        if (item.aoc_type === aocType && item.month === month && item.year === year) {
          return item
        }
        return null
      })

      if (isHistory) {
        response = await HttpPatch(`/admin/aoc/update/target/${pathParam}`, {
          ...queryParams,
          crew_id: 10000,
        })
      } else {
        const body = {
          ...clonedList[itemIndex],
          ...queryParams,
          month: selectedMonth,
          crew_id: 10000,
        }

        response = await HttpPost(`/admin/aoc/create/target`, body)
      }

      const respData = response.data.newTarget

      if (itemIndex !== -1) {
        clonedList[itemIndex] = {
          ...clonedList[itemIndex],
          ...respData,
        }
      }
      return {
        data: clonedList,
        isHistory: isHistory,
      }
    } catch (error: any) {
      console.log('update aoc tier | err: ', error.response)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
