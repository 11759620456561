import Tabs from 'components/Tabs'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/configureStore'
import { AocState } from 'redux/departmentRepresentative/aocSlice'
import { MetaFilterState, setAoc } from 'redux/departmentRepresentative/metafilters'
import { getAocDetails } from 'redux/departmentRepresentative/thunk/getAocDetails'
import { getAocList } from 'redux/departmentRepresentative/thunk/getAocList'

const LABELS: any = {
  AK: 'MAA',
  D7: 'AAX',
  FD: 'TAA',
  QZ: 'IAA',
  Z2: 'PAA',
}

interface AocTabsProps {}

const AocTabs = (props: AocTabsProps) => {
  const {} = props

  const dispatch = useDispatch()
  const { currentMonth, currentYear } = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const aocState = useSelector<RootState, AocState>((state) => state.aoc)
  const { aoc: aocFilter } = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const aocTabs = useMemo(() => {
    if (aocState.result?.length) {
      if (!aocFilter) {
        // set default aoc if not set
        dispatch(setAoc(aocState.result[0].aoc_type))
      }

      // then return the aoc list
      return aocState.result
        .filter((aoc) => aoc?.aoc_type && aoc?.aoc_type !== "") // do not include empty aoc titles
        .map((aoc) => aoc?.aoc_type ?? "" )
    }

    return []
  }, [aocState.result?.length])

  useEffect(() => {
    dispatch(
      getAocList({
        month: Number(currentMonth),
        year: Number(currentYear),
      }),
    )
  }, [])

  return (
    <Tabs
      data={aocTabs}
      activeTabIndex={aocTabs.indexOf(aocFilter)}
      onIndexChange={(newIndex) => {
        dispatch(setAoc(aocTabs[newIndex]))
      }}
      labelFormatter={(label: string) => LABELS[label] ?? label}
      className="mt-auto "
    />
  )
}

export default AocTabs
