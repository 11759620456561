import { faBars, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AALogo from 'assets/icons/AirAsia.svg'
import DropdownMenu from 'components/DropdownMenu'
import { route } from 'constants/routes'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'redux/configureStore'
import { getUserAndAocDetails } from 'redux/thunk/getUserAndAocDetails'
import { UserState } from 'redux/user'
import { useResponsiveCheck } from 'utils/hooks'

interface TopbarProps {
  onLogout?: () => void
}

enum Impersonations {
  CREW_AK = 'rezzazakariah@airasia.com',
  CREW_Z2 = 'jordanlapuz@airasia.com',
  CREW_QZ = 'anandarachmaadira@airasia.com',
  CREW_FD = 'teerapatk@airasia.com',
  EXEC = 'faroukeffendy@airasia.com',
  EXEC_FD = 'sirilakt@airasia.com',
  // SUPP_ADMIN = 'vdr_flxrzjr@airasia.com',
  SUPP_ADMIN = 'darusfazarin@airasia.com',
  DEPT_REP = 'windsorguadalupejr@airasia.com',
  FARHANY = 'norfarhanymatsaad@airasia.com',
}

export const Topbar = (props: TopbarProps) => {
  const { onLogout } = props
  const isDev = process.env.REACT_APP_DEBUG === 'true'

  const userState = useSelector<RootState, UserState>((state) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isDesktop } = useResponsiveCheck()
  const [topbarVisible, setTopbarVisibility] = useState(true)
  const previousScrollPos = useRef(0) // NOTE: Avoid useState to avoid expensive rerendering

  const impersonate = (role: Impersonations) => () => {
    Promise.resolve(dispatch(getUserAndAocDetails({ email: role }))).then(() => {
      navigate(route.HOME, { replace: true })
    })
  }

  const DropdownItems = [
    ...(isDev
      ? [
          {
            label: 'Impersonate',
            subItems: [
              {
                label: 'Crew',
                subItems: [
                  {
                    label: 'Nor Farhany Mat Saad',
                    onClick: impersonate(Impersonations.FARHANY),
                  },
                  {
                    label: 'Crew (AK)',
                    onClick: impersonate(Impersonations.CREW_AK),
                  },
                  {
                    label: 'Crew (Z2)',
                    onClick: impersonate(Impersonations.CREW_Z2),
                  },
                  {
                    label: 'Crew (QZ)',
                    onClick: impersonate(Impersonations.CREW_QZ),
                  },
                  {
                    label: 'Crew (FD)',
                    onClick: impersonate(Impersonations.CREW_FD),
                  },
                ],
              },
              {
                label: 'Executive',
                subItems: [
                  {
                    label: "Executive (AK)",
                    onClick: impersonate(Impersonations.EXEC),
                  },
                  {
                    label: "Executive (FD)",
                    onClick: impersonate(Impersonations.EXEC_FD),
                  }
                ]
              },
              {
                label: 'Support Admin',
                onClick: impersonate(Impersonations.SUPP_ADMIN),
              },
              {
                label: 'Department Representative',
                onClick: impersonate(Impersonations.DEPT_REP),
              },
            ],
          },
        ]
      : []),
    { separator: true },
    {
      label: 'Logout',
      onClick: onLogout,
    },
  ]

  const goToHome = () => {
    navigate(route.HOME)
  }

  const handleTopbarScroll = () => {
    if (!isDesktop) {
      const currentScrollPos = document.getElementById('main-container')?.scrollTop ?? 0
      if (currentScrollPos > previousScrollPos.current) {
        // IF scrolling down, hide the top bar
        setTopbarVisibility(false)
      } else {
        // IF scrolling up, show the top bar
        setTopbarVisibility(true)
      }

      previousScrollPos.current = currentScrollPos
    }
  }

  useEffect(() => {
    document.getElementById('main-container')?.addEventListener('scroll', handleTopbarScroll, true)
    return () => document.getElementById('main-container')?.removeEventListener('scroll', handleTopbarScroll, true)
  }, [isDesktop])

  return (
    <div
      className={`z-10 h-16 bg-white shadow-md xl:shadow-none xl:border-b shrink-0 flex flex-row items-center sticky ${
        topbarVisible ? (isDesktop ? 'top-0' : 'top-0 animate-slide-down') : ''
      }`}>
      {/* Home button */}
      {isDesktop && (
        <div className="w-16 shrink-0 flex items-center justify-center">
          <FontAwesomeIcon icon={faBars} />
        </div>
      )}

      {/* The rest of the topbar */}
      <div className="grow px-4 flex flex-row">
        <img src={AALogo} alt="AirAsia Logo" className="pr-2" onClick={goToHome} />
        <span className="font-extrabold">Crew Commision Portal</span>
      </div>

      {/* User Profile */}
      <DropdownMenu items={DropdownItems}>
        <div
          className={`shrink-0 px-4 my-2 self-stretch flex flex-row items-center cursor-pointer ${
            isDesktop ? 'border-l' : ''
          }`}>
          <FontAwesomeIcon icon={faUserCircle} size="xl" />
          {isDesktop && <div className="ml-2">{userState.user.name}</div>}
        </div>
      </DropdownMenu>
    </div>
  )
}
