import { ReactNode } from 'react'

interface HeaderProps {
  children?: ReactNode
  className?: string
}

type SubComponents = {
  H1: typeof H1
  H2: typeof H2
  H3: typeof H3
  H4: typeof H4
  H5: typeof H5
  H6: typeof H6
  Subtitle1: typeof Subtitle1
}
const H1 = ({ children, className = '' }: HeaderProps) => <h1 className={`${className}`}>{children}</h1>
const H2 = ({ children, className = '' }: HeaderProps) => <h2 className={`${className}`}>{children}</h2>
const H3 = ({ children, className = '' }: HeaderProps) => (
  <h3 className={`text-xl font-medium ${className}`}>{children}</h3>
)
const H4 = ({ children, className = '' }: HeaderProps) => <h4 className={`${className}`}>{children}</h4>
const H5 = ({ children, className = '' }: HeaderProps) => <h5 className={`${className}`}>{children}</h5>
const H6 = ({ children, className = '' }: HeaderProps) => <h6 className={`${className}`}>{children}</h6>
const Subtitle1 = ({ children, className }: HeaderProps) => (
  <span className={`text-xs font-medium block ${className}`}>{children}</span>
)

const Header: SubComponents = {
  H1: H1,
  H2: H2,
  H3: H3,
  H4: H4,
  H5: H5,
  H6: H6,
  Subtitle1: Subtitle1,
}

export default Header
