import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../../apis'

export interface AocDetailsParams {
  aoc: string
}

// First, create the thunk
export const getAocDetails = createAsyncThunk(
  'aoc/getAocDetails',
  async (params: AocDetailsParams, { rejectWithValue }) => {
    try {
      const response = await HttpGet('/aoc/info', params)
      return response.data
    } catch (error: any) {
      console.error('aoc info err: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
