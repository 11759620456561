const getApiUrl = (maxTries = 3): string | undefined => {
  const apiUrl = process.env.REACT_APP_API_URL;

  if (apiUrl) {
    return apiUrl;
  } else {
    if (maxTries > 0) {
      console.log("Getting api url again...");
      return getApiUrl(maxTries - 1);
    }
    return "";
  }
}

export default getApiUrl;