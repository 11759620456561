import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from 'apis'
import { AxiosResponse } from 'axios'

interface GetSyncInfoResponse {
  timestamp: string
}

export const getSyncInfo = createAsyncThunk('user/getSyncInfo', async (params: any, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetSyncInfoResponse> = await HttpGet('crew/dashboard/sync-info')
    return response.data
  } catch (error: any) {
    console.log('Error getting sync info: ', error)

    if (error?.response?.data) {
      return rejectWithValue(error.response.data)
    }
  }
})
