import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


const ConfirmDownloadModal = (props: any) => {
  const { open, title, description, onClose, onClick } = props;

  return (
    <Modal
      open={open!}
      onClose={onClose}
      aria-labelledby="download-modal-title"
      aria-describedby="download-modal-description"
    >
      <Box style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        backgroundColor: 'white',
        borderRadius: '8px',
        padding: '24px',
      }}>
        <Typography id="download-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="download-modal-description" sx={{ mt: 2 }}>
          {description}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 3 }}>
          <Button sx={{ mr: 1 }} onClick={onClose} variant="outlined" disableElevation>
            No
          </Button>
          <Button onClick={onClick} variant="contained" disableElevation>
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfirmDownloadModal