import TierDetailCrewCard from 'components/Card/TierDetailCrewCard'
import { MainContainer } from 'components/Container'
import SharedTable from 'components/Table/SharedTable'
import Tabs from 'components/Tabs'
import { log } from 'console'
import { route } from 'constants/routes'
import { format } from 'date-fns'
import { findIndex } from 'lodash'
import { MRT_PaginationState } from 'material-react-table'
import AdminSummaryCard from 'pages/components/AdminSummaryCard'
import AocTabs from 'pages/components/AocTabs'
import HeaderToolbar from 'pages/home/components/HeaderToolbar'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from 'redux/configureStore'
import { AocState } from 'redux/departmentRepresentative/aocSlice'
import { CrewListState } from 'redux/departmentRepresentative/crewSlice'
import { MetaFilterState } from 'redux/departmentRepresentative/metafilters'
import { getCrewDetails } from 'redux/departmentRepresentative/thunk/getCrewDetails'
import { getCrewStatistics } from 'redux/departmentRepresentative/thunk/getCrewStatistics'
import { setDownloadFilter } from 'redux/transactions'
import { useResponsiveCheck } from 'utils/hooks'

const categories = [
  { value: '', name: 'All' },
  { value: '1', name: 'Tier 1' },
  { value: '2', name: 'Tier 2' },
  { value: '3', name: 'Tier 3' },
  { value: '4', name: 'Tier 4' },
]

const TierDetails = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { aocCurrency } = useSelector<RootState, AocState>((state) => state.aoc)
  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const { listWithDetails, listWithDetailsIsLoading, listWithDetailsPagination } = useSelector<
    RootState,
    CrewListState
  >((state) => state.crewList)
  const { isDesktop } = useResponsiveCheck()
  let [searchParams, setSearchParams] = useSearchParams()
  const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 1, pageSize: 5 })
  const tierParam = searchParams.get('tier')

  const goToTransactions = (row: any) => {
    navigate(route.CREWS_TRANSACTIONS.replace(':crewId', row.crew_id.toString()), {
      state: {
        crew_id: row.crew_id,
      },
    })
  }

  useEffect(() => {
    const tier = tierParam ? parseInt(tierParam) : undefined
    const { dateFrom, dateTo, aoc, hub } = metaFilterState

    dispatch(
      getCrewDetails({
        start_date: dateFrom,
        end_date: dateTo,
        aoc,
        hub,
        tier,
        page: pagination.pageIndex + 1,
        page_size: pagination.pageSize,
      }),
    )

    dispatch(
      setDownloadFilter({
        from_date: dateFrom,
        to_date: dateTo,
        aoc,
        hub,
        tier,
      }),
    )
  }, [
    searchParams.get('tier'),
    pagination.pageIndex,
    pagination.pageSize,
    metaFilterState.currentMonth,
    metaFilterState.currentYear,
    metaFilterState.aoc,
    metaFilterState.hub,
  ])

  useEffect(() => {
    dispatch(
      getCrewStatistics({
        start_date: metaFilterState.dateFrom,
        end_date: metaFilterState.dateTo,
        aoc: metaFilterState.aoc,
        hub: metaFilterState.hub,
      }),
    )
  }, [metaFilterState.aoc, metaFilterState.hub, metaFilterState.currentMonth, metaFilterState.currentYear])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'aoc',
        header: 'AOC & Hub',
        Cell: ({ cell, row }: { cell: any; row: any }) => (
          <>
            <div className="text-sm font-medium font-dmSans text-dark-300">{cell.getValue()}</div>
            <div className="text-xs font-dmSans text-dark-200">{row.original.hub}</div>
          </>
        ),
        size: 80,
        gorw: false,
      },
      {
        accessorKey: 'name',
        header: 'Crew name and ID',
        Cell: ({ cell, row }: { cell: any; row: any }) => (
          <>
            <div className="text-sm font-medium font-dmSans text-ccp-purple80">{cell.getValue()}</div>
            <div className="text-xs font-dmSans text-ccp-grey90">{row.original.crew_id}</div>
          </>
        ),
      },
      {
        accessorKey: 'position_short',
        header: 'Rank',
        Cell: ({ cell }: { cell: any }) => (
          <div className="font-dmSans font-medium text-sm text-dark-300">{cell.getValue()}</div>
        ),
        size: 40,
      },
      {
        accessorKey: 'total_sales',
        header: `Sales (${aocCurrency})`,
        Cell: ({ cell }: { cell: any }) => (
          <div className="bg-ccp-purple100 rounded p-2 text-ccp-purple80 font-medium text-sm flex justify-center">
            {cell.getValue()?.toFixed(2) ?? '0.00'}
          </div>
        ),
        size: 80,
      },
      {
        accessorKey: 'tier',
        header: 'Tier',
        Cell: ({ cell }: { cell: any }) => (
          <div className="bg-ccp-purple90 rounded-full  flex items-center justify-center text-white w-8 h-8 text-sm">
            {cell.getValue()}
          </div>
        ),
        size: 40,
      },
    ],
    [aocCurrency],
  )

  const listWithDetailsData = useMemo(() => {
    if (listWithDetails.length) {
      return listWithDetails
    }

    return []
  }, [listWithDetails])

  return (
    <MainContainer
      contentHeaderBar={
        isDesktop ? (
          <div className="bg-white">
            <div className="bg-white shadow sticky top-0 left-0 w-full z-10 mb-2">
              <div className="h-14 w-full max-w-[1280px] px-4 mx-auto flex flex-row">
                <div className="grow flex flex-row">
                  <AocTabs />
                </div>
                <HeaderToolbar className="shrink-0" isDesktop={isDesktop} />
              </div>
            </div>
            <div className="max-w-[1280px] mx-auto px-4 pb-4">
              <AdminSummaryCard />
            </div>
          </div>
        ) : null
      }>
      <div className="mt-4 text-base font-medium font-dmSans text-ccp-purple80">Tier details</div>

      <div className="flex mt-2">
        <div className="text-xs font-medium font-dmSans">Date selected</div>
        <div className="text-xs font-bold font-dmSans ml-2">
          {format(new Date(metaFilterState.dateFrom), 'dd')} -{' '}
          {format(new Date(metaFilterState.dateTo), 'dd MMM, yyyy')}
        </div>
      </div>

      <Tabs
        data={categories.map((cat) => cat.name)}
        activeTabIndex={findIndex(categories, ['value', tierParam])}
        onIndexChange={(newIdx) => {
          setSearchParams({ tier: categories[newIdx].value })
        }}
        className="bg-white rounded-t mt-2"
      />

      <div className="flex">
        <SharedTable
          data={listWithDetailsData}
          columns={columns}
          pagination={pagination}
          pageCount={listWithDetailsPagination.total_page}
          setPagination={setPagination}
          showActionColumn
          onActionClick={goToTransactions}
          isLoading={listWithDetailsIsLoading}
        />

        <TierDetailCrewCard />
      </div>
    </MainContainer>
  )
}

export default TierDetails
