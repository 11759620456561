import { createTheme } from '@mui/material/styles'
const theme = createTheme({
  palette: {
    primary: {
      main: '#e32625',
      contrastText: '#fff',
    },
    text: {
      secondary: '#00000',
    },
    secondary: {
      main: '#68CDE7',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Roboto', 'sans-serif'].join(','),
    h1: {
      fontSize: 20,
    },
    h2: {
      fontSize: 14,
    },
    h3: {
      fontSize: 12,
    },
    h4: {
      fontSize: 11,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          width: 200,
        },
      },
    },
  },
})

export default theme
