import { useMediaQuery } from '@mui/material'
import { HttpGet, HttpPost } from 'apis'
import { ComponentProps, FC, useEffect, useRef, useState } from 'react'

/**
 * Utility hook to help with responsive UI check. We follow tailwind's convention
 * for consistency
 * ref: https://tailwindcss.com/docs/responsive-design
 * @returns
 */
export const useResponsiveCheck = (): { isTablet: boolean; isDesktop: boolean } => {
  const isTablet = useMediaQuery('(min-width: 768px)') // TW's lg
  const isDesktop = useMediaQuery('(min-width: 1280px)') // TW's xl

  return {
    isTablet,
    isDesktop,
  }
}

/**
 * utility hook to lazy load svg import using svgr, courtesy of Kwan Jun Wen
 * ref: https://stackoverflow.com/a/61472427
 */
export const useLazySvgImport = (name: string) => {
  const importRef = useRef<FC<ComponentProps<'svg'>>>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()

  useEffect(() => {
    setLoading(true)
    const importIcon = async () => {
      try {
        importRef.current = (await import(`../assets/icons/${name}.svg?react`)).ReactComponent
      } catch (err) {
        setError(err as Error)
      } finally {
        setLoading(false)
      }
    }
    importIcon()
  }, [name])

  return {
    error,
    loading,
    Svg: importRef.current,
  }
}

type useAjaxCallOptions = {
  queryParams?: any
  timeout?: number
  dependencyArray?: any[]
}

export const useAjaxCall = <P, D = any>(
  httpMethod: 'GET' | 'POST',
  url: string,
  payload?: P,
  options: useAjaxCallOptions = {},
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<D | null>(null)
  const [error, setError] = useState<any>(null)

  let _options = { queryParams: {}, timeout: 60000, dependencyArray: [], ...options }

  const fetch = async () => {
    setIsLoading(true)
    try {
      let response
      if (httpMethod === 'GET') {
        response = await HttpGet(url, payload ?? {}, _options.timeout)
      } else if (httpMethod === 'POST') {
        response = await HttpPost(url, payload, _options.queryParams, _options.timeout)
      }

      setData(response?.data)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetch()
  }, [..._options.dependencyArray])

  return [
    {
      data,
      error,
    } as any,
    isLoading,
  ]
}
