import { faCircleXmark, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { InputHTMLAttributes } from 'react'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string
  /**
   * @deprecated use sizing instead
   */
  forMobile?: boolean
  /**
   * Size variant for input. Usually `md` for desktop and `lg` for mobile
   * @default 'lg'
   */
  sizing?: 'md' | 'lg'
  showClearButton?: boolean
  onChange: (value: any) => void
  wrapperClassName?: string
}

/**
 * NB: Recommendations for mobile input size is at least 44px (i.e. tailwind's h-11)
 *     ref: https://www.w3.org/WAI/WCAG21/Understanding/target-size.html#:~:text=Sufficient%20Techniques,the%20target%20independent%20of%20magnification
 */
const Input = (props: InputProps) => {
  const {
    className,
    wrapperClassName,
    error,
    forMobile = false,
    sizing = 'lg',
    onChange,
    showClearButton,
    ...inputProps
  } = props
  const isLgStyle = sizing === 'lg' || forMobile

  const clearValue = () => {
    if (onChange) {
      onChange('')
    }
  }

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value)
    }
  }

  return (
    <div className={clsx('relative', wrapperClassName)}>
      <input
        className={clsx(
          'w-full rounded border',
          isLgStyle ? 'min-h-11 px-4' : 'min-h-8 text-sm px-2 py-1',
          error && 'border-red-500',
          className,
        )}
        onChange={handleOnChange}
        {...inputProps}
      />
      {showClearButton && inputProps.value && (
        <div
          className={clsx(
            'h-6 w-6 absolute rounded-full hover:bg-ccp-hover cursor-pointer flex items-center justify-center',
            isLgStyle ? 'top-3 right-3' : 'top-1 right-1',
          )}
          onClick={clearValue}>
          <FontAwesomeIcon icon={faClose} />
        </div>
      )}
      {error ? <span className="text-xs text-red-500 italic">{error}</span> : null}
    </div>
  )
}

export default Input
