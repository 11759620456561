import { ReactNode } from 'react'
import SwipeableViews, { OnChangeIndexCallback } from 'react-swipeable-views'

interface SwipeableContentProps {
  className?: string
  pageWrapperClassName?: string
  pages?: ReactNode[]
  onChange?: OnChangeIndexCallback
  activeTabIndex?: number
}

const SwipeableContent = (props: SwipeableContentProps) => {
  const { className, pageWrapperClassName, pages, onChange, activeTabIndex } = props
  return (
    <div className={className}>
      <SwipeableViews resistance index={activeTabIndex} onChangeIndex={onChange}>
        {pages &&
          pages.length > 0 &&
          pages.map((page, idx) => (
            <div key={idx} className={pageWrapperClassName}>
              {page}
            </div>
          ))}
      </SwipeableViews>
    </div>
  )
}

export default SwipeableContent
