import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import DropdownMenu, { DropdownItems } from 'components/DropdownMenu'
import { Link } from 'react-router-dom'
import { Fragment } from 'react/jsx-runtime'

interface BreadcrumbData {
  label: string
  url?: string
  options?: DropdownItems[]
}

interface BreadcrumbProps {
  items: BreadcrumbData[]
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { items } = props

  const renderItem = (item: BreadcrumbData, index: number) => {
    if (item.url) {
      return (
        <Link
          to={item.url}
          className={clsx(
            'text-sm hover:text-gray-800',
            index !== items.length - 1 ? 'text-gray-500' : 'text-gray-800 font-medium',
          )}>
          {item.label}
        </Link>
      )
    }

    if (item.options) {
      return (
        <DropdownMenu items={item.options}>
          <span className={clsx('text-sm', index !== items.length - 1 ? 'text-gray-500' : 'text-gray-800 font-medium')}>
            {item.label}
          </span>
        </DropdownMenu>
      )
    }

    return (
      <span className={clsx('text-sm', index !== items.length - 1 ? 'text-gray-500' : 'text-gray-800 font-medium')}>
        {item.label}
      </span>
    )
  }

  return (
    <div className="flex flex-row items-center gap-4">
      {items.map((item, index) => {
        return (
          <Fragment key={'bc-' + index}>
            {index > 0 ? <FontAwesomeIcon icon={faChevronRight} className="text-gray-500" /> : null}
            {renderItem(item, index)}
          </Fragment>
        )
      })}
    </div>
  )
}

export default Breadcrumb
