import { DateRange } from 'components/DayPicker/DateRangePicker'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/configureStore'
import { MetaFilterState, setDateRange } from 'redux/departmentRepresentative/metafilters'
import { DownloadReportParams, getCrewReports } from 'redux/thunk/getCrewReports'
import { FlightPairingTransaction } from 'redux/thunk/getFlightPairingList'
import { setDownloadFilter, TransactionState } from 'redux/transactions'

export const mapToFlightPairingCardData = (d: FlightPairingTransaction, currency: string) => ({
  currency,
  id: d.id,
  date: d.date_string,
  flightNoPair: d.flight_number_pairing,
  sales: {
    santan: d.total_sales.perishable + d.total_sales.non_perishable,
    mcds: d.total_sales.mcds,
    dtf: d.total_sales.dtf,
    domlux: d.total_sales.domlux,
  },
  earnings: {
    santan: d.total_commission.perishable + d.total_commission.non_perishable,
    mcds: d.total_commission.mcds,
    dtf: d.total_commission.dtf,
    domlux: d.total_commission.domlux,
  },
})

/**
 * DRYing the set and get date range from metaFilterState
 */
export const useHeaderDateRangeHelper = () => {
  const todaysDate = new Date()
  const defaultDateRange = {
    from: new Date(todaysDate.getFullYear(), todaysDate.getMonth(), 1),
    to: todaysDate,
  }

  const dispatch = useDispatch()
  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)

  const setSelectedDateRange = (newRange?: DateRange) => {
    if (newRange && newRange.from && newRange.to)
      dispatch(
        setDateRange({
          dateFrom: format(newRange.from, 'yyyy-MM-dd'),
          dateTo: format(newRange.to, 'yyyy-MM-dd'),
        }),
      )
  }

  const selectedDateRange = useMemo(() => {
    return {
      from: new Date(metaFilterState.dateFrom),
      to: new Date(metaFilterState.dateTo),
    }
  }, [metaFilterState.dateFrom, metaFilterState.dateTo])

  return {
    selectedDateRange,
    setSelectedDateRange,
    defaultDateRange,
  }
}

export const useDownloadReportHelper = () => {
  const transactionState = useSelector<RootState, TransactionState>((state) => state.transactions)

  const dispatch = useDispatch()

  const _downloadCrewReportByType = (type: 'CSV' | 'PDF') => () => {
    dispatch(
      getCrewReports({
        ...transactionState.downloadReportsFilter,
        download_type: type,
      }),
    )
  }

  const DownloadCrewDropdownItems = [
    {
      label: 'Download PDF',
      onClick: _downloadCrewReportByType('PDF'),
    },
    {
      label: 'Download CSV',
      onClick: _downloadCrewReportByType('CSV'),
    },
  ]

  const downloadCrewReports = () => {
    dispatch(getCrewReports(transactionState.downloadReportsFilter))
  }

  const setReportFilter = (filter: DownloadReportParams) => {
    dispatch(setDownloadFilter(filter))
  }

  const hasMenu = useMemo(() => {
    if (
      transactionState.downloadReportsFilter.flight_pairing_id &&
      transactionState.downloadReportsFilter.flight_pairing_id !== ''
    ) {
      return true
    } else {
      return false
    }
  }, [transactionState.downloadReportsFilter.flight_pairing_id])

  const reportFilters = useMemo(() => {
    return transactionState.downloadReportsFilter
  }, [transactionState.downloadReportsFilter])

  const downloadingReport = useMemo(() => {
    return transactionState.downloadingReports
  }, [transactionState.downloadingReports])

  return {
    downloadCrewReports,
    setReportFilter,
    reportFilters,
    downloadingReport,
    DownloadCrewDropdownItems,
    hasMenu,
  }
}
