const encryptPayload = (payload: any, maxTries = 3): string => {
  try {
    const CryptoJS = require('crypto-js')

    const payloadString: string = JSON.stringify(payload)
    const encryptedPayload: string = CryptoJS.AES.encrypt(payloadString, process.env.REACT_APP_SECRET_KEY).toString()
    const encrypted: string = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encryptedPayload))

    // const data: string = CryptoJS.enc.Base64.parse(encrypted).toString(CryptoJS.enc.Utf8)
    // const bytes = CryptoJS.AES.decrypt(data, process.env.REACT_APP_SECRET_KEY);

    return encrypted
  } catch (error) {
    if (maxTries > 0) {
      console.log('Retrying encryption...')
      return encryptPayload(payload, maxTries - 1)
    }
    return ''
  }
}

// simple id spoofing utility
export const idSpoofer = {
  encrypt: (id: string) => {
    const CryptoJS = require('crypto-js')
    // const encrypted = CryptoJS.AES.encrypt(id, process.env.REACT_APP_SECRET_KEY)
    // return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
    const test = CryptoJS.AES.encrypt(id, process.env.REACT_APP_SECRET_KEY).toString()

    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(test))
  },

  decrypt: (encString: string) => {
    const CryptoJS = require('crypto-js')
    // const ciphParams = CryptoJS.lib.CipherParams.create({
    //   ciphertext: CryptoJS.enc.Base64.parse(encString),
    // })
    // const decrypted = CryptoJS.AES.decrypt(ciphParams, process.env.REACT_APP_SECRET_KEY)
    // return decrypted.toString(CryptoJS.enc.Utf8)
    const test = CryptoJS.AES.decrypt(CryptoJS.enc.Utf8.parse(encString), process.env.REACT_APP_SECRET_KEY).toString(
      CryptoJS.enc.Utf8,
    )
    return test
  },
}

export default encryptPayload
