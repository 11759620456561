const useStyles = {
  main: {
    float: 'right',
    mt: 3,
    mb: 1,
    mr: 1,
    p: 1,
    border: 1,
    borderRadius: 2,
    backgroundColor: 'white',
  },
}
export default useStyles
