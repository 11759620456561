import { createSlice } from '@reduxjs/toolkit'
import { getAocList } from './thunk/getAocList'
import { getAocListByAoc } from './thunk/getAocListByAoc'
import { patchAocTier } from './thunk/updateAOCTier'
import { getAocDetails } from './thunk/getAocDetails'
import { getCrewSummary } from 'redux/thunk/getCrewSummary'
import { getMonthlySummary } from './thunk/getMontlySummary'

export interface AocTierState {
  tier_1: number
  tier_2: number
  tier_3: number
  month: number
  year: number
  currency: string
  aoc_type: string
  updated_by: string
}

export interface AocState {
  result: Array<AocTierState>
  historyList: Array<AocTierState>
  aocTierIsLoading: boolean
  aocTierUpdateIsLoading: boolean
  aocListByAocIsLoading: boolean
  aocTierErrorMsg: any
  hubs: string[]
  aocCurrency: string
  aocDetailsIsLoading: boolean
}

const initialState: AocState = {
  aocTierIsLoading: false,
  aocTierUpdateIsLoading: false,
  aocListByAocIsLoading: false,
  result: [],
  historyList: [],
  aocTierErrorMsg: '',
  hubs: [],
  aocCurrency: '',
  aocDetailsIsLoading: false,
}

export const aocSlice = createSlice({
  name: 'aoc',
  initialState,
  reducers: {
    setAocCurrency: (state, action) => {
      state.aocCurrency = action.payload
    },
    resetTransctionsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAocList.pending, (state, action) => {
      state.aocTierIsLoading = true
    })
    builder.addCase(getAocList.fulfilled, (state, action) => {
      state.aocTierIsLoading = false
      state.result = action.payload
    })
    builder.addCase(getAocList.rejected, (state, action) => {
      state.aocTierIsLoading = false
      state.aocTierErrorMsg = 'Error fetching AOC list'
    })
    builder.addCase(patchAocTier.pending, (state, action) => {
      state.aocTierUpdateIsLoading = true
    })
    builder.addCase(patchAocTier.fulfilled, (state, action) => {
      state.aocTierUpdateIsLoading = false
      if (action.payload?.data) {
        const clonedArr = [...action.payload.data]
        if (action.payload.isHistory) {
          state.historyList = clonedArr
        } else {
          state.result = clonedArr
        }
      }
    })
    builder.addCase(patchAocTier.rejected, (state, action) => {
      state.aocTierUpdateIsLoading = false
      state.aocTierErrorMsg = 'Error fetching AOC list'
    })
    builder.addCase(getAocListByAoc.pending, (state, action) => {
      state.aocListByAocIsLoading = true
    })
    builder.addCase(getAocListByAoc.fulfilled, (state, action) => {
      state.aocListByAocIsLoading = false
      state.historyList = action.payload
    })
    builder.addCase(getAocListByAoc.rejected, (state, action) => {
      state.aocListByAocIsLoading = false
      state.aocTierErrorMsg = 'Error fetching AOC list by AOC'
    })
    builder.addCase(getAocDetails.pending, (state, action) => {
      state.aocDetailsIsLoading = true
    })
    builder.addCase(getAocDetails.fulfilled, (state, action) => {
      state.hubs = action.payload?.hubs ?? []
      state.aocDetailsIsLoading = false
    })
    builder.addCase(getAocDetails.rejected, (state, action) => {
      console.error('[error] failed to get AOC detail')
      state.hubs = []
      state.aocDetailsIsLoading = false
    })
    builder.addCase(getCrewSummary.fulfilled, (state, action) => {
      state.aocCurrency = action.payload?.currency ?? state.aocCurrency
    })
    builder.addCase(getMonthlySummary.fulfilled, (state, action) => {
      state.aocCurrency = action.payload?.currency ?? state.aocCurrency
    })
  },
})

export const { setAocCurrency, resetTransctionsState } = aocSlice.actions

export default aocSlice.reducer
