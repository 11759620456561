import { Box, Typography } from '@mui/material'
import useStyles from './Styles'

interface TotalRecordsProps {
  total: number
}

const TotalRecords: React.FunctionComponent<TotalRecordsProps> = ({ total }) => {
  return (
    <Box sx={useStyles.main}>
      <Typography component="span" sx={{ fontSize: 13, marginLeft: 1 }}>
        TOTAL RECORDS:
      </Typography>
      <Typography component="span" sx={{ fontSize: 13, marginLeft: 1, fontWeight: 'bold' }}>
        {total}
      </Typography>
    </Box>
  )
}

export default TotalRecords
