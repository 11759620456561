import * as RTabs from '@radix-ui/react-tabs'
import { isNil } from 'lodash'
import { MutableRefObject, useEffect, useRef } from 'react'
import { useDraggable } from 'react-use-draggable-scroll'

interface TabsProps {
  data: any[]
  activeTabIndex?: number
  defaultActiveTabIndex?: number
  className?: string
  wrapperClassName?: string
  onIndexChange?: (newIndex: number) => void
  labelFormatter?: (label: any) => string
}

const Tabs = (props: TabsProps) => {
  const {
    data,
    activeTabIndex,
    defaultActiveTabIndex = 0,
    className,
    wrapperClassName,
    onIndexChange,
    labelFormatter = (s) => s,
  } = props

  const scrollRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>
  const listRef = useRef<HTMLDivElement>(null)
  const { events: dragEvents } = useDraggable(scrollRef, {
    applyRubberBandEffect: true,
  })

  // NOTE: Math.max to safeguard active tab index from -1
  const currentActiveTab = Math.max(activeTabIndex ?? defaultActiveTabIndex, 0)

  const handleOnValueChange = (newValue: string) => {
    if (onIndexChange) onIndexChange(data.indexOf(newValue))
  }

  /**
   * Side effect to scroll to active tab when active tab changes
   */
  useEffect(() => {
    const listParentEle = listRef.current

    if (listParentEle && data.length) {
      const btns = listParentEle.children
      const activeBtnOffset = (btns.item(currentActiveTab) as HTMLElement).offsetLeft

      // NOTE: (offsetLeft - 16) bcs button padding 16px
      scrollRef.current.scrollTo({ left: activeBtnOffset - 16, behavior: 'smooth' })
    }
  }, [currentActiveTab, data])

  return (
    <div
      ref={scrollRef}
      {...dragEvents}
      className={`overflow-x-scroll scrollbar-hide relative flex flex-row items-end ${wrapperClassName}`}>
      <RTabs.Root
        onValueChange={handleOnValueChange}
        defaultValue={data[defaultActiveTabIndex]}
        className={`h-min-11 ${className}`}>
        <RTabs.List ref={listRef} className="flex flex-row h-full w-full border-b">
          {!!data?.length &&
            data.map((d, idx) => (
              <RTabs.Trigger key={idx} value={d} className={`relative py-2 mx-4`}>
                {labelFormatter(d)}
                <div
                  className={`w-full absolute left-0 bottom-0 border-t-4 rounded-t border-ccp-primary transition-transform origin-bottom ${
                    currentActiveTab === idx ? 'scale-y-100' : 'scale-y-0'
                  }`}
                />
              </RTabs.Trigger>
            ))}
        </RTabs.List>
      </RTabs.Root>
    </div>
  )
}

export default Tabs
