import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getUserAndAocDetails } from './thunk/getUserAndAocDetails'
import { getSyncInfo } from './thunk/getSyncInfo'

interface User {
  name: string
  email: string
}

export interface ExtraDetails {
  full_name: string | null
  email: string | null
  crew_id: string
  hub?: string | null
  aoc: string
  role: string
  permissions: string[]
  currency?: string
}

export interface AocDetails {
  tier_1: number
  tier_2: number
  tier_3: number
  aoc_type: string
  currency: string
  year?: number
  month?: number
  description?: string
  updated_by?: string
  updated_at?: {
    _seconds: number
  }
  created_by?: number
  created_at?: {
    _seconds: number
  }
}

export interface UserState {
  user: User
  extraDetails: ExtraDetails
  aocDetails: AocDetails
  isLoggedIn: boolean
  isLoading: boolean
  userDetailsErroMsg: string | null
  isSyncing: boolean
  lastSyncInfo: string | null
}

const initialState: UserState = {
  user: {
    name: '-',
    email: '-',
  },
  extraDetails: {
    full_name: null,
    email: '-',
    crew_id: '-',
    hub: null,
    aoc: '-',
    role: 'crew',
    permissions: [],
  },
  aocDetails: {
    tier_1: 0,
    tier_2: 0,
    tier_3: 0,
    aoc_type: '-',
    currency: '-',
  },
  isLoggedIn: false,
  isLoading: false,
  userDetailsErroMsg: null,
  isSyncing: false,
  lastSyncInfo: null,
}

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    setLoggedIn: (state, action) => {
      console.log(action.payload)
      if (!action.payload) {
        state.user.name = '-'
        state.user.email = '-'
      }
      state.isLoggedIn = action.payload
    },
    setAuthLoading: (state, action) => {
      state.isLoading = action.payload
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserAndAocDetails.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUserAndAocDetails.fulfilled, (state, action: any) => {
      const responseData = action.payload

      try {
        state.extraDetails = responseData.extraDetails

        try {
          state.aocDetails = {
            ...responseData.aocDetails,
            aoc_type: responseData.extraDetails.aoc.toUpperCase(),
            currency: responseData.aocDetails.currency.toUpperCase(),
          }
        } catch (_) {
          // console.log("Cannot read currency from aoc details");
          state.aocDetails = {
            ...responseData.aocDetails,
            aoc_type: responseData.extraDetails.aoc.toUpperCase(),
            currency: responseData.extraDetails.aoc.toUpperCase(),
          }
        }

        // Note: overwrite the name from Google Login
        if (responseData.extraDetails.full_name) {
          state.user.name = responseData.extraDetails.full_name
        }
      } catch (_) {}

      state.isLoading = false
    })
    builder.addCase(getUserAndAocDetails.rejected, (state, action: any) => {
      console.log('getUserAndAocDetails rejected: ', action)
      state.userDetailsErroMsg = 'Error fetching user details. Please try again in a moment'
      state.isLoading = false
    })
    builder.addCase(getSyncInfo.pending, (state) => {
      state.isSyncing = true
    })
    builder.addCase(getSyncInfo.fulfilled, (state, action: any) => {
      state.isSyncing = false
      state.lastSyncInfo = action.payload?.timestamp ?? ''
    })
    builder.addCase(getSyncInfo.rejected, (state, action: any) => {
      state.isSyncing = false
      console.error('getSyncInfo rejected: ', action)
    })
  },
})

export const { setUser, setLoggedIn, resetState, setAuthLoading } = user.actions

export default user.reducer
