import SharedTable from 'components/Table/SharedTable'
import Input from 'components/TextField/Input'
import { debounce } from 'lodash'
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/configureStore'
import { MetaFilterState } from 'redux/departmentRepresentative/metafilters'
import { useAjaxCall } from 'utils/hooks'
import UploadCsvModal from './UploadCsvModal'
import { setDownloadFilter } from 'redux/transactions'

interface RouteSpecificTargetProps {}

const RouteSpecificTarget = (props: RouteSpecificTargetProps) => {
  const {} = props

  const dispatch = useDispatch();

  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const [searchText, setSearchText] = useState<string>('')
  const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: 5 })
  const [pageCount, setPageCount] = useState<number>(-1)
  const [routeTargetResponse, routeTargetIsLoading] = useAjaxCall(
    'GET',
    '/tier-targets/daily-route-specific',
    {
      startDate: metaFilterState.dateFrom,
      endDate: metaFilterState.dateTo,
      page: pagination.pageIndex + 1,
      page_size: pagination.pageSize,
      aoc: metaFilterState.aoc,
      flight_number: searchText,
    },
    {
      dependencyArray: [
        metaFilterState.aoc,
        metaFilterState.dateFrom,
        metaFilterState.dateTo,
        searchText,
        pagination.pageIndex,
        pagination.pageSize,
      ],
    },
  )

  useEffect(() => {
    dispatch(
      setDownloadFilter({
        for_targets: true,
        aoc: metaFilterState.aoc,
        month: metaFilterState.currentMonth,
        year: metaFilterState.currentYear
      })
    )
  }, [
    metaFilterState.aoc,
    metaFilterState.currentMonth,
    metaFilterState.currentYear
  ])

  const routeTargetTableData = useMemo(() => {
    if (routeTargetResponse.data) {
      const dailyTargets = routeTargetResponse.data.data.daily_targets
      setPageCount(routeTargetResponse.data.total_page)

      return dailyTargets.map((target: any) => ({
        ...target,
      }))
    }

    return []
  }, [routeTargetResponse.data])

  const columns = useMemo<MRT_ColumnDef<any, any>[]>(
    () => [
      {
        id: 'aoc_and_routes',
        Header: () => <span>AOC & Route</span>,
        header: '',
        Cell: ({ row }) => (
          <>
            <div className="text-sm font-medium text-dark-300">{row.original.aoc}</div>
            <div className="text-xs text-ccp-grey90">{row.original.sector}</div>
          </>
        ),
      },
      {
        accessorKey: 'flight_number',
        Header: () => <span>Flight number</span>,
        header: '',
        Cell: ({ cell }) => <span className="text-dark-200">{cell.getValue()}</span>,
      },
      {
        accessorKey: 'date',
        Header: () => <span>Date</span>,
        header: '',
        Cell: ({ cell }) => <span className="text-dark-200">{cell.getValue()}</span>,
      },
      {
        accessorKey: 'total_target.perishable',
        Header: () => <span>Perishable</span>,
        header: '',
      },
      {
        accessorKey: 'total_target.non_perishable',
        Header: () => <span>Non-perishable</span>,
        header: '',
      },
      {
        accessorKey: 'total_target.mcds',
        Header: () => <span>Merchandise</span>,
        header: '',
      },
      {
        accessorKey: 'total_target.dtf',
        Header: () => <span>Duty-free</span>,
        header: '',
      },
      {
        accessorKey: 'total_target.domlux',
        Header: () => <span>Domestic lux.</span>,
        header: '',
      },
      {
        accessorKey: 'total_target.total',
        Header: () => <span>Overall Target</span>,
        header: '',
        Cell: ({ cell }) => <span className="text-ccp-purple80">{cell.getValue()}</span>,
      },
    ],
    [],
  )

  return (
    <div>
      <div className="flex flex-row mb-2 items-center">
        <div className="grow">
          <Input
            placeholder="Search flight number, AOC"
            wrapperClassName="w-96"
            sizing="md"
            value={searchText}
            onChange={setSearchText}
          />
        </div>
        <UploadCsvModal />
      </div>

      <SharedTable
        columns={columns}
        data={routeTargetTableData}
        pagination={pagination}
        setPagination={setPagination}
        pageCount={pageCount}
        isLoading={routeTargetResponse.data === null && routeTargetIsLoading}
        isSaving={routeTargetResponse.data !== null && routeTargetIsLoading}
      />
    </div>
  )
}

export default RouteSpecificTarget
