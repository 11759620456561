import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SummaryCard from 'components/Card/SummaryCard'
import { format } from 'date-fns'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, generatePath, useNavigate } from 'react-router-dom'
import { getSyncInfo } from 'redux/thunk/getSyncInfo'
import { useResponsiveCheck } from 'utils/hooks'
import { MainContainer } from '../../../components/Container'
import { route } from '../../../constants/routes'
import { RootState } from '../../../redux/configureStore'
import { UserState } from '../../../redux/user'
import HeaderToolbar from '../components/HeaderToolbar'

const Home: React.FC = (): React.ReactElement => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isDesktop } = useResponsiveCheck()

  const userState = useSelector<RootState, UserState>((state) => state.user)

  const viewTierDetails = () => {
    navigate(generatePath(route.TIERS))
  }

  useEffect(() => {
    dispatch(getSyncInfo({}))
  }, [])

  return (
    <MainContainer
      contentHeaderBar={
        isDesktop ? (
          <div className="bg-white shadow sticky top-0 left-0 w-full z-10 mb-2">
            <div className="h-14 w-full max-w-[1280px] px-4 mx-auto flex flex-row">
              <span className="grow flex flex-row items-center">
                Hello {userState.user.name ?? 'there!'}, here is your crew commission dashboard overview
              </span>
              <HeaderToolbar className="shrink-0" isDesktop={isDesktop} />
            </div>
          </div>
        ) : null
      }>
      {!isDesktop && <HeaderToolbar className="mb-4 border-b-2 justify-end h-14" isDesktop={isDesktop} />}

      {/* Last sync indicator */}
      <div className={`flex flex-row justify-end items-center text-xs mb-2${isDesktop ? 'mt-12' : ''}`}>
        <span className="text-gray-800 mr-1">Last sync</span>
        {userState.isSyncing && <FontAwesomeIcon icon={faArrowsRotate} spin />}
        <span className="text-ccp-primary ml-2">
          {userState.lastSyncInfo ? format(new Date(userState.lastSyncInfo), 'd MMMM yyyy H:mm') : 'never'}
        </span>
      </div>

      <SummaryCard onCardClick={viewTierDetails} />

      <Outlet context={{ notFromUserView: false }} />
    </MainContainer>
  )
}

export default Home
