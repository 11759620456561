import { faChevronLeft, faChevronRight, faEllipsis } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil } from 'lodash'
import { ReactNode, useEffect, useMemo, useState } from 'react'

export type Pagination = {
  total_page?: number
  current_page: number
  page_size: number
}

enum BtnType {
  'prev' = 'p',
  'next' = 'n',
  'ellip' = 'e',
}

interface PaginationProps {
  currentPage: number
  dataLength?: number
  itemPerPage?: number
  onSides?: number
  totalPage?: number
  className?: string
  onChange?: (nextPage: number) => void
}

interface NavBtnProps {
  children?: number | ReactNode
  className?: string
  onClick?: () => void
  disabled?: boolean
}

const Pagination = (props: PaginationProps) => {
  const { currentPage, itemPerPage = 5, totalPage = -1, dataLength, onSides = 1, className, onChange } = props
  const [buttons, setButtons] = useState<(number | BtnType)[]>([])
  const lastPage = useMemo(() => {
    if (!isNil(totalPage)) return totalPage

    if (isNil(dataLength)) {
      console.error('[error] Pagination: unable to calculate total page, missing dataLength')
      return 0
    }

    return Math.ceil(dataLength / itemPerPage)
  }, [totalPage, itemPerPage, dataLength])

  const generateBtns = () => {
    let genBtns = []

    for (let i = 1; i <= lastPage; i++) {
      // Figure out the offset based on current page
      const offset = i === 1 || i === lastPage ? onSides + 1 : onSides

      if (i === 1 || (currentPage - offset <= i && currentPage + offset >= i) || i === currentPage || i === lastPage) {
        genBtns.push(i)
      } else if (i === currentPage - (offset + 1) || i === currentPage + (offset + 1)) {
        genBtns.push(BtnType.ellip)
      }
    }

    setButtons([BtnType.prev, ...genBtns, BtnType.next])
  }

  useEffect(generateBtns, [currentPage, itemPerPage, totalPage, dataLength])

  const NavBtn = ({ children, className = '', onClick, disabled }: NavBtnProps) => (
    <button
      onClick={onClick}
      className={[
        'text-sm h-8 w-8 flex items-center justify-center rounded',
        className,
        disabled && 'cursor-not-allowed text-gray-300 border-white',
        !disabled && Number(children) === currentPage + 1 ? 'bg-ccp-primary text-white' : 'bg-white text-current',
      ]
        .filter(Boolean)
        .join(' ')}
      disabled={disabled}>
      {children}
    </button>
  )

  return (
    <div className={['w-full flex flex-row justify-between gap-2', className].filter(Boolean).join(' ')}>
      {buttons.map((btn, idx) => {
        if (btn === BtnType.next) {
          return (
            <NavBtn
              key={`${idx}-${btn}`}
              className="border border-ccp-primary"
              onClick={() => {
                if (onChange) onChange(Math.min(lastPage, currentPage + 1))
              }}
              disabled={currentPage >= totalPage - 1}>
              <FontAwesomeIcon icon={faChevronRight} />
            </NavBtn>
          )
        }

        if (btn === BtnType.prev) {
          return (
            <NavBtn
              key={`${idx}-${btn}`}
              className="border border-ccp-primary"
              onClick={() => {
                if (onChange) onChange(Math.max(0, currentPage - 1))
              }}
              disabled={currentPage === 0}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </NavBtn>
          )
        }

        if (btn === BtnType.ellip) {
          return (
            <NavBtn key={`${idx}-${btn}`} className="bg-none">
              <FontAwesomeIcon icon={faEllipsis} />
            </NavBtn>
          )
        }

        return (
          <NavBtn
            key={`${idx}-${btn}`}
            onClick={() => {
              if (onChange) onChange(Number(btn) - 1)
            }}>
            {btn}
          </NavBtn>
        )
      })}
    </div>
  )
}

export default Pagination
