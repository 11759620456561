export const Footer = () => {
  return (
    <div className="h-16 px-6 shrink-0 flex flex-row items-center ">
      <span className="grow">This site is for AirAsia Group ICT internal use only</span>
      <div>
        <a href="">Terms of Use</a>
        <span className="mx-2">|</span>
        <a href="">Contact Us</a>
        <span className="mx-2">|</span>
        <a href="">Report An Issue</a>
      </div>
    </div>
  )
}
