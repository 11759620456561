const useStyles = {
  headerLbl: {
    textAlign: 'left',
    marginBottom: 2,
  },
  content: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listItem: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    height: 30,
    paddingY: 3,
  },
}
export default useStyles
