import { KeyboardArrowRight } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import { MRT_ColumnDef, MRT_PaginationState, MRT_RowData, MaterialReactTable } from 'material-react-table'

interface SharedTableProps<TData extends MRT_RowData> {
  // General props
  data: TData[]
  columns: MRT_ColumnDef<TData>[]
  isLoading?: boolean
  isSaving?: boolean
  layoutMode?: 'grid' | 'grid-no-grow' | 'semantic'

  // Pagination props
  disablePagination?: boolean
  paginationMode?: 'server' | 'client'
  pagination?: MRT_PaginationState
  setPagination?: any
  pageCount?: number

  // Action column props
  showActionColumn?: boolean
  alternateActionHeaderColumnColor?: boolean // lazy way to set the action header color to ccp-primary
  onActionClick?: (rowData: any) => void
}

const SharedTable = <TData extends MRT_RowData>(props: SharedTableProps<TData>) => {
  const {
    data,
    columns,
    isLoading,
    isSaving,
    layoutMode = 'semantic',
    disablePagination,
    paginationMode = 'server',
    pagination,
    setPagination,
    pageCount,
    showActionColumn,
    alternateActionHeaderColumnColor,
    onActionClick,
  } = props

  return (
    <>
      <div className="w-full font-dmSans">
        <MaterialReactTable
          // General props
          layoutMode={layoutMode}
          columns={columns}
          data={data ?? []}
          enableTopToolbar={false}
          enableSorting={false}
          enableBottomToolbar={!disablePagination}
          state={{
            showSkeletons: data?.length === 0 && isLoading,
            showLoadingOverlay: isLoading,
            ...(pagination ? { pagination } : {}),
          }}
          // Pagination related props
          enablePagination={!disablePagination}
          manualPagination={paginationMode === 'server'}
          paginationDisplayMode="pages"
          autoResetPageIndex={false}
          muiPaginationProps={{ size: 'small' }}
          pageCount={pageCount}
          onPaginationChange={setPagination}
          // Action column related props
          enableColumnActions={false}
          enableRowActions={showActionColumn}
          positionActionsColumn="last"
          displayColumnDefOptions={{
            'mrt-row-actions': {
              header: '',
              size: 40,
              muiTableHeadCellProps: {
                sx: {
                  ...(alternateActionHeaderColumnColor
                    ? {
                        backgroundColor: '#4B4FA6',
                        color: 'white',
                        borderTopRightRadius: '16px',
                      }
                    : {}),
                },
              },
            },
          }}
          renderRowActions={({ row }) => (
            <Box>
              <IconButton
                onClick={() => {
                  if (onActionClick) onActionClick(row.original)
                }}>
                <KeyboardArrowRight className="text-ccp-purple80" />
              </IconButton>
            </Box>
          )}
          // Styling overrides related props
          muiTableHeadCellProps={{
            sx: {
              fontSize: '14px',
              fontFamily: 'DM Sans, sans-serif',
              fontWeight: '400',
              color: '#212124',
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              fontWeight: 'normal',
              fontFamily: 'DM Sans, sans-serif',
            },
          }}
          muiTablePaperProps={{
            elevation: 0,
          }}
        />
      </div>
    </>
  )
}

export default SharedTable
