import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Dialog from '@radix-ui/react-dialog'
import { ReactNode } from 'react'

interface PlainModalProps {
  children?: ReactNode
  isOpen?: boolean
  onOpenChange?: (isOpen: boolean) => void
  className?: string
}

const PlainModal = (props: PlainModalProps) => {
  const { children, isOpen, onOpenChange, className } = props
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-black/40 z-30 animate-overlay-popup" />
        <Dialog.Content
          className={`bg-white rounded-lg z-30 px-4 pb-4 pt-8 fixed top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] data-[state=open]:animate-modal-popup focus:outline-none shadow-2xl ${className}`}>
          <Dialog.Close asChild>
            <button
              className="hover:bg-ccp-hover focus:shadow-ccp-hover absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full focus:shadow-[0_0_0_2px] focus:outline-none"
              aria-label="Close">
              <FontAwesomeIcon icon={faClose} />
            </button>
          </Dialog.Close>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default PlainModal
