import { faCircleInfo, faListAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Card, Collapse, Divider, Grid, List, ListItem, Typography } from '@mui/material'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HttpGet } from '../../../../apis'
import { route } from '../../../../constants/routes'
import { AocTierState } from '../../../../redux/departmentRepresentative/aocSlice'
import { currencyFormatToCountry } from '../../../../utils/currencyHelper/formatToCountry'
import EditModal from './EditModal'

export const TierListItem = (props: { aoc: string, month: number, year: number, tiernumber: number, tierLvl: string, currency: string, amount: number, updatedBy: string, isLastTier?: boolean }) => {
  const { aoc, month, year, tiernumber, tierLvl, currency, amount = 0, updatedBy, isLastTier = false } = props
  const [open, setOpen] = useState<boolean>(false);

  const [history, setHistory] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loadHistory = async () => {
    setOpen(!open);
    if (!open) {
      setIsLoading(true);

      let params: any = {
        aoc: aoc,
        month: month,
        year: year,
      };

      const response = await HttpGet('/aoc/tiers', params);

      setIsLoading(false);

      setHistory(response.data.history);
    }
  }

  return (
    <>
      <Grid container sx={{ padding: 2 }}>
        <Grid item xs={5}>
          <Typography>{tierLvl}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>{currencyFormatToCountry(amount, currency)}</Typography>
        </Grid>
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box sx={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faCircleInfo} size="lg" onClick={() => loadHistory()} />
          </Box>
        </Grid>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
        <Divider />
        <Box sx={{ p: 2 }}>
          {
            isLoading
              ? <Typography style={{ textAlign: 'center' }}>Loading...</Typography>
              : history.length > 0
                ? < Grid container alignItems="flex-end">
                  <Grid item xs={5} lg={5}>
                    <Typography>Last Update: </Typography>
                  </Grid>
                  <Grid item xs={7} lg={7}>
                    <Typography>{format(new Date(history[0].updated_at._seconds * 1000), 'dd-MM-yyyy HH:mm')}</Typography>
                  </Grid>
                  <Grid item xs={5} lg={5}>
                    <Typography>Amount: </Typography>
                  </Grid>
                  <Grid item xs={7} lg={7}>
                    <Typography>{currencyFormatToCountry(history[0]["tier_" + tiernumber], currency)}</Typography>
                  </Grid>
                  <Grid item xs={5} lg={5}>
                    <Typography>ID: </Typography>
                  </Grid>
                  <Grid item xs={7} lg={7}>
                    <Typography>{updatedBy}</Typography>
                  </Grid>
                </Grid>
                : <Typography style={{ textAlign: 'center' }}>{!isLoading ? "No previous history" : ""}</Typography>
          }
        </Box>
      </Collapse>
      {isLastTier === false && <Divider />}
    </>
  )
}

const SaleTier = ({ data, showHistory = false, canEdit = false }: { data: Array<AocTierState>; showHistory?: boolean; canEdit?: boolean }) => {
  const navigate = useNavigate()

  const navigateToHistory = (aocType: string) => {
    navigate(route.AOC_HISTORY.replace(":aoc", aocType), {
      state: {
        aocType: aocType
      }
    })
  }

  return (
    <Box sx={useStyles.content}>
      {data.map((item, index) => (
        <Card sx={{ marginY: 2 }} key={index}>
          <List sx={{ width: '100%' }}>
            <ListItem sx={useStyles.listItem}>
              <Box display="flex" alignItems="baseline">
                <Typography>{item.aoc_type}</Typography>
                <Typography style={{ marginLeft: 5 }} sx={{ fontSize: 12, color: '#a8a8a8', fontWeight: 600 }}>( {item.month} / {item.year} )</Typography>
                {
                  showHistory &&
                  <Box sx={{ cursor: 'pointer', ml: 1.5 }}>
                    <FontAwesomeIcon icon={faListAlt} fontSize={25} onClick={() => navigateToHistory(item.aoc_type)} />
                  </Box>
                }
              </Box>

              {
                canEdit &&
                <Box sx={{ cursor: 'pointer' }}>
                  <EditModal details={item} isHistory={!showHistory} />
                </Box>
              }
            </ListItem>
            <Divider sx={{ marginY: 1 }} />

            <TierListItem aoc={item.aoc_type} month={item.month} year={item.year} amount={item.tier_3} tiernumber={3} updatedBy={item.updated_by} tierLvl="Tier III" currency={item.currency} />
            <TierListItem aoc={item.aoc_type} month={item.month} year={item.year} amount={item.tier_2} tiernumber={2} updatedBy={item.updated_by} tierLvl="Tier II" currency={item.currency} />
            <TierListItem aoc={item.aoc_type} month={item.month} year={item.year} amount={item.tier_1} tiernumber={1} updatedBy={item.updated_by} tierLvl="Tier I" currency={item.currency} isLastTier={true} />
          </List>
        </Card>
      ))}
    </Box>
  )
}

const useStyles = {
  content: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  listItem: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    height: 30,
    paddingY: 3,
  },
}

export default SaleTier
