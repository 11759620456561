import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Popover from '@radix-ui/react-popover'
import * as Dialog from '@radix-ui/react-dialog'
import { format } from 'date-fns'
import { ReactNode } from 'react'
import {
  CaptionProps,
  DayPicker as DP,
  DayContentProps,
  DayPickerDefaultProps,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
  useNavigation,
  useSelectRange,
  useDayPicker,
} from 'react-day-picker'
import Button from 'components/Button'

interface DayPickerProps {
  isMobile?: boolean
  children: ReactNode
  onOpenChange?: (willOpen: boolean) => void
  isOpen?: boolean
  triggerClassName?: string
  onConfirmClick?: () => void
  confirmBtnDisabled?: boolean
}

type InterDayPickerProps = DayPickerProps &
  (DayPickerDefaultProps | DayPickerSingleProps | DayPickerMultipleProps | DayPickerRangeProps)

const DayPicker = (props: InterDayPickerProps) => {
  const {
    isMobile,
    children,
    onOpenChange,
    isOpen,
    triggerClassName = '',
    onConfirmClick,
    confirmBtnDisabled = false,
    ...dayPickerProps
  } = props

  if (isMobile) {
    return (
      <Dialog.Root onOpenChange={onOpenChange} open={isOpen}>
        <Dialog.Trigger className={triggerClassName}>{children}</Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-black/40 z-30 animate-overlay-popup" />
          <Dialog.Content className="bg-white rounded-lg z-30 p-4 fixed top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] data-[state=open]:animate-modal-popup focus:outline-none shadow-2xl">
            <div className="flex flex-col items-center mt-4">
              <DP {...dayPickerProps} weekStartsOn={1} components={{ DayContent, Head, Caption }} />
              <div className="flex flex-row justify-end mt-2 self-stretch">
                <Dialog.Close asChild>
                  <Button borderless>Cancel</Button>
                </Dialog.Close>
                <Button borderless className="ml-4" onClick={onConfirmClick} disabled={confirmBtnDisabled}>
                  Set
                </Button>
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    )
  }

  return (
    <Popover.Root onOpenChange={onOpenChange} open={isOpen}>
      <Popover.Trigger className={triggerClassName}>{children}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className="p-4 border border-gray-100 rounded shadow-lg bg-white z-20">
          <div>
            <DP {...dayPickerProps} weekStartsOn={1} components={{ DayContent, Head, Caption }} />
            <div className="flex flex-row justify-end mt-2">
              <Popover.Close asChild>
                <Button borderless>Cancel</Button>
              </Popover.Close>
              <Button borderless className="ml-4" onClick={onConfirmClick} disabled={confirmBtnDisabled}>
                Set
              </Button>
            </div>
          </div>
          <Popover.Arrow className="fill-white" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

/**
 * ---- Custom DayPicker components  -------------------------------------------
 */

const DayContent = ({ date, activeModifiers }: DayContentProps) => {
  return (
    <span
      className={[
        'h-8 w-8 flex items-center justify-center',
        ...(activeModifiers?.disabled
          ? ['bg-gray-100 text-gray-200']
          : [
              !activeModifiers?.selected ? 'hover:bg-slate-100 rounded' : '',
              activeModifiers?.range_start ? 'bg-ccp-primary text-white rounded-l' : '',
              activeModifiers?.range_end ? 'bg-ccp-primary text-white rounded-r' : '',
              activeModifiers?.range_middle ? 'bg-purple-100' : '',
            ]),
      ]
        .filter(Boolean)
        .join(' ')}>
      {date.getDate()}
    </span>
  )
}

const Head = () => (
  <thead>
    <tr>
      {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
        <th key={day}>
          <span className="h-6 w-6 text-xs text-gray-400">{day}</span>
        </th>
      ))}
    </tr>
  </thead>
)

const Caption = ({ displayMonth }: CaptionProps) => {
  const { mode } = useDayPicker()
  const { goToMonth, previousMonth, nextMonth } = useNavigation()

  const formatMonth = (date: Date) => format(date, 'MMMM')

  return (
    <div>
      {mode === 'range' && <Caption.Range />}
      <div className="flex flex-row items-center justify-between p-4">
        <button
          className={`h-6 w-6 rounded-full hover:bg-slate-100`}
          onClick={() => previousMonth && goToMonth(previousMonth)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span>
          {formatMonth(displayMonth)}, {displayMonth?.getFullYear()}
        </span>
        <button className={`h-6 w-6 rounded-full hover:bg-slate-100`} onClick={() => nextMonth && goToMonth(nextMonth)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  )
}

const RangeSubComponent = () => {
  const { selected } = useSelectRange()
  const formatDate = (date: Date) => format(date, 'dd/MM/yyyy')

  return (
    <div className="flex flex-row justify-around border-b pb-2">
      {selected?.from ? (
        <span className="border-b border-white">{formatDate(selected.from)}</span>
      ) : (
        <span className="text-italic text-ccp-primary border-b border-ccp-primary">Start date</span>
      )}
      <span> - </span>
      {selected?.to ? (
        <span className="border-b border-white">{formatDate(selected.to)}</span>
      ) : (
        <span
          className={`text-italic ${
            selected?.from ? 'text-ccp-primary border-b border-ccp-primary' : 'text-gray-400'
          }`}>
          End date
        </span>
      )}
    </div>
  )
}
Caption.Range = RangeSubComponent

export default DayPicker
