import { SvgIcon } from 'components/SvgIcon'
import { isNil } from 'lodash'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { navItems } from './navItems'

export const MobileNavBar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const isPathMatches = (url: string | undefined) => !isNil(url && matchPath(url, location.pathname))

  const handleClick = (url: string | undefined) => () => {
    if (url) navigate(url)
  }

  return (
    <div
      className="h-16 bg-white flex flex-row shrink-0 rounded-t-xl w-full border-t"
      style={{ boxShadow: '0 -4px 4px 0 rgba(0,0,0,0.08)' }}>
      {navItems.map((item, idx) => (
        <button
          key={`btn-${idx}`}
          type="button"
          onClick={handleClick(item.url)}
          className="grow flex flex-col items-center justify-center">
          <SvgIcon
            icon={item.icon ?? ''}
            className={`${isPathMatches(item.url) ? 'text-ccp-primary' : 'text-ccp-grey90'}`}
          />
          <span className={`${isPathMatches(item.url) ? 'text-ccp-primary' : 'text-ccp-grey90'}`}>{item.label}</span>
        </button>
      ))}
    </div>
  )
}
