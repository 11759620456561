import { MainContainer } from 'components/Container'
import AocTabs from 'pages/components/AocTabs'
import HeaderToolbar from 'pages/home/components/HeaderToolbar'
import { useHeaderDateRangeHelper } from 'pages/home/utils'
import { useResponsiveCheck } from 'utils/hooks'
import RouteSpecificTarget from './RouteSpecificTarget'

interface RouteSpecificTargetPageProps {}

const RouteSpecificTargetPage = (props: RouteSpecificTargetPageProps) => {
  const {} = props
  const { isDesktop } = useResponsiveCheck()
  const { selectedDateRange, setSelectedDateRange } = useHeaderDateRangeHelper()

  return (
    <MainContainer
      contentHeaderBar={
        isDesktop ? (
          <div className="bg-white shadow sticky top-0 left-0 w-full z-10 mb-2">
            <div className="h-14 w-full max-w-[1280px] px-4 mx-auto flex flex-row">
              <div className="grow flex flex-row">
                <AocTabs />
              </div>
              <HeaderToolbar className="shrink-0" isDesktop={isDesktop} />
            </div>
          </div>
        ) : null
      }>
      <div className="my-4">
        <span className="text-ccp-primary font-bold">Route specific target</span>{' '}
      </div>
      <RouteSpecificTarget />
    </MainContainer>
  )
}

export default RouteSpecificTargetPage
