import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../apis'

export const getSalesTier = createAsyncThunk('executive/getSalesTier', async (params: any, { rejectWithValue }) => {
  try {
    const response = await HttpGet('aoc/tiers', {
      aoc: params.aoc,
    })
    return response.data
  } catch (error: any) {
    console.log('get  all executive crew details error: ', error)

    if (error?.response?.data) {
      return rejectWithValue(error.response.data)
    }
  }
})
