const productCategory = (prdName: string | undefined) => {
  switch (prdName?.toLowerCase()) {
    case 'perishable':
      return 'PER'
    case 'non-perishable':
      return 'NPR'
    case 'merchandise':
      return 'MCD'
    case 'duty free':
      return 'DTF'
    default:
      return '-'
  }
}

export default productCategory
