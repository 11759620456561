import { createSlice } from '@reduxjs/toolkit'
import { Pagination } from 'components/Pagination'
import { addDays, format, parseISO, startOfMonth } from 'date-fns'
import { getMonthlySummary } from './departmentRepresentative/thunk/getMontlySummary'
import { getFlightFilterList } from './thunk/getFlightFilterList'
import { FlightPairingTransaction, getFlightPairingsList } from './thunk/getFlightPairingList'
import { getFlightSummary } from './thunk/getFlightSummary'
import { getFlightSummaryDetails } from './thunk/getFlightSummaryDetails'
import { DownloadReportParams, getCrewReports } from './thunk/getCrewReports'

export interface TransactionDetails {
  flight_number?: string
  date: string
  product_category?: string
  product_code?: string
  product?: string
  currency?: string
  number_of_crews?: number
  commission: number
  quantity: number
  net_amount: number
  unit_price: number
}

export interface FlightSummary {
  flight_number: string | number
  sales: number
  commissions: number
  date: string
  transactions_count: number
  transactions: Array<TransactionDetails> | []
}

export interface TransactionState {
  flightSummary: Array<any>
  flightSummaryTransactions: Array<any>
  totalCommission: number
  totalSales: number
  totalCrewMember: number
  dateFilterFrom: Date | string
  dateFilterTo: Date | string
  count: number
  flightSummaryIsLoading: boolean
  erorrMsg: string | null
  flightDetailsIsLoading: boolean
  flightDetailsError: string | null
  flightFilterList: Array<string>
  flightFilterListIsLoading: boolean
  dateFilterFromTransactionPage: Date | string
  dateFilterToTransactionPage: Date | string
  flightPairings: Array<FlightPairingTransaction>
  flightPairingsPagination: Pagination
  flightPairingsListIsLoading: boolean
  downloadingReports: boolean
  downloadReportsFilter: DownloadReportParams
}

const firstDayOfMonth = format(startOfMonth(new Date()), 'yyyy-MM-dd')

const initialState: TransactionState = {
  flightSummary: [],
  flightSummaryTransactions: [],
  totalCommission: 0,
  totalSales: 0,
  totalCrewMember: 0,
  dateFilterFrom: format(new Date(firstDayOfMonth.replace(/-/g, '/')), 'yyyy-MM-dd'),
  dateFilterTo: format(new Date(), 'yyyy-MM-dd'),
  dateFilterFromTransactionPage: format(new Date(firstDayOfMonth.replace(/-/g, '/')), 'yyyy-MM-dd'),
  dateFilterToTransactionPage: format(new Date(), 'yyyy-MM-dd'),
  count: 0,
  flightSummaryIsLoading: false,
  flightDetailsIsLoading: false,
  erorrMsg: null,
  flightDetailsError: null,
  flightFilterList: [],
  flightFilterListIsLoading: false,
  flightPairings: [],
  flightPairingsPagination: {
    current_page: 1,
    page_size: 5,
  },
  flightPairingsListIsLoading: false,
  downloadingReports: false,
  downloadReportsFilter: {
    crew_id: '',
    from_date: format(new Date(firstDayOfMonth.replace(/-/g, '/')), 'yyyy-MM-dd'),
    to_date: format(new Date(), 'yyyy-MM-dd'),
  },
}

export const transactions = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setFlightSummary: (state, action) => {
      state.flightSummary = action.payload.flightSummary
      state.flightSummaryTransactions = action.payload.flightSummary
      state.count = action.payload.count
    },
    setTotalSalesNCommission: (state, action) => {
      state.totalCommission = action.payload.totalCommission
      state.totalSales = action.payload.totalSales
    },
    setDateFiltered: (state, action) => {
      state.dateFilterFrom = action.payload.dateFrom
      state.dateFilterTo = action.payload.dateTo
    },
    setTransactionLoading: (state, action) => {
      state.flightSummaryIsLoading = action.payload
    },
    setDownloadFilter: (state, action) => {
      state.downloadReportsFilter = action.payload
    },
    resetTransctionsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getFlightSummary.pending, (state) => {
      state.flightSummaryIsLoading = true
    })
    builder.addCase(getFlightSummary.fulfilled, (state, action: any) => {
      const responseData = action.payload.data

      // console.log('responseData: ', responseData)

      try {
        state.count = responseData.count
        state.flightSummaryTransactions = responseData.count > 0 ? responseData.data : []
        state.dateFilterFromTransactionPage = format(parseISO(responseData.date_min), 'yyyy-MM-dd')
        // Note: need to use parseISO and addDays to get the correct date. It will goto next day due to API return 23:59:59
        state.dateFilterToTransactionPage = format(addDays(parseISO(responseData.date_max), -1), 'yyyy-MM-dd')

        if (action.payload.updateCommissionNsale) {
          state.flightSummary = responseData.count > 0 ? responseData.data : []
          state.totalCommission = responseData.total_commissions
          state.totalSales = responseData.total_sales
          state.dateFilterFrom = format(parseISO(responseData.date_min), 'yyyy-MM-dd')
          state.dateFilterTo = format(parseISO(responseData.date_max), 'yyyy-MM-dd')
          state.erorrMsg = null
        } else {
          state.flightDetailsError = null
        }
      } catch (error) {
        console.error(error)
      }

      state.flightSummaryIsLoading = false
    })
    builder.addCase(getFlightSummary.rejected, (state, action: any) => {
      console.log('getFlightSummary rejected: ', action)
      state.flightSummaryIsLoading = false
      state.erorrMsg = 'There is an error, please try again in a moment'
    })
    builder.addCase(getFlightSummaryDetails.pending, (state) => {
      state.flightDetailsIsLoading = true
    })
    builder.addCase(getFlightSummaryDetails.fulfilled, (state, action: any) => {
      try {
        const transactions = action.payload.transactions
        const firstRecord = transactions[0]

        const flightIndex = state.flightSummaryTransactions.findIndex((item) => {
          return item.flight_number === firstRecord.flight_number && item.date === firstRecord.date
        })

        state.flightSummaryTransactions[flightIndex] = { ...firstRecord }
      } catch (error) {
        console.error(error)
      }

      state.flightDetailsIsLoading = false
    })
    builder.addCase(getFlightSummaryDetails.rejected, (state, action: any) => {
      state.flightDetailsIsLoading = false
    })
    builder.addCase(getFlightFilterList.pending, (state) => {
      state.flightFilterListIsLoading = true
    })
    builder.addCase(getFlightFilterList.fulfilled, (state, action) => {
      state.flightFilterList = action.payload.data || []
      state.flightFilterListIsLoading = false
    })
    builder.addCase(getFlightFilterList.rejected, (state) => {
      state.flightFilterListIsLoading = false
    })
    builder.addCase(getFlightPairingsList.pending, (state) => {
      state.flightPairingsListIsLoading = true
    })
    builder.addCase(getFlightPairingsList.fulfilled, (state, action) => {
      state.flightPairingsListIsLoading = false
      const responseData = action.payload

      try {
        state.flightPairings = responseData?.data ?? []
        state.flightPairingsPagination = {
          ...state.flightPairingsPagination,
          total_page: responseData?.total_page,
        }
      } catch (error) {
        console.error('Error reducing flightPairings: ', error)
      }
    })
    builder.addCase(getFlightPairingsList.rejected, (state) => {
      state.flightPairingsListIsLoading = false
    })
    builder.addCase(getMonthlySummary.pending, (state) => {
      // handle api on load
    })
    builder.addCase(getMonthlySummary.fulfilled, (state, action) => {
      const responseData = action.payload

      state.totalSales = responseData?.total_sales ?? 0
      state.totalCommission = responseData?.total_commission ?? 0
      state.totalCrewMember = responseData?.total_crews ?? 0
    })
    builder.addCase(getMonthlySummary.rejected, (state) => {
      state.totalSales = 0
      state.totalCommission = 0
      state.totalCrewMember = 0
    })

    // download reports
    builder.addCase(getCrewReports.pending, (state, action) => {
      state.downloadingReports = true
    })
    builder.addCase(getCrewReports.fulfilled, (state, action) => {
      console.log(action.payload)
      const blob = action.payload.blob
      const fileName = action.payload.fileName

      const blobUrl = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = blobUrl
      a.download = fileName

      document.body.appendChild(a)

      a.click()

      document.body.removeChild(a)

      URL.revokeObjectURL(blobUrl)
      state.downloadingReports = false
    })
    builder.addCase(getCrewReports.rejected, (state, action) => {
      state.downloadingReports = false
    })
  },
})

export const {
  setFlightSummary,
  setTotalSalesNCommission,
  setDateFiltered,
  setTransactionLoading,
  resetTransctionsState,
  setDownloadFilter,
} = transactions.actions

export default transactions.reducer
