import { keys } from 'lodash'
import Card from '.'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/configureStore'
import { AocState } from 'redux/departmentRepresentative/aocSlice'

interface Transactions {
  santan: number
  mcds: number
  dtf: number
  domlux: number
}

interface FlightPairingCardData {
  id: string
  flightNoPair: string
  date: string
  sales: Transactions
  earnings: Transactions
  currency: string
}

interface FlightPairingCardProps {
  className?: string
  onClick?: () => void
  data?: FlightPairingCardData
  isMain?: boolean
  isLoading?: boolean
}

const FlightPairingCard = (props: FlightPairingCardProps) => {
  const { className, onClick, data, isMain = false, isLoading = false } = props

  const aocState = useSelector<RootState, AocState>((state) => state.aoc)
  const currency = data?.currency ?? aocState.aocCurrency

  const labelMapping: { [key: string]: string } = {
    santan: 'Santan',
    domlux: 'Domlux',
    mcds: 'MCDS',
    dtf: 'DTF',
  }

  const valuePair = (key: string, total: number) => (
    <div key={`key-${key}`} className="mt-4">
      <div className="text-sm mt-2">{labelMapping[key]}</div>
      <div className={`font-bold ${isLoading ? 'skeleton w-5' : ''}`}>
        {currency} {total.toFixed(2)}
      </div>
    </div>
  )

  return (
    <Card className={`mbt-2 flex flex-col ${className}`} onClick={onClick} color={isMain ? 'primary' : 'white'}>
      <span className={`mt-2 ${isLoading ? 'skeleton w-20' : ''}`}>{isLoading ? '' : 'Flight pairing'}</span>
      <span className={`mt-2 font-bold ${isLoading ? 'skeleton w-32' : ''}`}>{data?.flightNoPair}</span>
      <span className={`mt-2 ${isLoading ? 'skeleton w-9' : ''}`}>{isLoading ? '' : 'Date'}</span>
      <span className={`mt-2 font-bold ${isLoading ? 'skeleton w-14' : ''}`}>
        {data && format(new Date(data.date), 'MMMM do')}
      </span>
      <div className="border-b-2 mt-2 mb-2" />
      <div className="flex flex-row">
        {/* Sales column */}
        <div className="w-6/12">
          <span className={`text-purple-400 ${isLoading ? 'skeleton w-7' : ''}`}>{isLoading ? '' : 'Sales'}</span>
          {data && Object.entries(data.sales).map(([key, value]) => valuePair(key, value))}
        </div>

        {/* Eearnings column */}
        <div className="w-6/12">
          <span className={`text-red-400 ${isLoading ? 'skeleton w-16' : ''}`}>{isLoading ? '' : 'Commissions'}</span>
          {data && Object.entries(data.earnings).map(([key, value]) => valuePair(key, value))}
        </div>
      </div>
    </Card>
  )
}

export default FlightPairingCard
