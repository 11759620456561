import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { route, routeAuth } from '../constants/routes'
import Login from './auth/Login'

import CrewList from './crew-list/CrewList'
import CrewTransactions from './crew-transactions/Transactions'
import Home from './home/Home'
import Transactions from './transactions/Transactions'

import { Box, CircularProgress } from '@mui/material'
import * as firebase from 'firebase/auth'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { MetaFilterState, setAoc, setHub } from 'redux/departmentRepresentative/metafilters'
import { MainContainer } from '../components/Container'
import { appConfig } from '../config/appConfig'
import { adminRoles, roles } from '../constants/roles'
import { RootState, store } from '../redux/configureStore'
import { getUserAndAocDetails } from '../redux/thunk/getUserAndAocDetails'
import { UserState, setAuthLoading, setLoggedIn, setUser } from '../redux/user'
import AocHistory from './aoc-history/History'
import DownloadingPage from './downloading/DownloadingPage'
import FlightPairingDetails from './home/crew/FlightPairingDetails'
import FlightPairing from './home/crew/FlightPairings'
import RedirectPage from './redirect/RedirectPage'
import RouteSpecificTargetPage from './route-target'
import TierBreakdown from './tier-ranking/TierBreakdown'
import TierDetails from './tier-ranking/TierDetails'
import TierRanking from './tier-ranking/TierRanking'

/**
 * Main
 * <Routes></Routes> replacement of <Switch></Switch> on new version of react router
 * path specifies a pathname we assign to our component
 * element It refers to the component which will render on matching the path
 */

const Main = (): React.ReactElement => {
  const wrapper = React.createRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userState = useSelector<RootState, UserState>((state) => state.user)
  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const [isLogin, setIsLogin] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [callBackMessage, setCallBackMessage] = useState<string>('')

  const onLoginCallback = useCallback(
    async (user: firebase.User | null) => {
      // console.log('user: ', user)

      if (user) {
        if (appConfig.enable_test_user_dropdown) {
          if (store.getState().user.user.email === '' || store.getState().user.user.email === '-') {
            await Promise.resolve(
              dispatch(
                getUserAndAocDetails({
                  email: user.email,
                }),
              ),
            ).then(async (action: any) => {
              if (action.payload.error) {
                dispatch(setLoggedIn(false))
                setCallBackMessage('User not found!')
                setIsLogin(false)
                // console.clear();
                await firebase.getAuth().signOut()
                localStorage.clear()
              } else {
                setIsLogin(true)
                dispatch(
                  setUser({
                    name: user.displayName || '-',
                    email: user.email || '-',
                  }),
                )
                dispatch(setAoc(action.payload.extraDetails?.aoc || ''))
                if (
                  action.payload.extraDetails?.role === roles.crew ||
                  action.payload.extraDetails?.role === roles.executive
                ) {
                  dispatch(setHub(action.payload.extraDetails?.hub || ''))
                }
              }
              dispatch(setAuthLoading(false))
              setIsLoading(false)
            })
          } else {
            setIsLogin(true)
            dispatch(setAuthLoading(false))
            setIsLoading(false)
          }
        } else {
          await Promise.resolve(
            dispatch(
              getUserAndAocDetails({
                email: user.email,
              }),
            ),
          ).then(async (action: any) => {
            if (action.payload.error) {
              dispatch(setLoggedIn(false))
              setCallBackMessage('User not found!')
              setIsLogin(false)
              // console.clear();
              await firebase.getAuth().signOut()
              localStorage.clear()
            } else {
              setIsLogin(true)
              dispatch(
                setUser({
                  name: user.displayName || '-',
                  email: user.email || '-',
                }),
              )
              dispatch(setAoc(action.payload.extraDetails?.aoc || ''))
              if (
                action.payload.extraDetails?.role === roles.crew ||
                action.payload.extraDetails?.role === roles.executive
              ) {
                dispatch(setHub(action.payload.extraDetails?.hub || ''))
              }
            }
            dispatch(setAuthLoading(false))
            setIsLoading(false)
          })
        }
      } else {
        navigate(route.HOME)
        setIsLogin(false)
        setIsLoading(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch, navigate],
  )

  const onChangeError = (error: any) => {
    console.log('error | on Login: ', error)
    setIsLogin(false)
    setIsLoading(false)
  }

  useEffect(() => {
    if (metaFilterState.aoc === '' && userState.extraDetails.aoc !== '') {
      dispatch(setAoc(userState.extraDetails.aoc))
    }
    if (metaFilterState.hub === '' && userState.extraDetails.hub !== '') {
      if (userState.extraDetails.role === roles.departementRepresentative) {
        dispatch(setHub('')) // Set hub filter to 'All' if the user is dept rep
      } else {
        dispatch(setHub(userState.extraDetails.hub))
      }
    }
  }, [userState.extraDetails.aoc, userState.extraDetails.hub])

  useEffect(() => {
    const subscribe = firebase.getAuth().onAuthStateChanged(onLoginCallback, onChangeError)
    return subscribe
  }, [onLoginCallback])

  return (
    <div className="App" ref={wrapper as any}>
      <ToastContainer />
      {isLoading ? (
        <MainContainer backBtn={false} sideMenu={false}>
          <Box
            sx={{
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <CircularProgress size={32} />
          </Box>
        </MainContainer>
      ) : (
        <Routes>
          {!isLogin ? (
            <Route path={routeAuth.ROOT} element={<Login message={callBackMessage} />} />
          ) : (
            <>
              <Route path={route.HOME} element={<Home />}>
                <Route path={route.HOME} element={<FlightPairing />} />
                <Route path={route.FLIGHT_DETAILS} element={<FlightPairingDetails />} />
                <Route path={route.TIERS} element={<TierRanking />} />
              </Route>
              <Route path={route.DOWNLOADING} element={<DownloadingPage />} />
              {(userState.extraDetails.role === undefined || userState.extraDetails.role === roles.crew) && (
                <Route path={route.TRANSACTIONS} element={<Transactions />} />
              )}
              {(userState.extraDetails.role === undefined ||
                userState.extraDetails.role === roles.departementRepresentative) && (
                <Route path={route.TIER_DETAILS} element={<TierDetails />} />
              )}
              {(userState.extraDetails.role === undefined ||
                userState.extraDetails.role === roles.departementRepresentative) && (
                <Route path={route.TIER_BREAKDOWN} element={<TierBreakdown />} />
              )}
              {userState.extraDetails.role !== undefined && adminRoles.includes(userState.extraDetails.role) && (
                <Route path={route.ROUTE_SPEC_TARGET} element={<RouteSpecificTargetPage />} />
              )}
              {userState.extraDetails.role !== undefined &&
                (adminRoles.includes(userState.extraDetails.role) ||
                  userState.extraDetails.role === roles.executive) && (
                  <Route path={route.CREWS} element={<CrewList />} />
                )}
              {userState.extraDetails.role !== undefined && adminRoles.includes(userState.extraDetails.role) && (
                <Route path={route.AOC_HISTORY} element={<AocHistory />} />
              )}
              {userState.extraDetails.role !== undefined && userState.extraDetails.role !== roles.crew && (
                <Route element={<CrewTransactions />}>
                  <Route path={route.CREWS_TRANSACTIONS} element={<FlightPairing />} />
                  <Route path={route.CREWS_SUB_TRANSACTIONS} element={<FlightPairingDetails />} />
                  <Route path={route.CREWS_TIERS} element={<TierRanking />} />
                </Route>
              )}
              <Route path="*" element={<RedirectPage />} />
            </>
          )}
        </Routes>
      )}
    </div>
  )
}

export default Main
