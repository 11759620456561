import TierBreakdownCard from "components/Card/TierBreakdownCard";
import { MainContainer } from "components/Container";

const TierBreakdown = () => {

    const data = [
        { id: 1, product: 'Pak Nasser’s Nasi Lemak', flight: 'AK1234', Category: 'Perishable', qty: 1, sales: 500, commission: 500 },
        { id: 1, product: 'Pak Nasser’s Nasi Lemak', flight: 'AK1234', Category: 'Perishable', qty: 1, sales: 500, commission: 500 },
        { id: 1, product: 'Pak Nasser’s Nasi Lemak', flight: 'AK1234', Category: 'Perishable', qty: 1, sales: 500, commission: 500 },
    ];

    return (
        <>
            <MainContainer>
                <div className="grid grid-cols-3">
                    {data.map((item, index) => (
                        <TierBreakdownCard />
                    ))}
                </div>
            </MainContainer>
        </>
    )
}

export default TierBreakdown;