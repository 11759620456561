import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../apis'

interface FlightSummaryParams {
  aoc?: string,
  crew_id?: string
  flight_pair?: string,
  sales_tier?: string,
  crew_base?: string,
  from_date?: Date | string
  to_date?: Date | string
  product_category?: string
  updateCommissionNsales?: boolean
}

// First, create the thunk
export const getFlightSummary = createAsyncThunk(
  'transactions/getFlightSummary',
  async (params: FlightSummaryParams, { rejectWithValue }) => {
    // Note: set a default value
    const { updateCommissionNsales = true } = params

    try {
      const response = await HttpGet('crew/flights/summary', params);

      return {
        data: response.data,
        updateCommissionNsale: updateCommissionNsales,
      }
    } catch (error: any) {
      console.log('flight summary err: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
