import { faChartLine, faPlaneUp, faUserAstronaut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale } from 'chart.js'
import SharedTable from 'components/Table/SharedTable'
import { adminRoles } from 'constants/roles'
import { MRT_Cell, MRT_ColumnDef } from 'material-react-table'
import AdminSummaryCard from 'pages/components/AdminSummaryCard'
import AocTabs from 'pages/components/AocTabs'
import RouteSpecificTarget from 'pages/route-target/RouteSpecificTarget'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CrewListState } from 'redux/departmentRepresentative/crewSlice'
import { MetaFilterState } from 'redux/departmentRepresentative/metafilters'
import { getCrewStatistics } from 'redux/departmentRepresentative/thunk/getCrewStatistics'
import { UserState } from 'redux/user'
import { useResponsiveCheck } from 'utils/hooks'
import { MainContainer } from '../../../components/Container'
import { route } from '../../../constants/routes'
import { RootState } from '../../../redux/configureStore'
import HeaderToolbar from '../components/HeaderToolbar'
import { setDownloadFilter } from 'redux/transactions'
import { SvgIcon } from 'components/SvgIcon'

ChartJS.register(CategoryScale, LinearScale, BarElement)

const Home: React.FC = (props: any): React.ReactElement => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { statistics: crewStatData, statisticsIsLoading } = useSelector<RootState, CrewListState>(
    (state) => state.crewList,
  )
  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const isAdmin = adminRoles.includes(userState.extraDetails.role)
  const { isDesktop } = useResponsiveCheck()

  useEffect(() => {

    const aoc = isAdmin ? metaFilterState.aoc : userState?.aocDetails?.aoc_type;

    dispatch(
      getCrewStatistics({
        start_date: metaFilterState.dateFrom,
        end_date: metaFilterState.dateTo,
        aoc,
        hub: metaFilterState.hub,
      }),
    )
    dispatch(
      setDownloadFilter({
        from_date: metaFilterState.dateFrom,
        to_date: metaFilterState.dateTo,
        aoc,
        hub: metaFilterState.hub,
      }),
    )
  }, [metaFilterState.aoc, metaFilterState.hub, metaFilterState.currentMonth, metaFilterState.currentYear])

  const tierTableData = useMemo(() => {
    if (!crewStatData) return []

    const mappedData = Object.entries(crewStatData ?? {}).map(([key, value]: [key: string, value: any]) => ({
      tier: key,
      ...value,
    }))

    return mappedData
  }, [crewStatData])

  const tierTableColumns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'tier',
        header: '',
        Header: () => <span className="text-sm text-ccp-primary">Tier</span>,
        Cell: ({ cell }) => (
          <div className="w-6 h-6 rounded-full bg-ccp-purple90 text-white flex items-center justify-center">
            {cell.getValue<string>()}
          </div>
        ),
      },
      {
        accessorKey: 'aoc',
        header: '',
        Header: () => <>AOC</>,
      },
      {
        accessorKey: 'hub',
        header: '',
        Header: () => (
          <>
            <SvgIcon icon={'Plane'} className="text-red-500 mr-2" />
            <span className="">HUB</span>
          </>
        ),
      },
      {
        accessorKey: 'crew_count',
        header: '',
        Header: () => (
          <>
            <SvgIcon icon={'CabinCrew'} className="text-red-500 mr-2" />
            <span>Crew Count</span>
          </>
        ),
        Cell: ({ cell }) => Math.max(cell.getValue<number>() ?? 0, 0), // Prevent negative values
      },
      {
        accessorKey: 'headcountPercent',
        header: '',
        Header: () => (
          <>
            <SvgIcon icon={'LineChartStonks'} className="text-red-500 mr-2" />
            <span>From overall headcount</span>
          </>
        ),
        Cell: ({ cell }) => `${cell.getValue<number>()?.toFixed(0) ?? '0'} %`,
      },
    ],
    [],
  )

  const goTo = (url: string) => () => {
    navigate(url)
  }

  const goToTransactions = (row: any) => {
    console.log('[debug] action click', row) //TODO: remove this later
    navigate(route.TIER_DETAILS + `?tier=${row.tier}`)
  }

  return (
    <MainContainer
      contentHeaderBar={
        isDesktop ? (
          <div className="bg-white">
            <div className="bg-white shadow sticky top-0 left-0 w-full z-10 mb-2">
              <div className="h-14 w-full max-w-[1280px] px-4 mx-auto flex flex-row">
                <div className="grow flex flex-row">{isAdmin ? <AocTabs /> : null}</div>
                <HeaderToolbar className="shrink-0" isDesktop={isDesktop} />
              </div>
            </div>

            <div className="max-w-[1280px] mx-auto px-4 pb-4">
              <AdminSummaryCard />
            </div>
          </div>
        ) : null
      }>
      <div className="mt-4">
        <span className="text-ccp-primary font-bold">Tier details</span>{' '}
        <button onClick={goTo(route.TIER_DETAILS)} className="text-black ml-2 underline">
          View all
        </button>
      </div>

      <div className="mt-4">
        <SharedTable
          columns={tierTableColumns}
          data={tierTableData}
          disablePagination
          pagination={{
            pageIndex: 0,
            pageSize: 4,
          }}
          isLoading={statisticsIsLoading}
          showActionColumn
          onActionClick={goToTransactions}
        />
      </div>

      <div className="border-b my-4" />

      <div className="mt-4">
        <span className="text-ccp-primary font-bold">Route specific target</span>{' '}
        <button onClick={goTo(route.ROUTE_SPEC_TARGET)} className="text-black ml-2 underline">
          View all
        </button>
      </div>

      <div className="mt-4">
        <RouteSpecificTarget />
      </div>
    </MainContainer>
  )
}

export default Home
