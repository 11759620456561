export const countryCurrencyFormat: any = {
  MYR: {
    localeCode: 'en-MY',
    currencyCode: 'MYR',
  },
  PHP: {
    localeCode: 'en-PH',
    currencyCode: 'PHP',
  },
  IDR: {
    localeCode: 'id-ID',
    currencyCode: 'IDR',
  },
  INR: {
    localeCode: 'id-ID',
    currencyCode: 'IDR',
  },
  THB: {
    localeCode: 'th-TH',
    currencyCode: 'THB',
  },
  AK: {
    localeCode: 'en-MY',
    currencyCode: 'MYR',
  },
  FD: {
    localeCode: 'th-TH',
    currencyCode: 'THB',
  },
  Z2: {
    localeCode: 'en-PH',
    currencyCode: 'PHP',
  },
  QZ: {
    localeCode: 'id-ID',
    currencyCode: 'IDR',
  },
  D7: {
    localeCode: 'en-MY',
    currencyCode: 'MYR',
  },
  XJ: {
    localeCode: 'th-TH',
    currencyCode: 'THB',
  },
}

export const currencyFormatToCountry = (value: number, currencyOrAoc: string = 'USD') => {
  // Note: default value if not found in list
  let localeCode = countryCurrencyFormat[currencyOrAoc.toUpperCase()]

  if (!localeCode) {
    return `${value}`
  }

  const formater = new Intl.NumberFormat(localeCode.localeCode, {
    style: 'currency',
    currency: localeCode.currencyCode,
  })

  const convertedValue = formater.format(value).replace(/^(\D+)/, '$1 ')

  // Note: this is to make sure the sign and amount have a space between them
  const splittedValue = convertedValue.split(' ')
  const sign = splittedValue[0].trim()
  const amount = splittedValue[1]

  return `${sign} ${amount}`
}

export const currencyFormatToCountryObj = (value: number, currencyOrAoc: string = 'USD') => {
  // Note: default value if not found in list
  let localeCode = countryCurrencyFormat[currencyOrAoc.toUpperCase()]

  if (!localeCode) {
    return {
      sign: '-',
      amount: value,
    }
  }

  const formater = new Intl.NumberFormat(localeCode.localeCode, {
    style: 'currency',
    currency: currencyOrAoc,
  })

  const convertedValue = formater.format(value).replace(/^(\D+)/, '$1 ')

  console.log('convertedValue', convertedValue)

  // Note: this is to make sure the sign and amount have a space between them
  const splittedValue = convertedValue.split(' ')
  const sign = splittedValue[0].trim()
  const amount = splittedValue[1]

  return {
    sign: sign,
    amount: amount,
  }
}
