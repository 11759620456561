export const months = [
  { key: 1, label: 'January ' + new Date().getFullYear(), value: 'January ' + new Date().getFullYear() },
  { key: 2, label: 'February ' + new Date().getFullYear(), value: 'February ' + new Date().getFullYear() },
  { key: 3, label: 'March ' + new Date().getFullYear(), value: 'March ' + new Date().getFullYear() },
  { key: 4, label: 'April ' + new Date().getFullYear(), value: 'April ' + new Date().getFullYear() },
  { key: 5, label: 'May ' + new Date().getFullYear(), value: 'May ' + new Date().getFullYear() },
  { key: 6, label: 'June ' + new Date().getFullYear(), value: 'June ' + new Date().getFullYear() },
  { key: 7, label: 'July ' + new Date().getFullYear(), value: 'July ' + new Date().getFullYear() },
  { key: 8, label: 'August ' + new Date().getFullYear(), value: 'August ' + new Date().getFullYear() },
  { key: 9, label: 'September ' + new Date().getFullYear(), value: 'September ' + new Date().getFullYear() },
  { key: 10, label: 'October ' + new Date().getFullYear(), value: 'October ' + new Date().getFullYear() },
  { key: 11, label: 'November ' + new Date().getFullYear(), value: 'November ' + new Date().getFullYear() },
  { key: 12, label: 'December ' + new Date().getFullYear(), value: 'Decembe r' + new Date().getFullYear() },
]

export const HUBS = [
  {
    crew_base: 'KUL',
  },
  {
    crew_base: 'JHB',
  },
  {
    crew_base: 'BKK',
  },
  {
    crew_base: 'PEN',
  },
  {
    crew_base: 'BKI',
  },
  {
    crew_base: 'KCH',
  },
  {
    crew_base: 'DMK',
  },
  {
    crew_base: 'KNO',
  },
  {
    crew_base: 'CGK',
  },
  {
    crew_base: 'DPS',
  },
  {
    crew_base: 'MNL',
  },
  {
    crew_base: 'CRK',
  },
  {
    crew_base: 'CEB',
  },
  {
    crew_base: 'SUB',
  },
]

export const SALE_TIERS = [
  { label: 'None', value: 0 },
  { label: 'I', value: 1 },
  { label: 'II', value: 2 },
  { label: 'III', value: 3 },
]
