import * as DDM from '@radix-ui/react-dropdown-menu'
import { FC, ReactNode } from 'react'
import { v4 } from 'uuid'

export interface DropdownItems {
  label: ReactNode
  separator?: boolean
  subItems?: DropdownItems[]
  onClick?: () => void
}

interface DDMProps {
  items: DropdownItems[] | any[]
  children: ReactNode
  id?: string
  disabled?: boolean
}

const DropdownMenu: FC<DDMProps> = (props) => {
  const { children, items, id = v4(), disabled = false } = props

  const itemsRenderer = (item: DropdownItems, key: string): ReactNode => {
    if (item.separator) {
      return <DDM.Separator key={key} />
    }
    if (item.subItems) {
      return (
        <DDM.Sub key={key}>
          <DDM.SubTrigger className="py-2 px-4 font-bold hover:bg-slate-200 cursor-pointer">
            {item.label}
          </DDM.SubTrigger>
          <DDM.Portal>
            <DDM.SubContent className="bg-white rounded py-2 shadow-lg z-20">
              {item.subItems.map((d, idx) => itemsRenderer(d, `${key}.${idx}`))}
            </DDM.SubContent>
          </DDM.Portal>
        </DDM.Sub>
      )
    }

    return (
      <DDM.Item className="py-2 px-4 hover:bg-slate-200 cursor-pointer" onClick={item.onClick} key={key}>
        {item.label}
      </DDM.Item>
    )
  }

  return (
    <DDM.Root>
      <DDM.Trigger asChild disabled={disabled}>
        {children}
      </DDM.Trigger>

      <DDM.Portal>
        <DDM.Content className="bg-white rounded py-2 shadow-lg z-20">
          {items.map((d, idx) => itemsRenderer(d, `${id}-${idx}`))}
        </DDM.Content>
      </DDM.Portal>
    </DDM.Root>
  )
}

export default DropdownMenu
