import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { route } from '../../constants/routes';

const NotFound = (): React.ReactElement => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(route.HOME);
  }, [navigate]);

  return (<div></div>);
}

export default NotFound