import { HttpGet } from 'apis'
import clsx from 'clsx'
import TierRankingCard from 'components/Card/TierRankingCard'
import { DateRange } from 'components/DayPicker/DateRangePicker'
import Pagination from 'components/Pagination'
import { MRT_PaginationState } from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from 'redux/configureStore'
import { MetaFilterState } from 'redux/departmentRepresentative/metafilters'
import { UserState } from 'redux/user'
import { useResponsiveCheck } from 'utils/hooks'

interface TierRankingProps {}

const TierRanking = (props: TierRankingProps) => {
  const {} = props
  const { crewId } = useParams()
  const { isDesktop } = useResponsiveCheck()
  const [sectorTarget, setSectorTarget] = useState<Array<any>>([])
  const userState = useSelector<RootState, UserState>((state) => state.user)
  const metaFilterState = useSelector<RootState, MetaFilterState>((state) => state.metaFilters)
  const crew_id = crewId ?? userState.extraDetails.crew_id

  const [pagination, setPagination] = useState<MRT_PaginationState>({ pageIndex: 0, pageSize: isDesktop ? 9 : 5 })
  const totalPage = sectorTarget?.length ? Math.ceil(sectorTarget.length / pagination.pageSize) : -1

  // TODO: refactor this into redux-thunk for scalability
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: isDesktop ? 9 : 5 })
  }, [isDesktop])

  useEffect(() => {
    let params: any = {
      crew_id,
      month: metaFilterState.currentMonth,
      year: metaFilterState.currentYear,
    }

    // TODO: refactor this into redux-thunk for scalability
    const getTierList = async () => {
      setIsLoading(true)
      const response = await HttpGet('crew/tier-ranking', params)
      setSectorTarget(response.data?.sector_sales)
      setIsLoading(false)
    }

    getTierList()
  }, [metaFilterState.currentMonth, metaFilterState.currentYear])

  const data = useMemo(() => {
    if (sectorTarget.length) {
      return sectorTarget.slice(
        pagination.pageIndex * pagination.pageSize,
        (pagination.pageIndex + 1) * pagination.pageSize,
      )
    }

    return []
  }, [sectorTarget.length, pagination.pageIndex, pagination.pageSize])

  return (
    <>
      {isDesktop ? <div className="border-b my-4" /> : null}

      <div className={clsx('grid gap-2', isDesktop ? 'grid-cols-3' : 'grid-cols-1')}>
        {isLoading && [1, 2, 3].map((d) => <TierRankingCard key={d} isLoading={isLoading} />)}

        {!isLoading && data.length ? (
          data.map((item, index) => (
            <TierRankingCard key={index} sector={item.sector} target={item.target} sales={item.sales} />
          ))
        ) : (
          <div className="mt-4 text-center italic">No data available</div>
        )}
      </div>

      {sectorTarget?.length ? (
        <div className="flex flex-row xl:justify-end">
          <div className="w-full xl:w-fit">
            <Pagination
              currentPage={pagination.pageIndex}
              onChange={(number) => {
                setPagination({ pageIndex: number, pageSize: pagination.pageSize })
              }}
              totalPage={totalPage}
              className={'mt-4'}
            />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default TierRanking
