import { createAsyncThunk } from '@reduxjs/toolkit'
import { HttpGet } from '../../apis'

export const getUserAndAocDetails = createAsyncThunk(
  'user/getUserAndAocDetails',
  async (params: any, { rejectWithValue }) => {
    try {
      let data: any = {}

      const response = await HttpGet('crew/details', params)

      // Note: set a default value
      let aocResp = { data: { result: [] } }

      data = response.data

      if (response.data?.aoc) {
        aocResp = await HttpGet('aoc/tiers', {
          aoc: response.data.aoc,
        })
      } else {
        console.error('[error] User AOC detail is missing, this may cause other request to fail.')
      }

      let email = params.email

      if (response.data?.email) {
        email = response.data?.email
      }

      if (!data.full_name) {
        data.full_name = email.split('@')[0]
      }

      return {
        extraDetails: data,
        aocDetails: aocResp.data.result[0],
      }
    } catch (error: any) {
      console.error('get crew details error: ', error)

      if (error?.response?.data) {
        return rejectWithValue(error.response.data)
      }
    }
  },
)
